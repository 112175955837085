import {
  Indicate,
  DefaultIndicate,
  DefaultSpecialIndicate,
  MedicalInsurance,
  DefaultMedicalInsurance,
  MedicalInsuranceOldAge,
  DefaultMedicalInsuranceOldAge,
  MedicalInsuranceHighExpense,
  DefaultMedicalInsuranceHighExpense,
  CareInsurance,
  DefaultCareInsurance,
  CareInsuranceBurdenRatio,
  DefaultCareInsuranceBurdenRatio,
  PublicMoney,
  DefaultPublicMoney,
  PatientSituation,
  DefaultPatientSituation
} from "#/model/patient";
import { VisitRecord, DefaultVisitRecord } from "#/model/visit";
import { ApplyStaff } from "#/model/common";

export interface WindowPostMessage {
  movePath?: string; // 他画面に遷移する場合、遷移先URLのうちorigin以外の部分
}

export interface Menu {
  id: number;
  icon: string;
  title: string;
  url: string;
  is_emphasis: boolean;
  is_divider: boolean;
  childs: Menu[];
  is_no_menuitem?: boolean;
  is_outer: number;
}

//選択肢
export interface Choice {
  text: string;
  value: number | string;
  other_string?: string;
  disabled?: boolean;
}

export interface OcrTemplateChoice extends Choice {
  target_kind: number;
}

// 依頼機関Choice
export interface RequesterChoice extends Choice {
  id: number; // ID
  tel_area: string; // 市外局番
  tel_city: string; // 市内局番
  tel_no: string; // 加入者番号
  med_id?: number; // 医療機関ID
  med_name?: string; // 医療機関名
  doc_name?: string; // 医師名
}

export interface ChoiceAddOther extends Choice {
  other: number;
  other_string: string;
}

// ソートメニューの選択肢
export interface SortTypeSelect {
  text: string; // 選択した後に表示されるラベル
  select?: string; // 選択した後に表示されるラベル
  sort: number; // ソート対象カラムを示す数値
  sortType: number; // 0:降順 1:昇順
}

// カレンダーイベント用定期巡回
export interface RegularEvent {
  regular_visit_service_type: number; //'定期巡回サービス内容\n1:定期・随時\n2:2:定期のみ\n3:随時のみ',
  regular_visit_calc_type_from: number; //'定期巡回計算内容(開始)\n1:定期\n2:日割り',
  regular_visit_calc_type_to: number; //'定期巡回計算内容(終了)\n1:定期\n2:日割り',
}
export const DefaultRegularEvent = (): RegularEvent => ({
  regular_visit_service_type: 0,
  regular_visit_calc_type_from: 0,
  regular_visit_calc_type_to: 0
});

//カレンダーイベント
export interface CalendarEvent {
  id: number;
  name: string;
  start: string;
  end: string;
  color: string;
  type: number;
  visit_record_id: number;
  visitor_name: string;
  visit_time: string;
  decision_flg: number; //実績確定フラグ\n0:未確定\n1:確定
  registration_flg: number; //登録フラグ\n0:未登録\n1:登録済
  home_cancer_flg: number; //在宅がん医療総合診療料期間内\n0:期間外\n1:期間内
  absence_datetime: string; //不在日時
  specialty_hide_flg: boolean; //レセプト画面で専門にした場合非表示になるフラグ
  indicate: Indicate; //指示書
  special_indicate: Indicate; //特別指示書
  regular_visit: RegularEvent; //定期巡回
  visit_record: VisitRecord; //訪問記録
  sub_no: number; //連番（リハビリ等でvisit_recordが複数に別れた時の認識番号）
  medical_insurance: MedicalInsurance; //医療保険
  medical_insurance_old_age: MedicalInsuranceOldAge; //高齢受給者証
  medical_insurance_high_expense: MedicalInsuranceHighExpense; //高額療養費設定
  care_insurance: CareInsurance; //介護保険
  care_insurance_burden_ratio: CareInsuranceBurdenRatio; //介護保険負担割合証
  public_money: PublicMoney; //公費
  record_mating: number; //ほのぼのサービス事業所IDと実績のアンマッチチェック結果
  rare_case: boolean; // レアケース
  file_path: string; // ファイルパス
  name_toolchip: string; // ツールチップ表示文字列
  schedule_id?: number;
  timed?: boolean; // 終日フラグ false: 終日
  visitor_ids?: number[];
  visitors?: ApplyStaff[];
  user_id?: number;
  is_extend_time?: boolean; //時間増減可能なイベントかどうか
  is_move?: boolean; //移動可能なイベントかどうか
  is_repeat?: boolean; // 繰り返しイベントフラグ
  date?: string;
  minute?: number;
  hour?: number;
  start_day?: string;
  end_day?: string;
  rule_id?: number;
  patient_name: string;
  disp_name: string;
  pdf_time: string;
  pdf_value: string;
  event_type: number;
  event_title?: string;
  patient_id?: number; //利用者ID（「利用者のカレンダーを印刷する」で使用）
  visit_memo?: string;
  is_cancel?: number;
  patient_situation: PatientSituation;
}
export const DefaultCalendarEvent = (): CalendarEvent => ({
  id: 0,
  name: "",
  start: "",
  end: "",
  color: "",
  type: 0,
  visit_record_id: 0,
  visitor_name: "",
  visit_time: "",
  decision_flg: 0,
  registration_flg: 0,
  home_cancer_flg: 0,
  absence_datetime: "",
  specialty_hide_flg: false,
  indicate: DefaultIndicate(),
  special_indicate: DefaultSpecialIndicate(),
  regular_visit: DefaultRegularEvent(),
  visit_record: DefaultVisitRecord(),
  sub_no: 0,
  medical_insurance: DefaultMedicalInsurance(),
  medical_insurance_old_age: DefaultMedicalInsuranceOldAge(),
  medical_insurance_high_expense: DefaultMedicalInsuranceHighExpense(),
  care_insurance: DefaultCareInsurance(),
  care_insurance_burden_ratio: DefaultCareInsuranceBurdenRatio(),
  public_money: DefaultPublicMoney(),
  record_mating: 0,
  rare_case: false,
  file_path: "",
  patient_name: "",
  disp_name: "",
  pdf_time: "",
  pdf_value: "",
  event_type: 0,
  patient_id: 0,
  name_toolchip: "",
  patient_situation: DefaultPatientSituation()
});

/** カレンダーイベント(移動あり) */
export interface CalendarEventMoving extends CalendarEvent {
  is_not_moving: boolean; //移動不可の場合 true
}
export const DefaultCalendarEventMoving = (): CalendarEventMoving => ({
  ...DefaultCalendarEvent(),
  is_not_moving: false
});

//患者アイテム リスト項目
export interface PatientListItem {
  id: number;
  write_date: string;
  make_date: string;
}

//職員情報
export interface Staff {
  id: number;
  ibow_id: string;
  family_name: string;
  first_name: string;
  birthday: string;
  text?: string;
}

// 連想配列
// このインターフェースを実装した連想配列はobject[key]がlintエラーとならず使用できる
export interface StringKeyObject {
  [key: string]: string;
}

// v-formインターフェース
export interface VForm {
  validate: () => boolean;
  reset: () => void;
  resetValidation: () => void;
  $el: HTMLElement;
}

// マスタ
export interface Master {
  /* 共通 */
  master: Choice[]; // 事業所一覧
  sexes: Choice[]; // 性別
  yes_or_no: Choice[]; // ありorなし
  prefectures: Choice[]; // 都道府県
  breath: Choice[]; // 呼吸
  pulse: Choice[]; // 脈拍
  consciousness_level: Choice[]; // 意識レベル
  blood_glucose_level: Choice[]; // 血糖値
  sputum: Choice[]; // 痰
  lung_secondary_noise: Choice[]; // 肺副雑音
  life_independence: Choice[]; // 生活自立度
  tax_divs: Choice[]; // 課税区分
  tax_rates: Choice[]; // 課税率（％）
  offices: Choice[]; // 事業所・サテライト一覧
  only_offices: Choice[]; // 事業所のみ一覧
  group_offices: ChoiceAddOther[]; // グループ事業所一覧
  group_only_offices: Choice[]; // グループ事業所のみ一覧
  family_relationships: Choice[]; // 続柄

  /* 事業所 */
  area_div: Choice[]; // 地域区分
  business_div: Choice[]; // 事業所区分
  jokuso_rate_div: Choice[]; // 日々の褥瘡評価設定自立度
  doctor_title_div: Choice[]; // お医者様に対する敬称
  is_shift: Choice[]; // シフト管理機能表示設定
  record_divide_div: Choice[]; // 20分超えのリハ記録書IIまとめ設定
  staff_list_div: Choice[]; // 職員リスト表示設定
  service_record_disp_div: Choice[]; // 月次報告書表示設定
  adl_setting_div: Choice[]; // 看護記録Ⅱ（観察項目）設定
  care_service_time_required: Choice[]; // 提供時間必須化
  care_kyoka_add: Choice[]; // 看護体制強化加算
  hour_24_order_div: Choice[]; // 24時間体制の表示順
  care_service_supply_add_after_r3: Choice[]; //サービス提供体制強化加算
  care_end_time_include_div: Choice[]; //ほのぼの連携終了時刻
  shared_file_div: Choice[]; //共有ファイル登録区分
  report_seel_div: Choice[]; //帳票押印区分
  care_kinkyu_houmon_add: Choice[]; //緊急時訪問看護区分
  medical_kanri_ryouyouhi_div: Choice[]; //訪問看護ベースアップ評価料区分
  medical_base_up2_div: Choice[]; //訪問看護ベースアップ評価料

  /* 褥瘡 */
  bedsores: Choice[]; // 褥瘡部位
  depths: Choice[]; // 深さ
  exudates: Choice[]; // 滲出液
  sizes: Choice[]; // 大きさ
  infections: Choice[]; // 炎症・感染
  granulations: Choice[]; // 肉芽形成
  necrotics: Choice[]; // 壊死組織
  pockets: Choice[]; // ポケット(cm2)
  cans: Choice[]; // 出来るできない
  existences: Choice[]; // 有無
  bedsore_parts: Choice[]; // 褥瘡の有無の部位
  daily_life_rate: Choice[]; // 日常生活自立度

  /* 看護サマリ */
  send_types: Choice[]; // 宛先
  staff_titles: Choice[]; // 敬称区分

  /* 月次報告書 */
  sanitation_chg_flg: Choice[]; // 衛生材料等(種類・サイズ・必要量等)の変更の必要性
  support_office_div: Choice[]; // 支援事業所区分
  simple_daily_life_rate: Choice[]; // 日常生活自立度
  dementia_old_age_daily_life_rate: Choice[]; // 認知症高齢者の日常生活自立度

  /* 事故ヒヤリハット */
  support: Choice[]; // 対応状況 '\n1:その場で処置/様子を見る\n2:救急搬送/一般外来'

  /* 利用者 */
  need_care_levels: Choice[]; // 要介護度
  burden_rate_selects: Choice[]; // 負担割合
  indicate_divs: Choice[]; // 指示書種類
  insurance_types: Choice[]; // 保険種類
  insurance_types_switching_insured_div: ChoiceAddOther[]; // 保険種類
  insured_divs: Choice[]; // 被保険種類
  insured_divs_switching_rate: ChoiceAddOther[]; // 被保険種類（給付割合切り替え）
  insured_divs_public_alone: ChoiceAddOther[]; // 被保険種類（保険種類：公費単独）
  insured_divs_latter_period: ChoiceAddOther[]; // 被保険種類（保険種類：後期）
  payment_rates: Choice[]; // 給付割合 医療保険
  payment_rates_self_and_non_check: Choice[]; // 給付割合（本人 && 減免チェックなし）
  payment_rates_family_and_non_check: Choice[]; // 給付割合（家族 && 減免チェックなし）
  payment_rates_age6_and_non_check: Choice[]; // 給付割合（6歳 && 減免チェックなし）
  payment_rates_old_age1_and_non_check: Choice[]; // 給付割合（高齢一 && 減免チェックなし）
  payment_rates_old_age7_and_non_check: Choice[]; // 給付割合（高齢7 && 減免チェックなし）
  old_age_insured_divs: Choice[]; // 高齢-被保険者区分
  occupational_reasons: Choice[]; // 職務上の事由
  requester_types: Choice[]; // 依頼者種類
  job_types: Choice[]; // 職種
  before_use_types: Choice[]; // 利用前所在
  end_reasons: Choice[]; // 終了理由
  care_service_end_reasons: Choice[]; // 介護サービス中止理由
  regular_visit_service_types: Choice[]; // 定期巡回サービスタイプ
  regular_visit_calc_types: Choice[]; // 定期巡回計算タイプ
  elder_payment_rates: Choice[]; // 負担割合 高齢受給者証
  ranges: Choice[]; // 終了期間
  pay_types: Choice[]; // 支払い方法
  deposit_type: Choice[]; // 預金種目
  bank_types: Choice[]; // 銀行タイプ
  death_places: Choice[]; // 死亡場所
  indicate_types: Choice[]; // 普通指示書種類
  special_indicate_types: Choice[]; // 特別指示書種類
  specimen: Choice[]; // 指示書：ひな形選択
  visit_place_div: Choice[]; // 訪問場所区分（訪問した場所）\n1:自宅\n2:施設\n3:その他
  visit_place_facility_div: Choice[]; // 訪問場所施設区分\n1:01社会福祉施設及び身体障害者施設\n2:02小規模多機能型居宅介護\n3:03複合型サービス\n4:04認知症対応型グループホーム\n5:05特定施設\n6:06地域密着型介護老人福祉施設及び介護老人福祉施設

  /* 情報提供書 */
  reason: Choice[]; // 取得理由\n0:未選択\n1:入園\n2:入学\n3:転園\n4:転学\n5:医療的ケアの変更
  introduce_div: Choice[]; // 紹介先区分\n1:保険医療機関\n2:介護老人保健施設\n3:介護医療院

  /* 会議録 */
  conference_report_kind: Choice[]; // 会議種類
  institution_div: Choice[]; // 出席者
  agreement_flg: Choice[]; // 利用者同意欄
  address: Choice[]; // 宛名

  /* 看護計画書 */
  sanitation_flg: Choice[]; // 衛生材料等が必要な処置の有無
  mark_type: Choice[]; // 目標種別\n1:看護\n2:リハビリ\n3:看護・リハビリ
  vn_plan_support_office_div: Choice[]; // 支援事業所区分\n1:居宅介護支援事業所\n2:情報提供先\n3:地域包括支援センター\n4:その他

  /* 自費 */
  tax_div: Choice[]; // 課税区分
  medical_deducation: Choice[]; // 医療控除

  /* レセプト */
  visit_content_div: Choice[]; // 訪問内容（介護）のみ\n1:計画\n2:緊急
  nomal_staff_div: Choice[]; // 通常時職員区分\n1:看護師等\n2:准看護師\n3:専門\n4:PT\n5:OT\n6:ST
  mental_staff_div: Choice[]; // 精神職員区分\n1:看護師等\n2:准看護師\n5:OT
  nomal_accompanied_staff_div: Choice[]; // 通常複数名職員区分\n1:看護師等\n2:准看護師\n3:専門\n4:PT\n5:OT\n6:ST\n7:看護補助\n10:保健師
  mental_accompanied_staff_div: Choice[]; // 精神複数名職員区分\n1:看護師等\n2:准看護師\n3:専門\n4:PT\n5:OT\n6:ST\n7:看護補助\n9:PSW\n10:保健師
  nursing_time_div: Choice[]; // 時間区分（介護）\n1:20分未満(訪看I-1)\n2:30分未満(訪看I-2)\n3:30分以上1時間未満(訪看I-3)\n4:1時間以上1.5時間未満(訪看I-4)\n5:1.5時間以上(訪看I-4・長)
  accompanied_hojo_staff_div: Choice[]; // 複数名看護補助区分\n1:（ニ）\n2:（ハ）
  times_threeperson_div: Choice[]; // 複数回訪問同一建物内人数\n1:1人\n2:2人\n3:3人
  accompanied_staff_threeperson_div: Choice[]; // 複数名訪問同一建物内人数\n1:1人\n2:2人\n3:3人
  extra_time_div: Choice[]; // 延長時間区分\n1:1時間\n2:2時間\n3:3時間\n4:4時間\n5:5時間
  medical_time_div: Choice[]; // 時間区分（医療）\n1:30分未満\n2:30分以上
  regular_visit_div: Choice[]; // 定期巡回訪問タイプ（定期巡回は介護保険のみ）\n0:定期巡回以外\n1:定期巡回\n2:随時巡回
  med_every_month_modes: Choice[]; // 月次表示モード（医療）
  care_every_month_modes: Choice[]; // 月次表示モード（介護）
  both_every_month_modes: Choice[]; // 月次表示モード（両方）
  med_processing_target: Choice[]; // 処理対象表示（医療）
  care_processing_target: Choice[]; // 処理対象表示（介護）
  both_processing_target: Choice[]; // 処理対象表示（両方）

  /* 24時間体制 */
  support_div: Choice[]; // 対応区分
  support_method: Choice[]; // 対応方法
  messenger: Choice[]; // 連絡元

  /* ユニークマスタ */
  med_types: Choice[]; // 保険区分

  /* 訪問記録 */
  conditions: Choice[]; // 症状報告
  visit_contents: Choice[]; // 訪問内容（介護）
  care_offer_time: Choice[]; // 提供時間（介護）- 看護師・准看護師・専門・保健師
  care_offer_time2: Choice[]; // 提供時間（介護）- OT・PT・ST
  care_offer_time3: Choice[]; // 提供時間（介護）- OT・PT・ST(他STの訪問があり選択肢にない場合はこちら)
  medical_offer_time: Choice[]; // 提供時間（医療）
  add_item_regular_visit_divs: Choice[];
  senmon_care_divs: Choice[]; // 専門看護師訪問区分\n1:緩和ケア\n2:褥瘡ケア\n3:人工肛門／膀胱ケア
  senmon_care_divs_after_r6: Choice[]; // 専門看護師訪問区分\n1:緩和ケア\n2:褥瘡ケア\n3:人工肛門／膀胱ケア\n5:特定行為（01創傷管理関連）
  special_management_add_senmon_care_divs: Choice[]; // 専門看護師訪問区分\n1:緩和ケア\n2:褥瘡ケア\n3:人工肛門／膀胱ケア\n4:特定行為

  /* 記録書Ⅰ */
  emergency_contacts: Choice[]; // 緊急時の連絡方法
  required_times: Choice[]; // 訪問看護ステーションからの所要時間
  dw_envs: Choice[]; // 住居環境
  contacts: Choice[]; // 連絡先
  key_person_live_kinds: Choice[]; // キーパーソン用同居/別居
  live_kinds: Choice[]; // 同居/別居
  occupations: Choice[]; // 職業
  adl_supports: Choice[]; // 援助の要否
  adl_assistances: Choice[]; // 介助の要否

  /* 事業書職員 */
  staff_statuses: Choice[]; //職員勤務状態

  addition_or_not: Choice[];

  contact_types: Choice[];
}

// ログインユーザー
export interface LoginUser {
  /** 職員ID */ id: number;
  /** 契約ID */ agreement_id: number;
  /** 事業所ID */ office_id: number;
  /** 主たる事業所ID */ parent_office_id: number;
  /** eWeLL利用者ID */ ewell_user_id: number;
  /** eWeLLユーザーIDとの連携フラグ 0:未連携 1:連携済み */ is_link_ewell_user_id: number;
  /** 認証情報存在フラグ 他システム連携・データ移行時 */ is_exists_key: number;
  /** 権限ID */ auth_id: number;
  /** 職員区分 */ staff_div_id: number;
  /** eWeLLユーザーシステムID */ ewell_user_system_id: string;
  /** eWeLLユーザーiBowID */ ewell_user_ibow_id: string;
  /** IDと同じ */ staff_id: number;
  /** アイコンのフルパス */ icon_full_path: string;
  /** 誕生日 */ birth_day: string;
  /** 職員氏名 */ ibow_nickname: string;
  /** ポータル職員ID */ portal_staff_id: number;
  /** ポータル事業所ID */ portal_office_id: number;
}

//未提出数
export interface ReportCount {
  bedsore_plan_not_count: number; //褥瘡未提出数
  care_summary_not_count: number; //看護サマリ未提出数
  conference_report_not_count: number; //会議録未提出数
  month_report_not_count: number; //月次報告書未提出数
  occur_report_not_count: number; //ヒヤリハット報告書未提出数
  provision_report_not_count: number; //情報提供書書未提出数
  service_report_not_count: number; //サービス確認書未提出数
  vn_plan_not_count: number; //看護計画書未提出数
  vn_record_not_count: number; //看護記録書1未提出数
  visit_record_not_count: number; //看護記録書2未提出数
  fulltime_not_read_count: number; //24時間体制未読数
}

// 利用者リンク状態
export interface PatientLinkCondition {
  before_month_report_id: number; // 前月の月次報告書ID
  before_month_report: object; // 前月の月次報告書
  latest_vn_plant_id: number; // 最新の看護計画所ID
  latest_vn_plant: object; // 最新の看護計画所ID
  last3_nursing_record_ids: number[]; // 前3回の看護記録所Ⅱ（看護）IDリスト
  last3_nursing_records: object[]; // 前3回の看護記録所Ⅱ（看護）リスト
  last3_rehab_record_ids: number[]; // 前3回の看護記録所Ⅱ（リハ）IDリスト
  last3_rehab_records: object[]; // 前3回の看護記録所Ⅱ（リハ）リスト
  exsit_progress: boolean; // バイタル存在フラグ（経過観察 ３ヶ月以内）
  exist_hour24: boolean; // 24時間体制存在フラグ（３ヶ月以内）
  exist_last3_adl: boolean; // 前3回のADL存在フラグ
  latest_vnrecord_pdf_path: string; // 最新の看護記録書I PDFパス
}
export const DefaultPatientLinkCondition = (): PatientLinkCondition => ({
  before_month_report_id: 0,
  before_month_report: {},
  latest_vn_plant_id: 0,
  latest_vn_plant: {},
  last3_nursing_record_ids: [],
  last3_nursing_records: [],
  last3_rehab_record_ids: [],
  last3_rehab_records: [],
  exsit_progress: false,
  exist_hour24: false,
  exist_last3_adl: false,
  latest_vnrecord_pdf_path: ""
});

export const FONT_SIZE = {
  DEFAULT: 0,
  SIZE_L: 1,
  SIZE_LL: 2
};
