



























































import { Component, Prop, Emit, Ref, Mixins } from "vue-property-decorator";
import { PublicMoneyKind, DefaultPublicMoneyKind } from "#/model/patient";
import { VForm } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class PublicMoneyKindEditDialog extends Mixins(
  UtilMixin,
  RulesMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return DefaultPublicMoneyKind();
    }
  })
  selectedItem!: PublicMoneyKind;

  private is_no_receipt_link = 0;

  private cloneContent: PublicMoneyKind = DefaultPublicMoneyKind();

  private get IsDelete() {
    return this.cloneContent.id;
  }

  private get PaymentRateDiff(): number {
    return 100 - this.cloneContent.payment_rate;
  }

  created() {
    this.cloneContent = { ...this.selectedItem };
    if (this.selectedItem.is_receipt_link) {
      this.is_no_receipt_link = 0;
    } else {
      this.is_no_receipt_link = 1;
    }
  }

  //キャンセル
  private cancel() {
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }

  //保存
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (this.is_no_receipt_link) {
      this.cloneContent.is_receipt_link = 0;
    } else {
      this.cloneContent.is_receipt_link = 1;
    }
    this.saveDialog(this.cloneContent);
  }

  @Emit("save")
  private saveDialog(content: unknown) {
    return content;
  }

  //削除
  private async remove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.removeDialog(this.cloneContent);
  }

  @Emit("remove")
  private removeDialog(content: unknown) {
    return content;
  }

  private ruleContentNo() {
    if (!this.is_no_receipt_link) {
      if (!this.cloneContent.no) {
        return "必須です";
      }
    }
    return this.rangeNumber(
      this.cloneContent.no,
      0,
      99,
      "法別番号は0～99で入力してください"
    );
  }

  private rulePaymentRate() {
    if (!this.is_no_receipt_link) {
      if (!this.cloneContent.payment_rate) {
        return "必須です";
      }
    }
    return this.rangeNumber(
      this.cloneContent.payment_rate,
      0,
      100,
      "給付率は0～100で入力してください"
    );
  }
}
