






























































import { Component, Prop, Mixins, Emit, Ref } from "vue-property-decorator";
import Draggable from "vuedraggable";
import { MenuButton, ContentsDisplay } from "./types";
import PrimaryButton from "./primaryButton.vue";
import SecondaryButton from "./secondaryButton.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import ChikenLauncher from "./chikenLauncher.vue";
import EditButton from "./editButton.vue";
import LoadingArea from "@/components/home/loadingArea.vue";
import UtilMixin from "@/mixins/utilMixin";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";

@Component({
  components: {
    Draggable,
    PrimaryButton,
    SecondaryButton,
    ChikenLauncher,
    EditButton,
    LoadingArea
  }
})
export default class ContentArea extends Mixins(AxiosMixin, UtilMixin) {
  @Ref("chikenLauncher") private readonly chikenLauncher!: ChikenLauncher;

  /** ローディング */
  @Prop() private readonly partLoading!: boolean;

  /** メインボタン */
  @Prop() private readonly primaryitem!: MenuButton[];

  /** サブボタン */
  @Prop() private readonly secondaryitem!: MenuButton[];

  /** ibowフォントサイズ選択値 */
  @Prop() private readonly selectfontsize!: number;

  /** コンテンツの表示設定 */
  @Prop() private readonly contentsDisplay!: ContentsDisplay;

  /** メインボタン変更時 */
  @Emit("update:primaryitem") private updatePrimaryitem(newVal: unknown) {
    return newVal;
  }

  /** サブボタン変更時 */
  @Emit("update:secondaryitem") private updateSecondaryitem(newVal: unknown) {
    return newVal;
  }

  /** ibowフォントサイズ選択値変更時 */
  @Emit("update:selectfontsize") private updateSelectFontSize(newVal: number) {
    return newVal;
  }

  /** コンテンツの表示設定変更時 */
  @Emit("update:contentsDisplay") private updateContentsDisplay(
    newVal: ContentsDisplay
  ) {
    return newVal;
  }

  /** 編集モード開始時 */
  @Emit("edit:start") private onStartEdit() {
    return;
  }

  /** 編集モード終了時 */
  @Emit("edit:end") private onEndEdit() {
    return;
  }

  /** ドラッグ操作並び替えモードであるか */
  private isEditable = false;

  /** メインボタン */
  private get Primaryitem() {
    return this.primaryitem;
  }
  private set Primaryitem(newVal: MenuButton[]) {
    this.updatePrimaryitem(newVal);
  }

  /** サブボタン */
  private get Secondaryitem() {
    return this.secondaryitem;
  }
  private set Secondaryitem(newVal: MenuButton[]) {
    this.updateSecondaryitem(newVal);
  }

  /** ibowフォントサイズ選択値 */
  private get SelectFontSize() {
    return this.selectfontsize;
  }
  private set SelectFontSize(newVal: number) {
    this.updateSelectFontSize(newVal);
  }

  /** コンテンツの表示設定 */
  private get ContentsDisplay() {
    return this.contentsDisplay;
  }
  private set ContentsDisplay(newVal: ContentsDisplay) {
    this.updateContentsDisplay(newVal);
  }

  /** メニューボタンクリック時 */
  private clickMenuButton(item: MenuButton) {
    //iBowレセプトの場合
    if (item.id == 17) {
      const url = item.url + "?fromIbow=1";
      window.open(url, "_blank");
    } else if (item.id == 171) {
      //旧iBowレセプトの場合
      //ワンタイムトークン
      let oneTimeToken = "";
      this.postJsonCheck(
        window.base_url + "/api/receipt/v1onetimetoken",
        {},
        res => {
          oneTimeToken = res.data.one_time_token;
          const url = item.url + "/?onetime=" + oneTimeToken;
          window.open(url, "_blank");
        }
      );
    } else if (item.id == 22) {
      //iBow治験の場合
      this.chikenLauncher.launch(item.url);
    } else {
      //それ以外
      if (item.is_outer == 1) {
        window.open(item.url, "_blank");
      } else if (item.is_outer == 2) {
        location.href = item.url;
      } else if (item.is_outer == 101) {
        //けあログっとを開く
        this.postJsonCheck(
          window.auth_backend_url + "/outer/auth/portal/token",
          {
            portal_office_staff_id: this.loginUser.portal_staff_id,
            portal_office_id: this.loginUser.portal_office_id
          },
          res => {
            this.setCookie(res);
            location.href = item.url;
          }
        );
      } else {
        this.$router.push(item.url);
      }
    }
  }

  /** クッキーに設定 */
  private setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, {
      domain: window.cookie_domain
    });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
  }
}
