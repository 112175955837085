














import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import { PATIENT } from "#/const";
import ItemTable from "#/components/ItemTable.vue";
import { HighCostItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class HighCostSeparated extends Vue {
  private PATIENT = PATIENT;

  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 見出し文言 */
  @Prop() private readonly title?: string;

  /** 高額療養費 */
  @Prop({ default: () => [] }) private readonly items!: HighCostItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 新規作成できるか */
  @Prop({ default: true }) private isAllowCreate!: number;

  /** 高額療養費ヘッダー */
  private highCostHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "3.5rem",
      class: "px-2",
      cellClass: "px-2 py-1",
      align: "center"
    },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "3.5rem",
      cellClass: "text-no-wrap",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "3.5rem",
      cellClass: "text-no-wrap",
      sort: SortWarekiDate
    },
    { text: "区分", value: "div_text", width: "260px" },
    { text: "特記欄", value: "special_note_name", width: "80px" },
    { text: "特定疾病特記欄", value: "special_note_name_tokutei", width: "80px" }
  ];

  /** 直接作られた高額療養費か(保険や証明書からの自動作成ではないか) */
  private isCreateSelf(item: HighCostItems) {
    return item?.add_div === PATIENT.HIGH_EXPENSE_ADD_DIV_SELF;
  }

  /** 自動作成された高額療養費の、元である保険/証明書の編集画面 */
  private toOriginalInsurance(item: HighCostItems) {
    /** 編集URLのうち、作成種類を表す部分 */
    let pathPart = "";
    /** 高額療養費IDまたは作成元ID */
    let addId = item.add_id;
    switch (item.add_div) {
      case PATIENT.HIGH_EXPENSE_ADD_DIV_SELF:
        pathPart = "highcost";
        addId = item.id;
        break;
      case PATIENT.HIGH_EXPENSE_ADD_DIV_LIMIT_CERT:
        pathPart = "limitcert";
        break;
      case PATIENT.HIGH_EXPENSE_ADD_DIV_MED_INSURANCE:
        pathPart = "med";
        break;
      case PATIENT.HIGH_EXPENSE_ADD_DIV_OLD_AGE:
        pathPart = "eldcert";
        break;
      case PATIENT.HIGH_EXPENSE_ADD_DIV_TOKUTEI_DISEASE:
        pathPart = "tokuteidisease";
        break;
    }
    this.$router.push(
      `/patient/${this.patientId}/insurance/${pathPart}/${addId}`
    );
  }

  /** 新規作成 */
  private highCostCreate() {
    this.$router.push({
      name: "InsuranceInfoHighCost",
      params: { id: String(this.patientId), inshighcostid: "0" }
    });
  }

  /** 修正 */
  private highCostRepair(item: HighCostItems) {
    this.$router.push({
      name: "InsuranceInfoHighCost",
      params: { id: String(this.patientId), inshighcostid: String(item.id) }
    });
  }

  /** コピー */
  private highCostCopy(item: HighCostItems) {
    this.$router.push({
      name: "InsuranceInfoHighCost",
      params: { id: String(this.patientId), inshighcostid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  /** 現在有効な高額療養費設定に色を付ける */
  private highCostItemsRowBackground(item: HighCostItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
