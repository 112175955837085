


































































































































































































import { Component, Emit, Prop, Mixins, Watch } from "vue-property-decorator";
import { CalendarEvent, Choice } from "@/types";
import {
  HomeCancer,
  ResetMessage,
  RegistrationMessage,
  ResultsConfirmedMessage,
  Receipt
} from "#/model/receipt";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import {
  EveryMonthMode,
  DISPLAY_RECEIPT_REGIST,
  DISPLAY_SERVICE_CONTENT
} from "./types";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";
import { EventType } from "#/model/schedule/calendarType";
import {
  OfficeHistory,
  DefaultOfficeHistory,
  DefaultOfficeHistoryOverTherapistVisitCount,
  DefaultOfficeHistoryOverTherapistTargetAddition,
  NextMonthGensanSetting,
  DefaultNextMonthGensanSetting
} from "#/model/agreement";

@Component({
  components: {
    ExpantionActions
  }
})
export default class ReceiptDetailFooter extends Mixins(
  AxiosMixin,
  UtilMixin,
  FocusingMixin
) {
  /** レセプト登録画面 */
  private DISPLAY_RECEIPT_REGIST = DISPLAY_RECEIPT_REGIST;

  /** サービス内容画面 */
  private DISPLAY_SERVICE_CONTENT = DISPLAY_SERVICE_CONTENT;

  /** 操作モード 0:レセプト登録 1:サービス内容 */
  @Prop({ default: "" }) display_option!: number;

  /** イベント */
  @Prop({ default: () => [] }) events!: CalendarEvent[];

  /** レセプト */
  @Prop({ default: () => ({}) }) receipt!: Receipt;

  /** 利用者ID */
  @Prop({ default: 0 }) patientId!: number;

  /** 利用者事業所ID */
  @Prop({ default: 0 }) officeId!: number;

  /** 対象月 */
  @Prop({ default: "" }) yearmonth!: string;

  /** 在宅がん医療総合診療料 */
  @Prop({ default: () => ({}) }) homeCancer!: HomeCancer;

  /** 請求履歴確定済フラグ */
  @Prop({ default: 0 }) isBilledMedical!: number;
  @Prop({ default: 0 }) isBilledCare!: number;

  /** 実績確定フラグ */
  @Prop({ default: false }) isDecisionMedical!: boolean;
  @Prop({ default: false }) isDecisionCare!: boolean;

  /** 実績確定時のチェック項目 */
  @Prop({ default: () => ({}) }) confirmCheckItem!: {
    /** 医療訪問の医療保険が公費単独で裏に公費の登録がない場合 */
    expiredPublicFlg: boolean;
    /** 介護訪問の裏の介護保険が申請中の場合 */
    applicationCareFlg: boolean;
    /** 月内に書面未到着の指示書がある場合 */
    notYetArrivedIndicateFlg: boolean;
    /** 在宅がん期間内に介護訪問がある（確定時用） */
    decisionHomeCancerCareFlg: boolean;
    /** DecisionHomeCancerCareFlgがtrueの場合の介護訪問がある日にち（確定時用） */
    decisionHomeCancerCareDay: string;
    /** 確定対象に医療訪問で在宅がんの訪問がある */
    decisionIncludeHomeCancer: boolean;
  };

  /** メッセージ項目 */
  @Prop({ default: () => ({}) }) messageItem!: {
    /** 登録時メッセージ */
    registrationMessages: RegistrationMessage[];
    /** 確定時メッセージ */
    resultsConfirmedMessage: ResultsConfirmedMessage;
    /** リセット時メッセージ */
    resetMessage: ResetMessage;
    /** 実績確定解除時メッセージ */
    releaseConfirmedResultsMessage: string;
    /** 登録メッセージ */
    registrationMessage: string;
  };

  /** 更新時 */
  @Emit() update(noFetch = false) {
    return noFetch;
  }

  /** メッセージリセット時 */
  @Emit() reset() {
    return;
  }

  @Watch("HasEventType") watchHasEventType() {
    //医療訪問のみ
    if (this.HasEventType.isMed && !this.HasEventType.isCare) {
      this.everyMonthMode = EveryMonthMode.MONTHLY_MEDICAL;
    }
    //介護訪問のみ
    if (!this.HasEventType.isMed && this.HasEventType.isCare) {
      if (this.everyMonthMode === EveryMonthMode.MONTHLY_MEDICAL) {
        // 医療を選択していた場合、給付費に変更する
        this.everyMonthMode = EveryMonthMode.MONTHLY_BENEFIT;
      }
    }
  }

  /** 処理対象/1:全て/2:医療/3:介護 */
  private processingTarget = 1;

  /** 登録確認なしで確定する */
  private noRegistrationConfirmed = false;

  /** 訪問実績なしで確定する */
  // IV2-3631 No.1 の対応
  // https://ewell-ibow.backlog.com/view/IV2-3631#comment-465556806
  private forceConfirmed = false;

  @Watch("forceConfirmed")
  private watchForceConfirmed(checked: boolean) {
    if (!checked) this.noRegistrationConfirmed = false;
  }

  /** 在宅がん医療総合診療料ダイアログ表示フラグ */
  private isOpenHomeCancerDialog = false;

  /** 翌月の事業所設定（訪問回数超過減算で使用） */
  private nextMonthOfficeHistory: OfficeHistory = DefaultOfficeHistory();

  /** 翌月の事業所設定の反映確認ダイアログ */
  protected isOpenGensanApplyDialog = false;
  private nextMonthGensanSetting: NextMonthGensanSetting = DefaultNextMonthGensanSetting();

  /** 月次表示 */
  private everyMonthMode = 2;

  /** 処理対象表示 */
  private getProcessingTargets(): Choice[] {
    //医療訪問のみ
    if (this.HasEventType.isMed && !this.HasEventType.isCare) {
      return this.masters.med_processing_target;
    }
    //介護訪問のみ
    if (!this.HasEventType.isMed && this.HasEventType.isCare) {
      return this.masters.care_processing_target;
    }
    //両方あり
    return this.masters.both_processing_target;
  }

  /** 対象月で訪問していないかどうか */
  private get NotExistVisit() {
    let result = true;
    for (const event of this.events) {
      //医・介・定の場合
      if (
        event.type == EventType.Record2NursingCare ||
        event.type == EventType.Record2Medical ||
        event.type == EventType.Record2PassCrawl
      ) {
        result = false;
        break;
      }
    }
    return result;
  }

  /** イベント合計数 */
  private get EventsTotal() {
    let total = 0;
    let registTotal = 0;
    for (const event of this.events) {
      //医・介・定の場合
      if (
        event.type == EventType.Record2NursingCare ||
        event.type == EventType.Record2Medical ||
        event.type == EventType.Record2PassCrawl
      ) {
        if (this.display_option == this.DISPLAY_RECEIPT_REGIST) {
          if (event.registration_flg) {
            registTotal++;
          }
        } else {
          //確定済みは色が入る
          if (event.color) {
            registTotal++;
          }
        }
        total++;
      }
    }
    return `${registTotal}/${total}`;
  }

  /** サービス提供票の種別リスト */
  private get ServiceTypes(): Choice[] {
    //医療訪問のみ
    if (this.HasEventType.isMed && !this.HasEventType.isCare) {
      this.everyMonthMode = EveryMonthMode.MONTHLY_MEDICAL;
      return this.masters.med_every_month_modes;
    }
    //介護訪問のみ
    if (!this.HasEventType.isMed && this.HasEventType.isCare) {
      if (this.everyMonthMode === EveryMonthMode.MONTHLY_MEDICAL) {
        // 医療を選択していて、選択肢から"医療"が無くなった場合は、給付費を選択する
        this.everyMonthMode = EveryMonthMode.MONTHLY_BENEFIT;
      }
      return this.masters.care_every_month_modes;
    }
    //両方あり
    return this.masters.both_every_month_modes;
  }

  private get HasEventType(): { isMed: boolean; isCare: boolean } {
    let isMed = false;
    let isCare = false;
    for (const event of this.events) {
      if (event.type == EventType.Record2Medical) {
        isMed = true;
      }
      if (
        event.type == EventType.Record2NursingCare ||
        event.type == EventType.Record2PassCrawl
      ) {
        isCare = true;
      }
    }
    return { isMed, isCare };
  }

  /** リセットする */
  private async batchReset() {
    //請求履歴チェック
    if (this.isBilledMedical == 1 || this.isBilledCare == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //確認
    if (
      !(await this.$openConfirm("実績を一括リセットします。よろしいですか？"))
    ) {
      return;
    }

    //メッセージリセット
    this.reset();

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/batchReset",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        events: this.events,
        processing_target: 1
      },
      async res => {
        this.messageItem.resetMessage = res.data.reset_message;
        //リセット成功時
        if (this.messageItem.resetMessage.success_flg) {
          this.update();
        } else {
          await this.$openAlert(
            "リセットに失敗しました。メッセージを確認してください"
          );
        }
      }
    );
  }

  /** 実績を解除する */
  private async releaseConfirmedResults() {
    //請求履歴チェック
    if (await this.checkBilled()) {
      return;
    }

    //メッセージリセット
    this.reset();

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/releaseConfirmedResult",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        processing_target: this.processingTarget
      },
      () => {
        this.messageItem.releaseConfirmedResultsMessage =
          "登録実績を解除しました";
        this.update();
      }
    );
  }

  /** 実績を確定する */
  private async resultsConfirmed() {
    //請求履歴チェック
    if (await this.checkBilled()) {
      return;
    }

    //R2から不必要になった（念の為一旦コメントアウト）
    // //医療訪問の医療保険が公費単独で裏に公費の登録がない場合
    // if (this.confirmCheckItem.expiredPublicFlg) {
    //   if (
    //     !(await this.$openConfirm(
    //       "公費の有効期限が切れていますが、実績確定しますか？"
    //     ))
    //   ) {
    //     return;
    //   }
    // }
    //介護訪問の裏の介護保険が申請中の場合
    if (this.confirmCheckItem.applicationCareFlg) {
      if (
        !(await this.$openConfirm(
          "申請中の介護保険がありますが、実績確定しますか？"
        ))
      ) {
        return;
      }
    }
    //月内に書面未到着の指示書がある場合
    if (this.confirmCheckItem.notYetArrivedIndicateFlg) {
      if (
        !(await this.$openConfirm(
          "未到着の指示書がありますが、実績確定しますか？"
        ))
      ) {
        return;
      }
    }
    //在宅がん期間内に介護訪問がある
    if (this.confirmCheckItem.decisionHomeCancerCareFlg) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料の期間に介護保険の実績が含まれています" +
            this.confirmCheckItem.decisionHomeCancerCareDay +
            "日。本当に実績確定しますか？（ケアマネジャーへご確認ください。）"
        ))
      ) {
        return;
      }
    }
    //確定対象に医療訪問で在宅がんの訪問がある
    if (this.confirmCheckItem.decisionIncludeHomeCancer) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料期間の実績は請求対象外となるため保険請求には反映されませんが宜しいですか？"
        ))
      ) {
        return;
      }
    }

    //メッセージリセット
    this.reset();

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/resultsConfirmed",
      {
        patient_id: this.patientId,
        no_registration_confirmed: this.noRegistrationConfirmed,
        yearmonth: this.yearmonth,
        events: this.events,
        processing_target: this.processingTarget
      },
      async res => {
        this.messageItem.registrationMessages = res.data.registration_messages;
        this.messageItem.resultsConfirmedMessage =
          res.data.results_confirmed_message;
        this.update();
        // 実績確定時チェックを行う
        if (this.yearmonth >= "2024-05") {
          this.checkMonthConfirmedResult();
        }
      },
      err => {
        if (err.response?.data.registration_messages) {
          this.messageItem.registrationMessages =
            err.response?.data.registration_messages;
        }
        if (err.response?.data.results_confirmed_message) {
          this.messageItem.resultsConfirmedMessage =
            err.response?.data.results_confirmed_message;
        }
        this.update(true);
      }
    );
  }

  /** 対象月の実績確定時のチェック */
  public async checkMonthConfirmedResult() {
    // 親事業所のIDを取得
    const parentInfo = this.masters.group_offices.find(
      row => row.value == this.officeId
    );
    if (!parentInfo) return;
    const parentOfficeId = parentInfo.other;

    // API実行
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/checkMonthConfirmedResult",
      {
        office_id: parentOfficeId,
        yearmonth: this.yearmonth,
        enable_check_over_therapist_target_additions: 1
      },
      async res => {
        this.nextMonthOfficeHistory = res.data.next_month_office_history;
        // 訪問回数超過減算
        if (
          res.data.estimated_is_uncalculated_target_additions !=
          res.data.conventional_is_uncalculated_target_additions
        ) {
          this.confirmUpdateNextMonthOfficeHistory(
            this.yearmonth,
            res.data.conventional_is_uncalculated_target_additions,
            res.data.estimated_is_uncalculated_target_additions
          );
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  // 翌月の事業所設定の値を変更するかの確認
  private async confirmUpdateNextMonthOfficeHistory(
    yearmonth: string,
    conventionalValue: number,
    estimatedValue: number
  ) {
    // 設定値に変更が無ければスキップ
    if (conventionalValue == estimatedValue) return;
    // 対象月の翌月
    const targetNextYearmonth = new Date(yearmonth + "-01");
    targetNextYearmonth.setMonth(targetNextYearmonth.getMonth() + 1);
    const nextYearmonthNum = Number(
      this.dateToStr(targetNextYearmonth, "yyyyMM")
    );
    // 初期化
    this.nextMonthGensanSetting = DefaultNextMonthGensanSetting();
    this.nextMonthGensanSetting = {
      yearmonth: nextYearmonthNum,
      yearmonthText: this.dateToStr(targetNextYearmonth, "yyyy年M月"),
      conventionalValue: conventionalValue,
      conventionalValueText: conventionalValue == 0 ? "なし" : "あり",
      estimatedValue: estimatedValue,
      estimatedValueText: estimatedValue == 0 ? "なし" : "あり"
    };
    // 確認ポップアップの表示
    this.isOpenGensanApplyDialog = true;
  }

  //（超過減算）集計結果を翌月の事業所設定に適用する
  private applyOverTherapistTargetAdditions() {
    this.isOpenGensanApplyDialog = false;
    // 年月計算
    const year = Math.floor(this.nextMonthGensanSetting.yearmonth / 100);
    const month = this.nextMonthGensanSetting.yearmonth % 100;
    const fiscalYear = month < 4 ? year - 1 : year;
    // 翌月の事業所設定に超過減算の設定がなければ生成する
    this.generateOverTherapistSettingsForNextMonth(fiscalYear);
    // 更新対象の検索と更新
    for (const target_addition of this.nextMonthOfficeHistory
      .office_history_over_therapist_target_addition) {
      if (target_addition.yearmonth == this.nextMonthGensanSetting.yearmonth) {
        target_addition.is_uncalculated = this.nextMonthGensanSetting.estimatedValue;
      }
    }
    // 更新
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/saveOverTherapistTargetAdditions",
      {
        office_info: this.nextMonthOfficeHistory
      },
      async () => {
        await this.$openAlert(
          `${this.nextMonthGensanSetting.yearmonthText}の減算は"${this.nextMonthGensanSetting.estimatedValueText}"です。\n\n事業所マスタ＞訪問回数超過等減算＞減算設定で確認できます。`
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  // （超過減算）超過減算用の設定値を生成する
  private generateOverTherapistSettingsForNextMonth(fiscalYear: number) {
    // 既に追加されているyearであれば追加しない
    if (fiscalYear == 0 || this.existYearInOverTherapistVisitCount(fiscalYear))
      return;
    // 年度の減算を追加する
    if (!this.nextMonthOfficeHistory.office_history_over_therapist_visit_count)
      this.nextMonthOfficeHistory.office_history_over_therapist_visit_count = [];
    const newTherapistVisitCount = DefaultOfficeHistoryOverTherapistVisitCount();
    newTherapistVisitCount.year = fiscalYear;
    // 減算データ作成時に従来の設定値をチェックする。
    if (
      fiscalYear == 2024 &&
      this.nextMonthOfficeHistory.care_over_therapist_visit_count_flg == 1
    )
      newTherapistVisitCount.is_over = 1;
    this.nextMonthOfficeHistory.office_history_over_therapist_visit_count.push(
      newTherapistVisitCount
    );

    // 各月の減算の配列を追加する
    if (
      !this.nextMonthOfficeHistory.office_history_over_therapist_target_addition
    )
      this.nextMonthOfficeHistory.office_history_over_therapist_target_addition = [];
    const months =
      fiscalYear == 2024
        ? [6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
        : [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]; // 2024年は改定年度のため6月から開始
    months.forEach(month => {
      const newTargetAddition = DefaultOfficeHistoryOverTherapistTargetAddition();
      const newYearmonth =
        (month < 4 ? fiscalYear + 1 : fiscalYear) * 100 + month; // ex)2024年4月なら202404
      newTargetAddition.yearmonth = newYearmonth;
      newTargetAddition.fiscal_year = fiscalYear;
      // 減算データ作成時に従来の設定値をチェックする。
      if (
        (newYearmonth == 202406 &&
          this.nextMonthOfficeHistory.care_over_therapist_no_additions_flg ==
            1) ||
        (newYearmonth == 202407 &&
          this.nextMonthOfficeHistory
            .care_over_therapist_no_additions_july_flg == 1)
      ) {
        newTargetAddition.is_uncalculated = 1;
      }
      this.nextMonthOfficeHistory.office_history_over_therapist_target_addition.push(
        newTargetAddition
      );
    });
  }

  // （超過減算）指定した年度が既に登録済みか否か
  private existYearInOverTherapistVisitCount(year: number): boolean {
    if (!this.nextMonthOfficeHistory.office_history_over_therapist_visit_count)
      return false;
    return this.nextMonthOfficeHistory.office_history_over_therapist_visit_count.some(
      row => row.year == year
    );
  }

  /** 在宅がん医療総合診療料を保存する */
  private saveHomeCancer() {
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/homecancer/save",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        home_cancer: this.homeCancer
      },
      () => {
        this.update();
      }
    );
  }

  /** サービス提供票を出力する */
  private async outputServiceOffer() {
    let strPdfs: string[] = [];
    switch (this.everyMonthMode) {
      case EveryMonthMode.MONTHLY_OTHER:
        strPdfs = [this.receipt.month_service_report_path_beppyo];
        break;
      case EveryMonthMode.MONTHLY_BENEFIT:
        strPdfs = [this.receipt.month_service_report_path_care];
        break;
      case EveryMonthMode.MONTHLY_MEDICAL:
        strPdfs = [this.receipt.month_service_report_path_medical];
        break;
    }
    //パスが空の場合はリターン
    if (strPdfs[0] == "") {
      await this.$openAlert(
        "サービス提供票を作成中である、もしくは作成に失敗している可能性があります。画面を更新した後でもPDFが表示されない場合は、再度実績確定を行ってください。"
      );
      return;
    }
    this.makePdf(window.base_url + "/api/patient/receipt/preview/ids", {
      str_pdfs: strPdfs,
      receipt: this.receipt
    });
  }

  /** 請求履歴確定をチェックする */
  private async checkBilled(): Promise<boolean> {
    if (this.judgeBilled()) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return true;
    }
    return false;
  }

  /** 請求履歴確定判定 */
  private judgeBilled(): boolean {
    let result = false;
    //処理対象：全て
    if (
      this.processingTarget == 1 &&
      (this.isBilledMedical == 1 || this.isBilledCare == 1)
    ) {
      result = true;
    }
    //処理対象：医療
    if (this.processingTarget == 2 && this.isBilledMedical == 1) {
      result = true;
    }
    //処理対象：介護
    if (this.processingTarget == 3 && this.isBilledCare == 1) {
      result = true;
    }
    return result;
  }
}
