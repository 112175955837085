






























































































































































































import { Component, Mixins, Watch, Prop, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import AddItem from "@/components/patient/record2/AddItem.vue";
import GafEvaluate from "@/components/patient/record2/GafEvaluate.vue";
import Vital from "@/components/patient/common/Vital.vue";
import ObservationItem from "@/components/patient/common/ObservationItem.vue";
import SymptomReport from "@/components/patient/common/SymptomReport.vue";
import NursingDetail from "@/components/patient/common/nursingdetail/NursingDetail.vue";
import Bedsore from "@/components/patient/common/Bedsore.vue";
import RiskFactor from "@/components/patient/common/RiskFactor.vue";
import Infomation from "@/components/patient/record2/Infomation.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { DefaultVitalText, ResetVisitTimeType } from "@/components/visit/types";
import BaseVisit from "@/views/BaseVisit";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { COMMON } from "#/const";
import VisitTmpDataMixin from "@/mixins/firestore/visitTmpDataMixin";
import { VnPlanProblem } from "#/model/vnplan";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import { VForm } from "@/types";
import { Link } from "#/types";
import * as constant from "#/const";
import VisitHeader from "@/components/visit/VisitHeader.vue";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    FileUpload,
    AddItem,
    GafEvaluate,
    Vital,
    ObservationItem,
    SymptomReport,
    NursingDetail,
    Bedsore,
    RiskFactor,
    Infomation,
    PatientHeader,
    PatientExpantionAreas,
    ExpantionActions
  }
})
export default class VisitStay extends Mixins(
  BaseVisit,
  UtilMixin,
  VisitTmpDataMixin,
  RulesMixin,
  RulesSaveMixin,
  PatientMixin
) {
  /** 保存 */
  private REPORT_SAVE = COMMON.REPORT_STATUS.SAVE;

  /** フォームref */
  @Ref("header") private readonly form!: VForm;

  /** 変数 */
  @Prop() readonly pheader!: PatientHeader;
  @Prop() readonly vheader!: VisitHeader;

  /** 終了ダイアログ開閉状態 */
  private actionDialog = false;

  // 看護計画書
  private vnplan_problems: VnPlanProblem[] = [];

  /** プレフィックスリンク */
  private prefixLink = [
    {
      text: "写真",
      key: "print"
    }
  ];

  /** ミドルリンク */
  private middleLink = [
    {
      text: "本日の状態（バイタル）",
      key: "vital"
    },
    {
      text: "観察項目",
      key: "observationItem"
    },
    {
      text: "症状報告",
      key: "symptomReport"
    },
    {
      text: "看護内容",
      key: "nursingDetail"
    }
  ];

  /** サフィックスリンク */
  private suffixLink = [
    {
      text: "連絡事項",
      key: "infomation"
    }
  ];

  /** アップロード可能ファイル */
  private acceptFilePaths = [".jpg", ".jpeg", ".png"];

  /** 前回のバイタル情報 */
  private beforeVital = DefaultVitalText();

  /** 保険ワーニング表示 */
  private warning_insurance = "";

  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";

  /** ファイル登録先ディレクトリ */
  private get imagePath(): string {
    // [co_code]/[kst_no]/visit/[利用者ID]/[記録書ⅡID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.patient.info.office_id);
    return `${this.siteName}/${kstNo}/visit/${this.patientId}/0/`;
  }

  /** 訪問者の職種 */
  private get visitorJobType(): number {
    const staff = this.master.staffs.find(
      staff => staff.value === this.loginUser.id
    );
    if (staff) {
      return staff.job_type;
    }
    return 0;
  }

  /** アコーディオンメニュー */
  private localAccordionMenu: Link[] = [];

  /** アコーディオンメニュー getter */
  private get AccordionMenu(): Link[] {
    this.localAccordionMenu = [
      ...this.prefixLink,
      ...this.addLink,
      ...this.linkMental,
      ...this.middleLink,
      ...this.linkJokuso,
      ...this.suffixLink
    ];

    return this.localAccordionMenu;
  }

  /** アコーディオンメニュー setter */
  private set AccordionMenu(val: Link[]) {
    this.localAccordionMenu = val;
  }

  private updateFlg = false;

  private beforeIntervalId = 0;

  /** モデルの変更監視 */
  @Watch("model", { deep: true }) private updateModel() {
    // 訪問終了処理中でなければfirestoreへ保存(終了したのに訪問中データが残ってしまう事態を防ぐため)
    if (this.updateFlg) {
      clearTimeout(this.beforeIntervalId);
      this.beforeIntervalId = setTimeout(() => {
        this.updateVisitTmpData(this.model);
      }, 1000);
    }
  }

  async created() {
    this.patientId = Number(this.$route.params.id);
    const model = await this.getVisitFirestoreData();
    if (!model) {
      // 失敗時はホームへ戻す
      // Firestore通信エラーの場合、Firestoreから訪問中データは消えていないので、再度訪問中画面に遷移する
      this.setIsSave(false);
      this.$router.replace({ name: "Home" });
      return;
    }

    this.model = model;
    this.setVital(this.model.vitals);
    this.fetchVisitMasterData();
    this.setIsSave(true);
    this.updateFlg = true;

    // オペログ書き込み用
    this.postJsonCheck(
      window.base_url + "/api/visit/stay",
      { patient_id: this.patientId },
      () => {
        // レスポンスはなし
      }
    );
  }

  // 訪問データ取得
  private fetchVisitMasterData() {
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/get",
      { patient_id: Number(this.patientId) },
      res => {
        if (!res.data) return;
        this.setData(res.data);
        // モデル（読み込み済みでない場合）
        if (!this.model.loaded) {
          this.model = {
            ...this.model,
            ...res.data.visit_record,
            visit_start_datetime: this.model.visit_start_datetime,
            staff_id: this.model.staff_id
          };
          this.model.loaded = true;
          this.model.is_risk_factor = res.data.is_risk_factor;
          if (
            this.patient.regular_visit_div ===
            constant.PATIENT.REGULAR_VISIT_DIV_NOTREGULAR
          ) {
            this.model.time_type = ResetVisitTimeType(
              this.model.visit_start_datetime
            );
          }
        }
        // モデル初期化
        this.initmodel();
        this.fetchPatientLink();
      }
    );
  }

  // 訪問データの再取得
  private fetch() {
    this.postJsonBackground(
      window.base_url + "/api/patient/visitrecord/get",
      {
        patient_id: Number(this.patientId),
        nursing_type: this.model.nursing_type
      },
      res => {
        if (!res.data) return;
        this.setData(res.data);
        // 基礎情報の定期巡回のタイプが"定期・随時"、かつ、訪問記録の定期巡回タイプが設定前なら"定期"をセット
        if (
          this.patient.regular_visit_div ===
            constant.PATIENT.REGULAR_VISIT_DIV_REGULAR_OR_ANYTIME &&
          this.model.regular_visit_div === 0
        ) {
          this.model.regular_visit_div =
            constant.VISIT_RECORD.REGULAR_VISIT_DIV_REGULAR;
        }
        // モデル初期化
        this.initmodel();
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setData(data: any) {
    // 前回のバイタル
    this.beforeVital = data.before_vital;
    // マスター
    this.master = data.master;
    // 利用者
    this.patient = data.patient;
    // 事業所
    this.office = data.office;
    // 看護計画書
    this.vnplan_problems = data.vnplan_problems;
    // 保険ワーニング表示
    this.warning_insurance = data.warning_insurance;
  }

  /** 保存ボタン押下 */
  private async clickSave() {
    // 緊急訪問のトグルがOFFの場合、理由欄をクリア（医療）
    this.clearEmergencyReason();

    // オペログ書き込み用
    this.postJsonCheck(
      window.base_url + "/api/visit/save",
      { patient_id: this.patientId },
      () => {
        // Do Nothing
      }
    );
    await this.$openAlert("保存しました。");
  }
  /** 終了ダイアログの開閉状態切り替え */
  private toggleActionDialog(): void {
    this.actionDialog = !this.actionDialog;
  }

  /** 訪問を終了する押下 */
  private async clickEnd() {
    // 訪問中
    this.vheader.mode = 1;
    // 終了時にポップアップ表示するかどうか
    if (this.vheader.IsDispMemo && this.model.is_disp_memo_end) {
      if (!(await this.vheader.openMemoDialog())) {
        // 確認
        return;
      }
    }
    this.toggleActionDialog();
  }

  /** 訪問終了 */
  private async clickVisitFinish() {
    const model = await this.getVisitFirestoreData();
    if (!model) {
      return;
    }

    this.setIsSave(true);
    this.$nextTick(async () => {
      // 緊急訪問のフラグがOFFの場合、理由欄をクリア
      this.clearEmergencyReason();

      if (!this.form.validate()) {
        await this.$openAlert(`入力内容に不備があります`);
        this.toggleActionDialog();
        return;
      }

      this.toggleActionDialog();
      this.updateFlg = false;
      this.model.status = this.REPORT_SAVE;
      this.postJsonCheck(
        window.base_url + "/api/visit/move/save",
        {
          patient_id: Number(this.patientId),
          visit_record: this.model,
          mode: 1,
          care_contents: this.createRegisteredCareContent()
        },
        async () => {
          await this.deleteVisitTmpData(); //サーバー側でも削除しているができない場合がありそうなため、対応
          this.setIsSave(false);
          this.$router.replace({ name: "Home" });
        },
        () => {
          // 失敗時はホームへ戻す
          // 訪問終了済みエラー以外の場合、Firestoreから訪問中データは消えていないので、再度訪問中画面に遷移する
          this.setIsSave(false);
          this.$router.replace({ name: "Home" });
        }
      );
    });
  }

  // 緊急訪問のトグルがOFFの場合、理由欄をクリア（医療）
  private clearEmergencyReason() {
    if (this.model.is_emergency_care == 0) this.model.emergency_reason = "";
  }
}
