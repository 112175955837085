


































































































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DefaultSearchCondition } from "@/components/officeAggregate/types";
import { VForm } from "@/types";
import { Data, DefaultData } from "@/components/officeAggregate/biHome/types";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class BiHome extends Base {
  @Ref("bi_home") private readonly form!: VForm;

  private officeId = 0; //検索条件：事業所ID
  private targetMonth = ""; //検索条件：対象年月
  private data: Data = DefaultData(); //データ

  public async mounted() {
    this.officeId = this.offices(); //まず、選択肢の1番目で検索する
    this.targetMonth = this.todaymonth();
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    await this.search();
  }

  //レセプト売上情報
  private receiptSales() {
    const name = "ReceiptSales";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //訪問記録情報
  private visitRecordNumber() {
    const name = "OfficeInvoice";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //利用者数情報
  private patientNumber() {
    const name = "PatientNumber";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //利用者継続率
  private patientContinuityRate() {
    const name = "PatientContinuityRate";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //勤務売上情報
  private unitCost() {
    const name = "UnitCost";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //指示書情報
  private indicateNumber() {
    const name = "IndicateNumber";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: {}
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //検索条件を保存
  protected saveSearchCon(target: string) {
    const parentSearchCondition = DefaultSearchCondition();
    parentSearchCondition.end = target;
    const date = new Date(target);
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 1);
    const lastmonth = date.toISOString().substr(0, 7);
    parentSearchCondition.start = lastmonth;
    this.saveParentSearchCon(parentSearchCondition);
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_id: this.officeId,
      target_month: this.targetMonth
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/biHome/search",
        searchCondition,
        res => {
          this.data = res.data.data;
          resolve(0);
          //親画面の検索条件を保存
          this.saveSearchCon(searchCondition.target_month);
        }
      );
    });
  }
}
