import { Component, Mixins } from "vue-property-decorator";
import { State } from "vuex-class";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { Link } from "#/types";
import * as appDate from "#/utility/appDate";
import * as master from "@/store/modules/master/types";
import {
  Item as ItemPdf,
  SearchCond as SearchCondPdf
} from "@/components/patient/pdf/list";
import { Item as ItemPersonnel } from "@/components/master/doctorpersonnel/list";
import { DefaultPdfLink } from "#/model/patient/pdfLink";
import { REPORT_STATUS } from "#/const/common";
import * as patient from "@/store/modules/patient/types";

@Component
export default class PdfLinkMixin extends Mixins(AxiosMixin, RulesMixin) {
  @State(master.name) masterState!: master.MasterState;

  /** PDFリンク情報 */
  protected pdfLink = DefaultPdfLink();

  /** 選択行 */
  protected selectedPersonnels: ItemPersonnel[] = [];

  /** 提出済み帳票一覧 */
  protected pdfs: ItemPdf[] = [];
  /** 選択行 */
  protected selectedPdfs: ItemPdf[] = [];

  /** 編集画面展開メニューの項目 */
  protected editExpantions: Link[] = [
    {
      text: "送信先担当者・医師",
      key: "personnel",
      is_select: true
    },
    {
      text: "提出済み帳票",
      key: "pdf",
      is_select: true
    },
    {
      text: "メッセージ",
      key: "message",
      is_select: true
    }
  ];

  /** 再送信先 */
  protected get SendablePersonnels() {
    return this.selectedPersonnels.filter(personnel => !personnel.is_deleted);
  }

  /** 再送信できる帳票 */
  protected get SendableReports() {
    return this.selectedPdfs.filter(
      report =>
        !report.is_deleted && report.report_status === REPORT_STATUS.SUBMIT
    );
  }

  /** 作成・コピー・編集時：帳票一覧はAPIから関数で変換 */
  protected fetchPdfs(patientId: number, searchCond?: SearchCondPdf) {
    return new Promise(resolve => {
      this.pdfs = [];
      this.postJsonCheck(
        window.base_url + "/api/patient/reportpdf/get",
        {
          patient_id: patientId,
          write_start: searchCond?.writeStart,
          write_end: searchCond?.writeEnd,
          statuses: searchCond?.statuses
        },
        res => {
          if (res.data.report_pdfs != null) {
            this.pdfs = res.data.report_pdfs;
          }
          resolve(true);
        }
      );
    });
  }

  /** 編集・コピー時：PDFリンク情報取得 */
  protected fetchPdfLink(pdfLinkId: number) {
    return new Promise(resolve => {
      if (pdfLinkId === 0) {
        // 新規作成時、内容を取得する必要は無い
        resolve(true);
        return;
      }
      this.postJsonCheck(
        window.base_url + "/api/patient/pdflink/get",
        { pdf_link_id: pdfLinkId },
        res => {
          if (res.data.pdf_link != null) {
            this.pdfLink = res.data.pdf_link;
          }
          resolve(true);
        }
      );
    });
  }

  /** キーワードでクイックフィルタリンクする */
  protected filterdItems<T>(items: T[], keyword: string) {
    return items.filter(item => {
      const objValues = Object.values((item as unknown) as string)
        .filter(value => typeof value === "string")
        .join("____");
      return objValues.indexOf(keyword) !== -1;
    });
  }

  /** 有効な担当者連絡先情報 */
  protected findMailContact(contacts?: patient.Contact[]) {
    return contacts?.find((contact: patient.Contact) => {
      // 連絡種類はemail(4)または携帯メール(5)
      return contact.contact_type === 4 || contact.contact_type === 5;
    });
  }

  /** 入力検証:担当者選択 */
  protected get RulePersonnels() {
    return [
      this.requiredArray(
        this.SendablePersonnels,
        "メール送信先を選択してください"
      )
    ];
  }

  /** 入力検証:PDF選択 */
  protected get RulePdfs() {
    return [
      this.requiredArray(this.SendableReports, "帳票PDFを選択してください")
    ];
  }

  /** 入力検証:PDF閲覧期限*/
  protected get RuleExpiredDate() {
    return [
      this.required(
        this.pdfLink.expired_date,
        "PDFの閲覧期限を入力してください"
      ),
      this.dateAfter(
        appDate.today(),
        this.pdfLink.expired_date,
        true,
        "PDFの閲覧期限は、本日以降にしてください"
      )
    ];
  }

  /** 検索条件バリデーション：期間の開始日 */
  protected checkStartDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // 開始日が入力されていない場合
    if (!startDateStr) {
      // return "開始日を入力すると検索できます";
      return true;
    }
    // 有効な開始日として解釈できない場合
    if (Number.isNaN(startDate.getDate())) {
      return "開始日の入力形式に誤りがあります";
    }
    // 開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  protected checkEndDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // 終了日が入力されていない場合
    if (!endDateStr) {
      // return "終了日を入力すると検索できます";
      return true;
    }
    // 有効な終了日として解釈できない場合
    if (Number.isNaN(endDate.getDate())) {
      return "終了日の入力形式に誤りがあります";
    }
    // 終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }

  /** PDFリンクメールの送信前バリデーション */
  protected get NoticeLinkMailEdit() {
    return [this.RulePersonnels, this.RulePdfs, this.RuleExpiredDate]
      .flat()
      .filter(result => result !== true)
      .join("\n");
  }

  /** メール内容プレビューダイアログの開閉状態 */
  protected isOpenPreviewDialog = false;
  /** メール内容プレビューダイアログの件名 */
  protected previewMailSubject = "";
  /** メール内容プレビューの本文 */
  protected previewMailBody = "";
}
