







































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import {
  OfficeHistory,
  DefaultOfficeHistoryOverTherapistVisitCount,
  DefaultOfficeHistoryOverTherapistTargetAddition
} from "#/model/agreement";
import AppYearPicker from "./YearChangePicker.vue";
import AxiosMixin from "@/mixins/axiosMixin";

interface TherapistVisitCountCalcResult {
  fiscal_year: number;
  start_year_month: string;
  end_year_month: string;
  therapist_visit_count: number;
  nurse_visit_count: number;
}

@Component({
  components: {
    AppPlaces,
    AppYearPicker
  }
})
export default class TherapistVisitCount extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Prop() office!: OfficeHistory;

  @Prop({ default: false }) isReadOnly!: boolean;

  private calcResult: TherapistVisitCountCalcResult = {
    fiscal_year: 0,
    start_year_month: "",
    end_year_month: "",
    therapist_visit_count: 0,
    nurse_visit_count: 0
  };

  private isOpenCalcDialogue = false; //集計ダイアログ
  private isOpenEditDialogue = false; //超過減算編集ダイアログ

  private openedPanels: number[] = []; // 開いているパネル

  /*****************************************************************************
   * 年度チェック
   *****************************************************************************/
  // 新しい年度を追加するかを確認
  private checkNewFiscalYearAdd() {
    // 現在の年度を取得
    const currentFiscalYear = this.getCurrentFiscalYear();
    // チェックする年度（今年度+翌年度）
    const checkYears = [currentFiscalYear, currentFiscalYear + 1];
    // 年度が登録されていなければ追加する
    for (const year of checkYears) {
      if (!this.existYearInOverTherapistVisitCount(year)) {
        this.addYearItem(year, 0);
      }
    }
  }

  // 年度を追加する
  private addYearItem(year: number, initValue = 0) {
    // 無効な年度は追加しない
    if (year == 0) return;

    // 既に追加されているyearであれば追加しない
    if (this.existYearInOverTherapistVisitCount(year)) return;

    // 年度の減算を追加する
    if (!this.office.office_history_over_therapist_visit_count) {
      this.office.office_history_over_therapist_visit_count = [];
    }

    const newTherapistVisitCount = DefaultOfficeHistoryOverTherapistVisitCount();
    newTherapistVisitCount.year = year;
    newTherapistVisitCount.is_over = initValue;
    // 減算データ作成時に従来の設定値をチェックする。
    if (year == 2024 && this.office.care_over_therapist_visit_count_flg == 1)
      newTherapistVisitCount.is_over = 1;

    // office.office_history_over_therapist_visit_countはyearの降順で詰められているため、適切な位置に挿入する
    const index = this.office.office_history_over_therapist_visit_count.findIndex(
      therapistVisitCount => therapistVisitCount.year < year
    );
    if (index === -1) {
      this.office.office_history_over_therapist_visit_count.push(
        newTherapistVisitCount
      );
    } else {
      this.office.office_history_over_therapist_visit_count.splice(
        index,
        0,
        newTherapistVisitCount
      );
    }

    // 各月の減算の配列を追加する
    if (!this.office.office_history_over_therapist_target_addition) {
      this.office.office_history_over_therapist_target_addition = [];
    }

    // 年月の要素を追加する
    const months =
      year == 2024
        ? [6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
        : [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]; // 2024年は改定年度のため6月から
    months.forEach(month => {
      const newTargetAddition = DefaultOfficeHistoryOverTherapistTargetAddition();
      const yearmonth = (month < 4 ? year + 1 : year) * 100 + month; // 2023年4月なら202304
      // 減算データ作成時に従来の設定値をチェックする。
      if (
        (yearmonth == 202406 &&
          this.office.care_over_therapist_no_additions_flg == 1) ||
        (yearmonth == 202407 &&
          this.office.care_over_therapist_no_additions_july_flg == 1)
      ) {
        newTargetAddition.is_uncalculated = 1;
      }
      newTargetAddition.yearmonth = yearmonth;
      newTargetAddition.fiscal_year = year;
      this.office.office_history_over_therapist_target_addition.push(
        newTargetAddition
      );
    });
  }

  // 指定した年度が既に登録済みか否か
  private existYearInOverTherapistVisitCount(year: number): boolean {
    if (!this.office.office_history_over_therapist_visit_count) return false;
    return this.office.office_history_over_therapist_visit_count.some(
      row => row.year == year
    );
  }

  // 指定した年度から始まるyearmonthの行をフィルタリングする（表示用）
  private filteredTargetAddition(year: number) {
    if (!this.office.office_history_over_therapist_target_addition) {
      return [];
    }

    return this.office.office_history_over_therapist_target_addition.filter(
      row => row.fiscal_year == year
    );
  }

  // 月を表示する（表示用）
  private getMonthFromYearmonth(yearmonth: number) {
    const monthStr = String(yearmonth).slice(-2);
    //0から始まるmonthStrは0を削除
    return monthStr.replace(/^0+/, "");
  }

  // 現在の年度を取得する
  private getCurrentFiscalYear(): number {
    // 現在の年度を取得
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    return currentMonth >= 4 ? currentYear : currentYear - 1;
  }

  /*****************************************************************************
   * 集計
   *****************************************************************************/
  // 指定年度の理学療法士、看護師の訪問回数を集計する
  private async calcTherapistVisitCount(year: number) {
    // 親事業所のIDを取得
    const parentInfo = this.masters.group_offices.find(
      row => row.value == this.office.office_id
    );
    if (!parentInfo) return;
    const parentOfficeId = parentInfo.other;

    // API実行
    if (
      await this.$openConfirm(
        year +
          "年度の減算ありなしを判定するために、" +
          (year - 1) +
          "年度の実績確定した介護の訪問回数を集計します。\n\nデータ量によっては数分かかる場合があります。"
      )
    ) {
      // YYYY-MM-dd形式で日付を設定
      const targetDate = year + "-04-" + "01";
      this.postJsonCheck(
        window.base_url + "/api/master/station/get_therapist_visit_count",
        {
          office_id: parentOfficeId,
          target_date: targetDate
        },
        res => {
          this.calcResult = res.data;
          this.openCalcDialog();
        }
      );
    }
  }

  // 集計結果を反映する
  private applyCalcResult() {
    // calcResultの年度と一致する要素を検索して、減算フラグを変更する
    for (const over_therapist_count of this.office
      .office_history_over_therapist_visit_count) {
      if (over_therapist_count.year == this.calcResult.fiscal_year) {
        over_therapist_count.is_over =
          this.calcResult.therapist_visit_count >
          this.calcResult.nurse_visit_count
            ? 1
            : 0;
      }
    }
  }

  // yyyy-MMからyyyy月M月に変換する（表示用）
  private getYearMonthStr(yearmonth: string): string {
    const d = new Date(yearmonth + "-01");
    return this.dateToStr(d, "yyyy年M月");
  }

  /*****************************************************************************
   * 以下、ダイアログ・アコーディオンの操作
   *****************************************************************************/
  // 減算設定のダイアログを表示
  private openEditDialog() {
    // 新しい年度の追加のチェック
    this.checkNewFiscalYearAdd();
    // アコーディオン
    this.resetAccordionState();
    this.openCurrentYearAccordion();
    // ダイアログ表示
    this.isOpenEditDialogue = true;
  }

  // 減算設定のダイアログを閉じる
  private cancelEditDialog() {
    this.$nextTick(() => {
      this.isOpenEditDialogue = false;
    });
  }
  // 集計結果のダイアログを表示
  private openCalcDialog() {
    this.isOpenCalcDialogue = true;
  }

  // 集計結果のダイアログを閉じる
  private cancelCalcDialog() {
    this.$nextTick(() => {
      this.isOpenCalcDialogue = false;
    });
  }

  // 集計結果のダイアログで保存
  private saveCalcDialog() {
    this.addYearItem(
      this.calcResult.fiscal_year,
      this.calcResult.therapist_visit_count > this.calcResult.nurse_visit_count
        ? 1
        : 0
    );
    this.applyCalcResult();
    this.isOpenCalcDialogue = false;
  }

  // 現在の年度のアコーディオンを開く
  private openCurrentYearAccordion() {
    const currentFiscalYear = this.getCurrentFiscalYear();
    if (
      this.office.office_history_over_therapist_visit_count &&
      this.office.office_history_over_therapist_visit_count.length > 0
    ) {
      this.office.office_history_over_therapist_visit_count.forEach(
        (item, index) => {
          if (item.year == currentFiscalYear) {
            this.openedPanels.push(index);
          }
        }
      );
    }
  }

  // アコーディオンの表示状態をリセット
  private resetAccordionState() {
    this.openedPanels = [];
  }
}
