import { Choice } from "@/types";
import { FileIF } from "@/components/patient/types";
import { Pdf, DefaultPdf, Remand, DefaultRemand } from "#/model/report";
import { ApplyStaff, DefaultApplyStaff } from "#/model/common";

export interface AdlItem {
  id: number; // 看護記録書ⅠADL項目ID
  vn_record_adl_item_id: number; // 看護記録書ⅠADL項目マスタID
  vn_record_adl_item_name: string; // ADL項目マスタ項目名
  check: number; // 不問
  support: number; // 援助の要否
  assistance: number; // 介助の要否
  name: string;
  support_name: string;
  assistance_name: string;
}

export interface AdlMasterItem {
  id: number; // 看護記録書ⅠADL項目マスタID
  name: string; // 項目名
  supports: Choice[]; // 援助の要否マスタ
  assistances: Choice[]; // 介助の要否マスタ
}

export interface Vnrecord {
  id: number; // 看護記録書Ⅰ ID
  patient_id: number; // 利用者ID
  editor_staff_id: number; // 編集者ID
  status: number; // 記事ステータス 1:保存 2:提出 3:差し戻し
  submit_datetime: string; // 提出日時
  prevhistory: string; // 既往歴及び現業歴
  treat: string; // 病状・治療状態
  insight: string; // 病識の程度／有無
  emphone: number; // 緊急時の連絡方法 1:電話 2:携帯電話 3:その他
  rtime: number; // 所要時間 1:1number分 2:2number分 3:3number分 4:4number分 5:5number分 6:6number分 7:7number分 8:8number分 9:9number分 1number:1numbernumber分 11:11number分 12:12number分 13:12number分以上
  dwenv: number; // 住居環境 1:居宅(家族と同居) 2:居宅(単独) 3:福祉ホーム 4:障害福祉サービスを行う施設 5:その他
  same_building_id: number; // 同一建物ID
  family_contact_div: number; // 連絡先 1:連絡先あり 2:連絡先なし
  hp_personal: string; // 本人の希望する援助内容
  hp_family: string; // 家族の希望する援助内容
  hp_organ: string; // 依頼期間の希望する援助内容
  is_comment_to_state: number; // コメント心身の状態反映フラグ
  comment: string; //コメント
  mind_and_body_state: string; // 心身の状態
  doctor_id: number; // 医療機関情報:医師ID
  medical_use_date: string; // 医療機関情報:利用日
  government_personnel_id: number; // 情報提供機関情報1(法人・行政ID)の担当者ID
  is_government_submit: number; // 情報提供書提出先フラグⅠ
  school_personnel_id: number; // 情報提供機関情報2(学校等ID)担当者ID
  is_school_submit: number; // 情報提供書提出先フラグⅡ
  medical_personnel_id: number; // 情報提供機関情報3(医療機関ID)の担当者ID
  is_medical_submit: number; // 情報提供書提出先フラグⅢ
  dental_personnel_id: number; // 歯科医療機関の担当者ID
  support_office_personnel_id: number; // 居宅介護支援事業所の担当者ID
  is_mouth_renkei_submit: number; // 口腔連携強化加算の情報提供先フラグ
  personnel1_id: number; // 機関情報1担当者ID
  personnel2_id: number; // 機関情報2担当者ID
  personnel3_id: number; // 機関情報3担当者ID
  other_service: string; // その他サービス
  message: string; // 申し送り事項
  write_date: string; // 記載日
  created_at: string; // 作成日時
  updated_at: string; // 更新日時
  adls: AdlItem[]; // ADL項目
  persons: Person[]; // 連絡先家族
  files: FileByType[]; // ファイル
  pdf: Pdf; //PDF
  remand: Remand; //差し戻し
  editor_staff: ApplyStaff; // 編集者
  write_date_wareki: string; // 記載日（和暦）
}

export interface Person {
  id: number; // 看護記録1_家族ID
  vn_record_id: number; // 看護記録1ID
  sort_no: number; // 並び順
  name: string; // 氏名
  age: number; // 年齢
  birthday: string; // 生年月日
  family_relationship_id: number; // 続柄ID
  kind: number; // 同居・別居
  occ: number; // 職業
  tel_area: string; // 電話番号_市外局番
  tel_city: string; // 電話番号_市内局番
  tel_no: string; // 電話番号_加入者番号
  spcomment: string; // 特記すべき事項
}

export interface FileByType extends FileIF {
  file_type: number; // 写真種別
}

export interface SameBuilding {
  building_kind: number;
  building_names: Choice[];
}

// 看護記録書Ⅰ初期値
export const VnrecordDefaultValues = (): Vnrecord => ({
  id: 0,
  patient_id: 0, // 利用者ID
  editor_staff_id: 0, // 編集者ID
  status: 0, // 記事ステータス 1:保存 2:提出 3:差し戻し
  submit_datetime: "", // 提出日時
  prevhistory: "", // 既往歴及び現業歴
  treat: "", // 病状・治療状態
  insight: "", // 病識の程度／有無
  emphone: 0, // 緊急時の連絡方法 1:電話 2:携帯電話 3:その他
  rtime: 0, // 所要時間 1:10分 2:20分 3:30分 4:40分 5:50分 6:60分 7:70分 8:80分 9:90分 10:100分 11:110分 12:120分 13:120分以上
  dwenv: 0, // 住居環境 1:居宅(家族と同居) 2:居宅(単独) 3:福祉ホーム 4:障害福祉サービスを行う施設 5:その他
  same_building_id: 0, // 同一建物ID
  family_contact_div: 1, // 連絡先 1:連絡先あり 2:連絡先なし
  hp_personal: "", // 本人の希望する援助内容
  hp_family: "", // 家族の希望する援助内容
  hp_organ: "", // 依頼期間の希望する援助内容
  is_comment_to_state: 0, // コメント心身の状態反映フラグ
  comment: "", //コメント
  mind_and_body_state: "", // 心身の状態
  doctor_id: 0, // 医療機関情報:医師ID
  medical_use_date: "", // 医療機関情報:利用日
  government_personnel_id: 0, // 情報提供機関情報1(法人・行政ID)の担当者ID
  is_government_submit: 0, // 情報提供書提出先フラグⅠ
  school_personnel_id: 0, // 情報提供機関情報2(学校等ID)担当者ID
  is_school_submit: 0, // 情報提供書提出先フラグⅡ
  medical_personnel_id: 0, // 情報提供機関情報3(医療機関ID)の担当者ID
  is_medical_submit: 0, // 情報提供書提出先フラグⅢ
  dental_personnel_id: 0, // 歯科医療機関の担当者ID
  support_office_personnel_id: 0, // 居宅介護支援事業所の担当者ID
  is_mouth_renkei_submit: 0, // 口腔連携強化加算の情報提供先フラグ
  personnel1_id: 0, // 機関情報1担当者ID
  personnel2_id: 0, // 機関情報2担当者ID
  personnel3_id: 0, // 機関情報3担当者ID
  other_service: "", // その他サービス
  message: "", // 申し送り事項
  write_date: "", // 記載日
  created_at: "", // 作成日時
  updated_at: "", // 更新日時
  adls: [], // ADL項目
  persons: [], // 連絡先家族
  files: [], // ファイル
  pdf: DefaultPdf(), // PDF
  remand: DefaultRemand(), // 差し戻し
  editor_staff: DefaultApplyStaff(), // 編集者
  write_date_wareki: "" // 記載日（和暦）
});

// 連絡先家族初期値
export const PersonDefaultValues = (
  vnrecordId: number,
  sortNo: number
): Person => ({
  id: 0, // 看護記録1_家族ID
  vn_record_id: vnrecordId, // 看護記録1ID
  sort_no: sortNo, // 並び順
  name: "", // 氏名
  age: 0, // 年齢
  birthday: "", // 生年月日
  family_relationship_id: 0, // 続柄ID
  kind: 0, // 同居・別居
  occ: 0, // 職業
  tel_area: "", // 電話番号_市外局番
  tel_city: "", // 電話番号_市内局番
  tel_no: "", // 電話番号_加入者番号
  spcomment: "" // 特記すべき事項
});

// ファイル種別定数
export const FileType = {
  A4QUARTER_SIZE: 1, // A4の4分の1サイズ
  A4_SIZE: 2 // A4サイズ
} as const;
export type FileType = typeof FileType[keyof typeof FileType];
