




























import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import AxiosMixin from "@/mixins/axiosMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { ProvisionReport } from "#/model/provisionreport";
import { COMMON } from "#/const";

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class Provision extends Mixins(AxiosMixin, FocusingMixin) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = 0;
  private items: ProvisionReport[] = []; //情報提供書
  private paths: string[] = []; //最新の3つの情報提供書のパス

  private headers: DataTableHeader[] = [
    {
      text: "種類",
      value: "report_type_str",
      align: "start",
      width: "200px"
    },
    {
      text: "報告月",
      value: "yearmonth_wareki",
      align: "start",
      width: "120px",
      sort: SortWarekiDate
    }
  ];
  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };

  public created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchProvisionReport({ tab: 0, page: 0 });
    }
  }

  private clickNew(): void {
    this.$router.push({
      name: "ProvisionEdit",
      params: { id: String(this.patientId), provisionreportid: "0" }
    });
  }

  private clickEdit(item: ProvisionReport): void {
    this.$router.push({
      name: "ProvisionEdit",
      params: { id: String(this.patientId), provisionreportid: String(item.id) }
    });
  }

  //前3回の記録
  private last3TimesProvisionReport() {
    this.makePdf(window.base_url + "/api/reportlist/pdf/join", {
      str_pdfs: this.paths
    });
  }

  //情報提供書情報一覧取得
  private fetchProvisionReport(obj: { tab: number; page: number }): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/provisionreports/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.items != null) {
          this.paths = res.data.paths;
          this.reportList?.finishLoaded(res.data.items);
          if (obj.page != 0) {
            this.items = this.items.concat(res.data.items);
          } else {
            this.items = res.data.items;
          }
        }
      }
    );
  }

  //差し戻し
  private setRemand(recordItem: {
    item: ProvisionReport;
    comment: string;
  }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/provisionreport/saveRemand",
      {
        provision_report: recordItem.item
      },
      () => {
        this.items = this.items.filter(v => v.id !== recordItem.item.id);
      }
    );
  }

  //PDF
  private pdf(item: ProvisionReport): void {
    this.makePdf(window.base_url + "/api/patient/provisionreport/preview/ids", {
      patient_id: this.patientId,
      provision_report_ids: [item.id],
      provision_reports: [item]
    });
  }
}
