
































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Ref, Watch, Prop } from "vue-property-decorator";
import { Choice, ChoiceAddOther } from "@/types";
import { Link } from "#/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import AdlSelect from "@/components/patient/provisionreport/AdlSelect.vue";
import UnderlyingConditionSelect from "@/components/patient/provisionreport/UnderlyingConditionSelect.vue";
import UtilMixin from "@/mixins/utilMixin";
import {
  DefaultProvisionReportRemand,
  ProvisionReport,
  DefaultProvisionReport,
  ProvisionReportUnderlyingCondition
} from "#/model/provisionreport";
import { DefaultApplyPersonnel } from "#/model/common";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import EditMixin from "@/mixins/editMixin";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientMixin from "@/mixins/patientMixin";

interface AdlItem {
  id: number;
  title: string;
  items: Choice[];
}

interface UnderlyingConditionItem {
  id: number;
  title: string;
  items: Choice[];
}

@Component({
  components: {
    PatientHeader,
    PatientSaveArea,
    RemandExpand,
    AdlSelect,
    UnderlyingConditionSelect,
    OfficeSelect,
    PatientDoctorSelect,
    PatientExpantionAreas
  }
})
export default class ProvisionCreate extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  @Prop() readonly pheader!: PatientHeader;
  /** 選択観察項目 */

  private provisionReportId = 0;
  private patientId = 0;

  private provision_report: ProvisionReport = DefaultProvisionReport(); //情報提供書
  private informationProviderFlg = 0; //看護記録書1の情報提供先が一つでも存在フラグ（ない場合は情報提供療養費の情報提供書の提出ができない）
  private informationProviderMouthRenkeiFlg = 0; //看護記録書1の情報提供先が一つでも存在フラグ（ない場合は口腔連携強化加算の情報提供書の提出ができない）
  private adl_items: AdlItem[] = []; //ADL
  private underlying_condition_items: UnderlyingConditionItem[] = []; //基礎疾患
  private providerId = 0; //提供先ID（学校宛）
  private provider = ""; //提供先名（学校宛）
  private informationProvider1 = ""; //情報提供先1
  private informationProvider2 = ""; //情報提供先2
  private informationProvider3 = ""; //情報提供先3
  private informationProviderDental = ""; //口腔連携強化加算 情報提供先（介護 歯科医療機関）
  private informationProviderSupport = ""; //口腔連携強化加算 情報提供先（介護 居宅介護支援事業所）
  private writerStaffs: ChoiceAddOther[] = []; //作成者

  // 種別切り替え
  private selectedReportType = 0;
  private old: ProvisionReport = DefaultProvisionReport(); //情報提供書（切り替え時の値リセット用）
  private isSettingSelectedReportType = false;
  private isChangedValues = false; // 値が変更されたかどうか

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  /** 読み込み完了したかどうか */
  private isLoaded = false;

  //報告月をリアルタイムの今月にする
  private today(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 7);
    return today;
  }

  // 評価 できる/できない選択肢
  private canOrCanNot = [
    {
      value: 1,
      text: "できる"
    },
    {
      value: 2,
      text: "できない"
    }
  ];

  // 評価 なし/あり選択肢
  private withoutOrWith = [
    {
      value: 2,
      text: "なし"
    },
    {
      value: 1,
      text: "あり"
    }
  ];

  //画面上部アコーディオンリンク
  private localRegularLinks: Link[] = [
    {
      text: "報告月",
      key: "provision_month"
    },
    {
      text: "ステーション名",
      key: "station_name"
    },
    {
      text: "主治医師名",
      key: "doctor_name"
    },
    {
      text: "ADL",
      key: "adl"
    },
    {
      text: "病状・障害等の状態",
      key: "insured_status",
      attention: "※市町村等の場合のみ有効"
    },
    {
      text: "家族および主な介護者に係る情報",
      key: "family_info"
    },
    {
      text: "看護の内容",
      key: "care_content"
    },
    {
      text: "必要と考えられる保健福祉サービス",
      key: "service_needs"
    },
    {
      text: "そのほか特筆すべき事項",
      key: "notice"
    },
    {
      text: "学校宛",
      key: "to_school",
      attention: "※学校宛の場合のみ"
    },
    {
      text: "医療機関宛",
      key: "to_hospital",
      attention: "※医療機関宛の場合のみ"
    },
    {
      text: "作成者",
      key: "created_staff"
    },
    {
      text: "記載日",
      key: "write_date"
    }
  ];

  //画面上部アコーディオンリンク
  private localMouthRenkeiLinks: Link[] = [
    {
      text: "報告月",
      key: "provision_month"
    },
    {
      text: "ステーション名",
      key: "station_name"
    },
    {
      text: "基礎疾患",
      key: "underlying_condition"
    },
    {
      text: "基本情報",
      key: "mouth_basic_info"
    },
    {
      text: "口腔の健康状態の評価",
      key: "mouth_evaluation"
    },
    {
      text: "連絡事項",
      key: "messages"
    },
    {
      text: "作成者",
      key: "created_staff"
    },
    {
      text: "記載日",
      key: "write_date"
    }
  ];

  private get Links(): Link[] {
    const links: Link[] = [];

    if (this.provision_report.report_type == 2) {
      links.push(...this.localMouthRenkeiLinks);
    } else if (this.provision_report.report_type == 1) {
      links.push(...this.localRegularLinks);
      const index = links.findIndex(link => link.key == "to_school");
      links[index].add_info = this.beforTargetDateDisp;
    }

    if (
      this.provision_report.remand.id &&
      this.provision_report.remand.editor_staff_id
    ) {
      const index = links.findIndex(link => link.key == "remand");
      if (index < 0) {
        links.unshift({
          text: "差戻し情報",
          key: "remand",
          add_info: this.provision_report.remand.comment
        });
      }
    }

    return links;
  }

  private set Links(val: Link[]) {
    if (this.provision_report.report_type == 1) {
      this.localRegularLinks = val;
    } else if (this.provision_report.report_type == 2) {
      this.localMouthRenkeiLinks = val;
    }
  }

  private get StorageKey(): string {
    return `provision_report-${this.loginUser.id}-${this.patientId}-${this.provisionReportId}`;
  }

  //編集モード設定
  @Watch("provision_report", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      provision_report: this.provision_report,
      displayPreview: this.displayPreview
    });
    if (this.isLoaded) {
      this.isChangedValues = true;
    }
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  // 種類切り替え
  @Watch("selectedReportType") private watchSelectedReportType(
    newValue: number,
    oldValue: number
  ) {
    if (this.isSettingSelectedReportType) return;
    if (this.isChangedValues) {
      this.confirmChangeReportType(newValue, oldValue);
    } else {
      this.provision_report.report_type = newValue;
      this.$nextTick(() => {
        this.isChangedValues = false;
      });
    }
  }

  // 種類変更の確認ポップアップ
  private async confirmChangeReportType(newValue: number, oldValue: number) {
    if (
      await this.$openConfirm("変更した内容が破棄されます。よろしいですか？")
    ) {
      this.resetAsOld(newValue);
      this.$nextTick(() => {
        this.isChangedValues = false;
      });
    } else {
      this.setSelectedReportType(oldValue);
    }
  }

  // 選択中の種別を変更
  private setSelectedReportType(value: number) {
    this.isSettingSelectedReportType = true;
    this.selectedReportType = value;
    this.$nextTick(() => {
      this.isSettingSelectedReportType = false;
    });
  }

  // 口腔の評価
  @Watch("provision_report.open_mouth_flg") private watchOpenMouthFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.tooth_dirt_flg") private watchToothFirtFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.tongue_dirt_flg") private watchTongueDirtFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.blood_flg") private watchBloodFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.bite_flg") private watchBiteFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.muse_flg") private watchMuseFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.bukubuku_flg") private watchBukubukuFlg() {
    this.checkMouthEvaluation();
  }

  @Watch("provision_report.food_residue_flg") private watchFoodResidueFlg() {
    this.checkMouthEvaluation();
  }

  created() {
    this.provisionReportId = Number(this.$route.params.provisionreportid);
    this.patientId = Number(this.$route.params.id);
    this.fetchProvisionReport();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/provision`
      },
      {
        text: "情報提供書",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/provisionreport/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    // 情報提供療養費
    if (
      (this.provision_report.report_type == 1 &&
        this.informationProviderFlg == 0) ||
      (this.provision_report.report_type == 2 &&
        this.informationProviderMouthRenkeiFlg == 0)
    ) {
      await this.$openAlert(
        "看護記録Ⅰの機関情報が登録されていない為、提出できません"
      );
      return;
    }

    this.saveAction(2, "/api/patient/provisionreport/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.provision_report.patient_id = this.patientId;
    this.provision_report.status = status;
    this.makePdf(
      window.base_url + url,
      {
        provision_report: this.provision_report,
        is_preview: isPreview
      },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.provisionReportId = res.data.id;
          this.provision_report.id = res.data.id;
          this.provision_report.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { provisionreportid: String(this.provisionReportId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //削除
  private clickDelete() {
    this.provision_report.id = this.provisionReportId;
    this.provision_report.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/provisionreport/delete",
      {
        provision_report: this.provision_report
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview(): void {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.provision_report.patient_id = this.patientId;
      // this.provision_report.status = status;
      this.makePdf(window.base_url + "/api/patient/provisionreport/preview", {
        provision_report: this.provision_report
      });
    });
  }

  //情報提供書情報取得
  private fetchProvisionReport(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/provisionreport/get",
      {
        patient_id: this.patientId,
        provision_report_id: this.provisionReportId
      },
      async res => {
        this.informationProviderFlg = res.data.information_provider_flg;
        this.informationProviderMouthRenkeiFlg =
          res.data.information_provider_mouth_renkei_flg;
        if (
          this.informationProviderFlg == 0 &&
          this.informationProviderMouthRenkeiFlg == 0
        ) {
          await this.$openAlert(
            "看護記録Ⅰの機関情報が登録されていない為、提出はできません"
          );
        }
        this.adl_items = res.data.adl_items;
        this.underlying_condition_items = res.data.underlying_condition_items;
        this.providerId = res.data.provider_id;
        this.provider = res.data.provider;
        this.writerStaffs = res.data.writer_staffs;
        this.informationProvider1 = res.data.information_provider1;
        this.informationProvider2 = res.data.information_provider2;
        this.informationProvider3 = res.data.information_provider3;
        this.informationProviderDental = res.data.information_provider_dental;
        this.informationProviderSupport = res.data.information_provider_support;
        this.provision_report = res.data.provision_report;
        if (this.provisionReportId == 0) {
          this.provision_report.yearmonth = this.today();
          this.provision_report.remand = DefaultProvisionReportRemand();
        }
        this.old = this.deepCopy(res.data.provision_report);
        this.setSelectedReportType(this.provision_report.report_type);
        this.execAfterFetch(this.StorageKey, data => {
          this.provision_report = data.provision_report;
          this.displayPreview = data.displayPreview;
          this.setSelectedReportType(this.provision_report.report_type);
        });
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }
    );
  }

  //-----------------------------------------------------
  // 学校宛関連
  //-----------------------------------------------------
  //追加
  private addRow() {
    this.provision_report.calculates.unshift({
      id: 0,
      provision_report_id: 0,
      order_no: 0,
      target_yearmonth: "",
      reason: 0,
      personnel_id: this.providerId,
      provider: this.provider,
      created_at: "",
      updated_at: "",
      deleted_at: "",
      target_yearmonth_wareki: "",
      personnel: DefaultApplyPersonnel()
    });
  }
  //削除
  private async deleteRow(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.provision_report.calculates.splice(idx, 1);
    }
  }
  //回数（転園・転学）
  private get transferNumTimes(): number {
    let count = 0;
    for (const calculate of this.provision_report.calculates) {
      if (calculate.reason == 3 || calculate.reason == 4) {
        count += 1;
      }
    }
    return count;
  }
  //前回算定年月（今月より過去月の最新）
  private get beforTargetDate(): string {
    const today = this.today();

    const targetDates = [];
    for (const calculate of this.provision_report.calculates) {
      targetDates.push(calculate.target_yearmonth);
    }
    targetDates.sort(function(a, b) {
      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    });
    for (const targetDate of targetDates) {
      if (today > targetDate) {
        return targetDate;
      }
    }
    return "";
  }
  //前回算定年月（アコーディオンラベル表示）
  private get beforTargetDateDisp(): string {
    if (this.lawAmendmentDispFlg()) {
      return "前回算定年月：" + this.beforTargetDate;
    }
    return "";
  }
  //2020年4月法改正（学校宛算定年月部分の表示・非表示）
  private lawAmendmentDispFlg(): boolean {
    let flg = false;
    if (this.provision_report && this.provision_report.yearmonth >= "2020-04") {
      flg = true;
    }
    return flg;
  }

  //作成者：作成者が被っている場合
  private checkWriterStaff(): boolean | string {
    if (!this.provision_report.writer_staff1_id) {
      return true;
    }
    if (!this.provision_report.writer_staff2_id) {
      return true;
    }
    if (
      this.provision_report.writer_staff1_id !=
      this.provision_report.writer_staff2_id
    ) {
      return true;
    } else {
      return "作成者が重複しています";
    }
  }

  // チェック処理
  private checkedUnderlyingCondition(checked: boolean, checkedId: number) {
    const idx = this.findUnderlyingConditionIndex(checkedId);
    if (checked) {
      if (idx == -1) {
        // 追加
        this.provision_report.underlying_conditions.push({
          id: 0,
          provision_report_id: 0,
          provision_report_underlying_condition_item_id: checkedId,
          created_at: "",
          updated_at: "",
          deleted_at: "",
          item_name: ""
        });
      }
    } else {
      // 削除
      if (idx != -1) {
        this.provision_report.underlying_conditions.splice(idx, 1);
      }
    }
  }

  // 選択値
  protected underlyingConditionValue(checkId: number): boolean {
    return this.findUnderlyingCondition(checkId) ? true : false;
  }

  // 基礎疾患の検索
  private findUnderlyingCondition(
    checkId: number
  ): ProvisionReportUnderlyingCondition | undefined {
    return this.provision_report.underlying_conditions.find(
      (o: ProvisionReportUnderlyingCondition) => {
        return o.provision_report_underlying_condition_item_id == checkId;
      }
    );
  }

  // 基礎疾患のインデックス検索
  protected findUnderlyingConditionIndex(checkId: number): number {
    if (!this.provision_report.underlying_conditions) return -1;
    return this.provision_report.underlying_conditions.findIndex(
      (o: ProvisionReportUnderlyingCondition) => {
        return o.provision_report_underlying_condition_item_id == checkId;
      }
    );
  }

  // 変更内容をリセット
  // deepCopyは日付オブジェクトやMapなど一部のコピーが不完全です。現在の情報提供書のモデルではデータは欠落しないため使用しています。
  protected resetAsOld(newReportType: number) {
    // 種別のみ新しい値を入れる
    this.provision_report = this.deepCopy(this.old);
    this.provision_report.report_type = newReportType;
  }

  // 口腔の評価のチェック
  private checkMouthEvaluation() {
    if (
      this.provision_report.open_mouth_flg == 2 ||
      this.provision_report.tooth_dirt_flg == 1 ||
      this.provision_report.tongue_dirt_flg == 1 ||
      this.provision_report.blood_flg == 1 ||
      this.provision_report.bite_flg == 2 ||
      this.provision_report.muse_flg == 1 ||
      this.provision_report.bukubuku_flg == 2 ||
      this.provision_report.food_residue_flg == 1
    ) {
      this.provision_report.need_dentist_check_flg = 2;
    } else {
      this.provision_report.need_dentist_check_flg = 1;
    }
  }

  // //算定年月：法改正以前年月チェック
  // private chackLawAmendment(targetDate: string): boolean | string {
  //   if (targetDate < "2020-04") {
  //     return "2020年3月以前は登録できません";
  //   }
  //   return true;
  // }
}
