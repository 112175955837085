
















import { Component, Emit, Prop, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PatientMixin from "@/mixins/patientMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import BasePatientDoctorSelect from "@/components/patient/common_ibow/base/BasePatientDoctorSelect.vue";
import * as patient from "@/store/modules/patient/types";
import { SearchItem } from "@/components/patient/common_ibow/doctor";

const Patient = namespace(patient.name);

@Component({ components: { BasePatientDoctorSelect } })
export default class PatientDoctorSelect extends Mixins(
  PatientMixin,
  AxiosMixin
) {
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;

  @Ref("select") private readonly select!: BasePatientDoctorSelect;

  @Prop() value!: number;
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "医師" }) label!: string;
  @Prop({ default: false }) not_select!: boolean;
  @Prop({ default: true }) not_empty!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => [] }) rules!: unknown[];

  @Emit() private input(newValue: number) {
    return newValue;
  }

  /** 検索条件を入力した状態で検索ダイアログを開く */
  public openDialogWithCondition(cond: SearchItem) {
    this.select.openDialogWithCondition(cond);
  }

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  // 利用者マスタ情報取得
  private updateMaster() {
    this.postJsonCheck(
      window.base_url + "/api/patient/master/get",
      { patient_id: Number(this.$route.params.id) },
      res => {
        if (res.data) {
          this.setPatientMaster({ master: res.data });
        }
      }
    );
  }
}
