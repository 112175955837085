import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  Patient,
  DefaultPatient,
  PatientPreNursingMonth
} from "#/model/patient";
import { Office, DefaultOffice } from "#/model/agreement";
import PatientMixin from "@/mixins/patientMixin";
import EditMixin from "@/mixins/editMixin";
import * as patient from "@/store/modules/patient/types";
import { namespace } from "vuex-class";
import * as constant from "#/const";
import { RelationInstitution, MedicalInstitution } from "#/model/uniquemaster";
import { PRIREQUESTER_TYPE } from "./const";

const Patient = namespace(patient.name);

@Component
export default class Base extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  PatientMixin,
  EditMixin
) {
  /** 依頼元種類 */
  protected PRIREQUESTER_TYPE = PRIREQUESTER_TYPE;

  /** 利用者マスタ情報 */
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;
  /** 利用者情報(編集対象) */
  protected patient: Patient = DefaultPatient();
  /** 事業所情報 */
  protected office: Office = DefaultOffice();
  /** ケアマネ追加用事業所情報 */
  protected relationInstitutions: RelationInstitution[] = [];
  /** 医者追加用事業所情報 */
  protected medInstitutions: MedicalInstitution[] = [];

  /** 予防訪問看護減算起算開始月 */
  protected preNursingMonths: {
    id: number;
    exec_yearmonth: number; //リハ予防訪問看護実施年月
    is_auto: number; //自動入力フラグ
    is_checked: number; //チェックフラグ
    wareki: string; //和暦
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  }[] = [];

  /** 編集モードへの切り替え */
  @Watch("patient", { deep: true })
  protected watchPatient() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  /** 引落開始設定切り替え */
  @Watch("patient.info.withdrawal_st_kbn")
  protected async watchWithdrawal() {
    if (this.IsLoaded && this.patient.info.withdrawal_st_kbn == 1) {
      await this.$openAlert("引落開始設定が「開始する」に設定されました。");
    }
  }

  /** 事業所切り替え */
  @Watch("patient.info.office_id") watchOfficeId() {
    if (!this.IsLoaded) {
      return;
    }
    // 事業所変更時にマスタデータ再取得
    this.fetchPatientMaster();
  }

  /** リハ予防訪問看護減算起算開始月切り替え */
  @Watch("patient.info.prevent_gensan_month") protected setPreNursingMonth() {
    const preNursingMonths = this.makeCheckboxForPreNursingMonth(
      this.patient.info.prevent_gensan_month,
      this.patient.info.patient_pre_nursing_months
    );
    this.preNursingMonths = preNursingMonths;
  }

  /** リハ予防訪問看護実施月チェックボックス生成 */
  protected makeCheckboxForPreNursingMonth(
    preventGensanMonth: string,
    patientPreNursingMonth?: PatientPreNursingMonth[]
  ) {
    const thisMonth = new Date();
    thisMonth.setDate(1);
    const max = Number(
      `${thisMonth.getFullYear()}${("0" + (thisMonth.getMonth() + 1)).slice(
        -2
      )}`
    );

    // 減算起算開始月から、今月までデータを作る
    const preNursingMonths = [];
    if (preventGensanMonth) {
      const [year, month] = preventGensanMonth.split("-");

      const targetDate = new Date(Number(year), Number(month) - 1);
      let targetDateInt = Number(
        `${targetDate.getFullYear()}${("0" + (targetDate.getMonth() + 1)).slice(
          -2
        )}`
      );

      const warekiFormat = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
        era: "narrow",
        year: "numeric",
        month: "numeric"
      });

      let first = true;
      while (targetDateInt <= max) {
        const wareki = warekiFormat
          .format(targetDate)
          .replace("年", "/")
          .replace("月", "");

        // サーバーから取得した予防訪問看護減算起算開始月を反映する
        let isSet = false;
        if (patientPreNursingMonth) {
          for (const preNursing of patientPreNursingMonth) {
            if (preNursing.exec_yearmonth == targetDateInt) {
              preNursingMonths.push({
                ...preNursing,
                is_checked: 1,
                wareki: wareki
              });
              isSet = true;
              break;
            }
          }
        }

        if (!isSet) {
          const preNursing = {
            id: 0,
            exec_yearmonth: targetDateInt,
            is_auto: 1,
            is_checked: 0,
            wareki: wareki
          };
          if (first) {
            preNursing.is_checked = 1;
            preNursing.is_auto = 0;
          }
          preNursingMonths.push(preNursing);
        }

        first = false;
        targetDate.setMonth(targetDate.getMonth() + 1);
        targetDateInt = Number(
          `${targetDate.getFullYear()}${(
            "0" +
            (targetDate.getMonth() + 1)
          ).slice(-2)}`
        );
      }
    }
    return preNursingMonths;
  }

  /** ADLから心身の状態を反映 */
  protected applyADL() {
    this.postJsonCheck(
      window.base_url + "/api/patient/info/adl/get",
      { patient_id: this.patient.id },
      res => {
        this.patient.info.mind_and_body_state = res.data.mind_and_body_state;
      }
    );
  }

  /** 保存時のグループの設定変更 */
  protected setGroupParameter() {
    this.patient.groups.forEach(patientGroup => {
      if (patientGroup.group_id < 1) {
        patientGroup.is_icon_display = 0;
      }
    });
  }

  /** 訪問場所・死亡場所の施設コードリセット */
  protected resetFacilityCode() {
    // 訪問場所
    if (
      this.patient.info.visit_place_div !=
      constant.PATIENT.VISIT_PLACE_DIV_FACILITY
    ) {
      this.patient.info.visit_place_facility_div = 0;
    }

    // 死亡場所
    if (this.patient.info.death_place != 2) {
      this.patient.info.death_place_facility_div = 0;
    }
  }

  /** 事業所変更時のマスタデータ取得(基礎情報・履歴編集用) */
  private fetchPatientMaster() {
    this.postJsonCheck(
      window.base_url + "/api/patient/master/get",
      {
        patient_id: Number(this.$route.params.id),
        office_id: this.patient.info.office_id
      },
      res => {
        if (res.data) {
          // 医療機関と関連機関をセットし直す
          this.setPatientMaster({ master: res.data });
        }
      }
    );
  }
}
