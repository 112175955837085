

































import { Component, Mixins, Prop } from "vue-property-decorator";
import { VisitModel } from "@/components/patient/record2/types";
import * as constant from "#/const";
import RulesMixin from "#/mixins/rulesMixin";

interface Gaf {
  maxPoint: number;
  minPoint: number;
}

@Component
export default class GafEvaluate extends Mixins(RulesMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private model!: VisitModel;

  private gafHeaders = [
    { text: "GAF評価点", value: "point" },
    { text: "説明", value: "description" }
  ];

  private gafItems = [
    {
      maxPoint: 100,
      minPoint: 91,
      point: "100-91",
      description:
        "広範囲の行動にわたって最高に機能しており、生活上の問題で手に負えないものは何もなく、その人の多数の長所があるために他の人々から求められている。症状は何もない。"
    },
    {
      maxPoint: 90,
      minPoint: 81,
      point: "90-81",
      description:
        "症状がまったくないか、ほんの少しだけ（例：試験前の軽い不安）、すべての面でよい機能で、広範囲の活動に興味をもち参加し、社交的にはそつがなく、生活に大体満足し、日々のありふれた問題や心配以上のものはない（例：たまに、家族と口論する）。"
    },
    {
      maxPoint: 80,
      minPoint: 71,
      point: "80-71",
      description:
        "症状があったとしても、心理的社会的ストレスに対する一過性で予期される反応である（例：家族と口論した後の集中困難）、社会的、職業的または学校の機能にごくわずかな障害以上のものはない（例：学業で一時遅れをとる）。"
    },
    {
      maxPoint: 70,
      minPoint: 61,
      point: "70-61",
      description:
        "いくつかの軽い症状がある（例：抑うつ気分と軽い不眠）、または、社会的、職業的または学校の機能に、いくらかの困難はある（例：時にずる休みをしたり、家の金を盗んだりする）が、全般的には、機能はかなり良好であって、有意義な対人関係もかなりある。"
    },
    {
      maxPoint: 60,
      minPoint: 51,
      point: "60-51",
      description:
        "中等度の症状（例：感情が平板的で、会話がまわりくどい、時に、恐慌発作がある）、または、社会的、職業的、または学校の機能における中等度の障害（例：友達が少ない、仲間や仕事の同僚との葛藤）。"
    },
    {
      maxPoint: 50,
      minPoint: 41,
      point: "50-41",
      description:
        "重大な症状（例：自殺の考え、強迫的儀式がひどい、しょっちゅう万引する）、または、社会的、職業的または学校の機能において何か重大な障害（友達がいない、仕事が続かない）。"
    },
    {
      maxPoint: 40,
      minPoint: 31,
      point: "40-31",
      description:
        "現実検討か意思伝達にいくらかの欠陥（例：会話は時々、非論理的、あいまい、または関係性がなくなる）、または、仕事や学校、家族関係、判断、思考または気分、など多くの面での粗大な欠陥（例：抑うつ的な男が友人を避け家族を無視し、仕事ができない。子どもが年下の子どもを殴り、家で反抗的で、学校では勉強ができない）。"
    },
    {
      maxPoint: 30,
      minPoint: 21,
      point: "30-21",
      description:
        "行動は妄想や幻覚に相当影響されている。または意思伝達か判断に粗大な欠陥がある（例：時々、滅裂、ひどく不適切にふるまう、自殺の考えにとらわれている）、または、ほとんどすべての面で機能することができない（例：一日中床についている、仕事も家庭も友達もない）。"
    },
    {
      maxPoint: 20,
      minPoint: 11,
      point: "20-11",
      description:
        "自己または他者を傷つける危険がかなりあるか（例：死をはっきり予期することなしに自殺企図、しばしば暴力的、躁病性興奮）、または、時には最低限の身辺の清潔維持ができない（例：大便を塗りたくる）、または、意思伝達に粗大な欠陥（例：ひどい滅裂か無言症）。"
    },
    {
      maxPoint: 10,
      minPoint: 1,
      point: "10-1",
      description:
        "自己または他者をひどく傷つける危険が続いている（例：何度も暴力を振るう）、または最低限の身辺の清潔維持が持続的に不可能、または、死をはっきり予測した重大な自殺行為。"
    },
    {
      maxPoint: 0,
      minPoint: 0,
      point: "0",
      description: "情報不十分"
    }
  ];

  private get localGafScore(): string {
    let score = "";
    if (this.model.gaf.gaf_score !== -1) {
      score = this.model.gaf.gaf_score + "";
    }
    return score;
  }
  private set localGafScore(val: string) {
    let score = -1;
    if (val !== "") {
      score = Number(val);
      if (isNaN(score)) {
        score = 0;
      }
    }
    this.model.gaf.gaf_score = score;
  }

  private itemClass(item: Gaf) {
    if (this.model.gaf.gaf_score === null || this.model.gaf.gaf_score < 0) {
      return;
    }
    if (
      this.model.gaf.gaf_score >= item.minPoint &&
      this.model.gaf.gaf_score <= item.maxPoint &&
      !this.model.gaf.is_family_visit
    ) {
      return "checked";
    }
  }

  /** バリデーション GAF評価点 */
  private get validateGafScore(): boolean | string {
    // チェックを行わないパターン
    // ・自費
    // ・オンライン訪問(電話対応)
    // ・家族への訪問（GAF評価なし）
    // ・精神訪問以外
    // ただし、家族への訪問（GAF評価なし）時、「評価なし(-1)」「0〜100」である必要あり(v1挙動)

    // 自費
    if (
      this.model.nursing_type === constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE
    ) {
      return true;
    }
    // オンライン訪問（電話対応）
    if (this.model.online_visit_flg) {
      return true;
    }
    // 精神訪問以外
    if (
      this.model.insurance_div !== constant.VISIT_RECORD.INSURANCE_DIV_MEDICAL
    ) {
      return true;
    }
    // 有効な指示書が精神の場合のみチェック
    if (this.model.mental_div !== constant.PATIENT.MENTAL_DIV_MENTAL) {
      return true;
    }

    // 家族への訪問（GAF評価なし）
    if (this.model.gaf.is_family_visit) {
      // 空欄（評価なし）はOK
      if (this.model.gaf.gaf_score === -1) {
        return true;
      }
      // 何か入っているなら、0〜100の範囲
      return this.rangeNumber(
        this.model.gaf.gaf_score,
        0,
        100,
        "0〜100で入力してください"
      );
    }

    // 通常のチェックが必要なパターン
    // ・必須（空欄=評価なしはNG）
    // ・0〜100

    // 必須チェック
    let valid = this.minNumber(this.model.gaf.gaf_score, 0, "必須です");
    if (valid !== true) {
      return valid;
    }

    // 最小値・最大値チェック
    valid = this.rangeNumber(
      this.model.gaf.gaf_score,
      0,
      100,
      "0〜100で入力してください"
    );
    if (valid !== true) {
      return valid;
    }

    return true;
  }
}
