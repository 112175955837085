





























import { Component } from "vue-property-decorator";
import SenmonCareDiv from "./SenmonCareDiv.vue";

@Component
export default class SenmonCareDivV2 extends SenmonCareDiv {}
