












































































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAddV2 from "./OneMonthAddV2.vue";

@Component
export default class OneMonthAddV3 extends OneMonthAddV2 {
  /** 口腔連携強化加算についての説明用ダイアログ */
  protected isOpenInfoExpenseGuide = false;

  created(): void {
    this.setNursingSpecialDiv();
    this.setCareKyokaFlg();
    this.setNursingUrgentFlg();
  }

  // 緊急時訪問看護加算
  protected setNursingUrgentFlg() {
    if (this.receiptVisitRecord.nursing_urgent_flg == 1) {
      // 緊急時訪問看護加算Ⅱの場合
      this.nursing_urgent_flg1 = false;
      this.nursing_urgent_flg2 = true;
    }
    if (this.receiptVisitRecord.nursing_urgent_flg == 2) {
      // 緊急時訪問看護加算Ⅰの場合
      this.nursing_urgent_flg1 = true;
      this.nursing_urgent_flg2 = false;
    }
  }

  /** 緊急時訪問看護加算Ⅰ */
  protected nursing_urgent_flg1 = false;

  /** 緊急時訪問看護加算Ⅱ */
  protected nursing_urgent_flg2 = false;

  //緊急時訪問看護加算Ⅰ
  @Watch("nursing_urgent_flg1")
  protected handleNursingUrgentFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.nursing_urgent_flg1 == true) {
      this.receiptVisitRecord.nursing_urgent_flg = 2;
    }
    if (this.nursing_urgent_flg1 == false) {
      this.receiptVisitRecord.nursing_urgent_flg = 0;
    }
  }
  //緊急時訪問看護加算Ⅱ
  @Watch("nursing_urgent_flg2")
  protected handleNursingUrgentFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.nursing_urgent_flg2 == true) {
      this.receiptVisitRecord.nursing_urgent_flg = 1;
    }
    if (this.nursing_urgent_flg2 == false) {
      this.receiptVisitRecord.nursing_urgent_flg = 0;
    }
  }

  //専門管理加算
  protected disableCheckSpecialManagementAdd(): boolean {
    let result = false;
    if (
      this.officeHistory.care_is_senmon_kangoshi != 1 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //遠隔死亡診断補助加算
  protected disableCheckCareRemoteDeathAssistDiv(): boolean {
    let result = false;
    if (
      this.officeHistory.care_terminal_care_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.care_div == 2
    ) {
      result = true;
    }
    return result;
  }

  //緊急時訪問看護加算Ⅰ
  protected disableCheckNursingUrgentFlg1(): boolean {
    let result = false;
    // 事業所設定で緊急時訪問看護加算Ⅰ(valueは2)が未設定 または オンライン訪問時は disabled
    if (
      this.officeHistory.care_kinkyu_houmon_add != 2 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //緊急時訪問看護加算Ⅱ
  protected disableCheckNursingUrgentFlg2(): boolean {
    let result = false;
    // 事業所設定で緊急時訪問看護加算Ⅱ(valueは1)が未設定 または オンライン訪問時は disabled
    if (
      this.officeHistory.care_kinkyu_houmon_add != 1 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  // 口腔連携強化加算
  protected disableCheckMouthRenkeiKyokaFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.care_mouth_renkei_kyoka_add != 1 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //緊急時訪問看護
    if (this.disableCheckNursingUrgentFlg()) {
      this.nursing_urgent_flg1 = false;
      this.nursing_urgent_flg2 = false;
      this.receiptVisitRecord.nursing_urgent_flg = 0;
    }
    //特別管理
    if (this.disableCheckNursingSpecialDiv()) {
      this.nursing_special_div1 = false;
      this.nursing_special_div2 = false;
      this.receiptVisitRecord.nursing_special_div = 0;
    }
    //ターミナルケア
    if (this.disableCheckNursingTerminalFlg()) {
      this.receiptVisitRecord.nursing_terminal_flg = 0;
    }
    //中山間部
    if (this.disableCheckMountainFlg()) {
      this.receiptVisitRecord.mountain_flg = 0;
    }
    //連携強化
    if (this.disableCheckCoopStrengtheningFlg()) {
      this.receiptVisitRecord.coop_strengthening_flg = 0;
    }
    //連携強化日
    if (this.disableCheckCoopStrengtheningDate()) {
      this.receiptVisitRecord.coop_strengthening_date = "";
    }
    //看護体制強化加算I
    if (this.disableCheckCareKyokaFlg1() && !this.care_kyoka_flg2) {
      this.care_kyoka_flg1 = false;
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
    //看護体制強化加算II
    if (this.disableCheckCareKyokaFlg2() && !this.care_kyoka_flg1) {
      this.care_kyoka_flg2 = false;
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
    //専門管理加算
    if (this.disableCheckSpecialManagementAdd()) {
      this.receiptVisitRecord.special_management_add = 0;
    }
    //遠隔死亡診断補助加算
    if (this.disableCheckCareRemoteDeathAssistDiv()) {
      this.receiptVisitRecord.remote_death_assist = 0;
    }
    //口腔連携強化加算
    if (this.disableCheckMouthRenkeiKyokaFlg()) {
      this.receiptVisitRecord.mouth_renkei_kyoka_flg = 0;
    }
  }
}
