













import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class SenmonCareDiv extends CarePartBase {
  //職員区分変更時、専門区分の中身未選択にする
  @Watch("receiptVisitRecord.staff_div") protected handleSenmonCareDiv() {
    if (!this.isLoaded) {
      return;
    }
    this.receiptVisitRecord.senmon_care_div = 0;
  }

  /** 専門区分に関するバリデーションチェック */
  protected checkSenmonCareDiv(): boolean | string {
    // 専門管理加算が有効になっている場合は、専門区分未選択NG
    if (
      this.receiptVisitRecord.senmon_care_div == 0 &&
      this.receiptVisitRecord.special_management_add == 1
    ) {
      return "専門区分を選択してください";
    }

    return true;
  }
}
