import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { CalendarEvent, VForm } from "@/types";
import {
  ReceiptVisitRecord,
  ExceptForRegularTourDay,
  RegistrationMessage,
  DefaultRegistrationMessage,
  ResetMessage,
  DefaultResetMessage
} from "#/model/receipt";
import { OfficeHistory } from "#/model/agreement";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class ReceiptEditAddRegularBace extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: 0 }) protected readonly patientId!: number;
  @Prop({ default: () => ({}) }) protected readonly event!: CalendarEvent;
  @Prop({ default: () => ({}) })
  protected readonly receiptVisitRecord!: ReceiptVisitRecord;
  @Prop({ default: "" }) protected readonly yearmonth!: string;
  @Prop({ default: () => ({}) })
  protected readonly officeHistory!: OfficeHistory;
  @Prop({ default: () => ({}) }) protected readonly patientHeader!: VForm;
  @Prop({ default: "" }) protected readonly endDay!: string;
  @Prop({ default: 0 }) protected readonly isBilledCare!: number;
  @Prop({ default: () => [] }) protected readonly events!: CalendarEvent[];
  @Prop({ default: false }) protected readonly homeCancerCareFlg!: boolean;
  @Prop({ default: "" }) protected readonly homeCancerCareDay!: string;
  @Prop({ default: () => [] })
  protected readonly exceptForRegularTourDays!: ExceptForRegularTourDay[];
  @Prop({ default: 0 }) protected readonly isHonobono!: number;
  @Prop({ default: "" }) protected readonly honobonoJigyoKnj!: string;
  @Prop({ default: false })
  protected readonly hasInfoProviderMouthRenkei!: boolean;
  //親のgetCalender動かす
  @Emit()
  protected update() {
    return;
  }

  //親画面にメッセージ表示
  @Emit()
  protected message(message: string) {
    return message;
  }

  /** 読み込み完了フラグ */
  protected isLoaded = false;

  /** 登録時メッセージ */
  protected registrationMessage: RegistrationMessage = DefaultRegistrationMessage();

  /** リセット時メッセージ */
  protected resetMessage: ResetMessage = DefaultResetMessage();

  /** サービス訪問（自費） */
  protected service_visit = false;

  //医療保険に変更（介護訪問なのに介護保険がないもしくは、指示書が医療扱い）
  protected judgeMed(): boolean {
    let result = false;

    if (
      this.event.care_insurance.id == 0 ||
      this.event.indicate.diseases.length > 0 ||
      this.event.special_indicate.id != 0 ||
      (this.event.indicate.indicate_div == 2 &&
        this.event.indicate.is_dementia != 1)
    ) {
      result = true;
    }

    return result;
  }

  //リセットメッセージ
  protected resetColumns(): string {
    let resetColumns = "";
    if (this.event.visit_record.visit_content_div == 2) {
      resetColumns += "[緊急時訪問（介護）]";
    }
    if (
      this.event.visit_record.service_time_div >= 1 &&
      this.event.visit_record.service_time_div <= 13
    ) {
      resetColumns += "[提供時間]";
    }

    return resetColumns;
  }

  mounted(): void {
    this.registrationMessage = DefaultRegistrationMessage();
    this.$nextTick(() => {
      this.isLoaded = true;
    });
  }
}
