import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { WindowPostMessage } from "./types";
import { SET_IS_EDIT } from "@/store/types";
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin, { wait: true });

Vue.config.productionTip = false;

/* 共通コンポーネント */
// ボタン
import AppButton from "#/components/AppButton.vue";
Vue.component("AppButton", AppButton);
// 選択可能ボタン
import AppSelectableButton from "#/components/AppSelectableButton.vue";
Vue.component("AppSelectableButton", AppSelectableButton);
// セレクトボックス
import AppSelect from "#/components/AppSelect.vue";
Vue.component("AppSelect", AppSelect);
// オートコンプリート
import AppAutocomplete from "#/components/AppAutocomplete.vue";
Vue.component("AppAutocomplete", AppAutocomplete);
// チェックボックス
import AppCheckbox from "#/components/AppCheckbox.vue";
Vue.component("AppCheckbox", AppCheckbox);
// チェックボックスグループ
import AppCheckboxGroup from "#/components/AppCheckboxGroup.vue";
Vue.component("AppCheckboxGroup", AppCheckboxGroup);
// スイッチ
import AppSwitch from "#/components/AppSwitch.vue";
Vue.component("AppSwitch", AppSwitch);
// テキストフィールド
import AppTextField from "#/components/AppTextField.vue";
Vue.component("AppTextField", AppTextField);
// 数値テキストフィールド(戻り値number)
import NumberInput from "#/components/NumberInput.vue";
Vue.component("NumberInput", NumberInput);
// 数値テキストフィールド(戻り値string)
import NumberInputString from "#/components/NumberInputString.vue";
Vue.component("NumberInputString", NumberInputString);
// テキストエリア
import AppTextArea from "#/components/AppTextArea.vue";
Vue.component("AppTextArea", AppTextArea);
// マンスピッカー（年月）
import TextMonthChangePicker from "#/components/TextMonthChangePicker.vue";
Vue.component("AppMonthPicker", TextMonthChangePicker);
// デイトピッカー（年月日）
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
Vue.component("AppDayPicker", TextDayChangePicker);
// タイムピッカー
import AppTimePicker from "#/components/AppTimePicker.vue";
Vue.component("AppTimePicker", AppTimePicker);
// タイムピッカー文字
import AppTimePickerString from "#/components/AppTimePickerString.vue";
Vue.component("AppTimePickerString", AppTimePickerString);
// デイトタイムピッカー（年月日時分）
import TextDatetimeChangePicker from "#/components/TextDatetimeChangePicker.vue";
Vue.component("AppDatetimePicker", TextDatetimeChangePicker);
// リンクボタン
import AppLinkButton from "#/components/AppLinkButton.vue";
Vue.component("AppLinkButton", AppLinkButton);
// タイトル
import AppTitle from "#/components/AppTitle.vue";
Vue.component("AppTitle", AppTitle);
// 第２タイトル
import AppSecondTitle from "#/components/AppSecondTitle.vue";
Vue.component("AppSecondTitle", AppSecondTitle);
// サブタイトル
import AppSubTitle from "#/components/AppSubTitle.vue";
Vue.component("AppSubTitle", AppSubTitle);
// セクションタイトル(左に縦棒が付く)
import AppSectionTitle from "#/components/AppSectionTitle.vue";
Vue.component("AppSectionTitle", AppSectionTitle);
// サブヘッダー
import AppSubHeader from "#/components/AppSubHeader.vue";
Vue.component("AppSubHeader", AppSubHeader);
// 電話番号エリア
import AppPhoneArea from "#/components/AppPhoneArea.vue";
Vue.component("AppPhoneArea", AppPhoneArea);
// ダイアログ
import AppDialog from "#/components/AppDialog.vue";
Vue.component("AppDialog", AppDialog);
// 小ダイアログ
import AppMiniDialog from "#/components/AppMiniDialog.vue";
Vue.component("AppMiniDialog", AppMiniDialog);
// ページング
import AppPagination from "#/components/AppPagination.vue";
Vue.component("AppPagination", AppPagination);
// ファイル
import AppFileInput from "#/components/AppFileInput.vue";
Vue.component("AppFileInput", AppFileInput);
// ローディングダイアログ
import AppLoadingDialog from "#/components/AppLoadingDialog.vue";
Vue.component("AppLoadingDialog", AppLoadingDialog);
// フレックスエリア
import AppFlex from "#/components/AppFlex.vue";
Vue.component("AppFlex", AppFlex);
// 半角カナエリア
import AppHanKanaField from "#/components/AppHanKanaField.vue";
Vue.component("AppHankanaField", AppHanKanaField);
// コンボボックス
import AppCombobox from "#/components/AppCombobox.vue";
Vue.component("AppCombobox", AppCombobox);
// トグルボタン
import AppToggleButton from "#/components/AppToggleButton.vue";
Vue.component("AppToggleButton", AppToggleButton);
// 曜日選択ボタン
import AppWeekdayButton from "#/components/AppWeekdayButton.vue";
Vue.component("AppWeekdayButton", AppWeekdayButton);
// パンくずリスト
import AppBreadcrumbs from "#/components/AppBreadcrumbs.vue";
Vue.component("AppBreadcrumbs", AppBreadcrumbs);
// シート
import AppSheet from "#/components/AppSheet.vue";
Vue.component("AppSheet", AppSheet);
// アイコン
import AppIcon from "#/components/AppIcon.vue";
Vue.component("AppIcon", AppIcon);
// タブ
import AppTabs from "#/components/AppTabs.vue";
Vue.component("AppTabs", AppTabs);
// タブアイテム
import AppTab from "#/components/AppTab.vue";
Vue.component("AppTab", AppTab);
// サイドバー
import AppSideBar from "#/components/AppSideBar.vue";
Vue.component("AppSideBar", AppSideBar);
// 日・週・月ボタン
import RangeChangeButtons from "#/components/RangeChangeButtons.vue";
Vue.component("RangeChangeButtons", RangeChangeButtons);
// カレンダー
import AppCalendar from "#/components/AppCalendar.vue";
Vue.component("AppCalendar", AppCalendar);
// ツールチップ
import AppToolTip from "#/components/AppToolTip.vue";
Vue.component("AppToolTip", AppToolTip);
// データテーブル
import AppDataTable from "#/components/AppDataTable.vue";
Vue.component("AppDataTable", AppDataTable);
// 左サイドバー
import AppLeftSideBar from "#/components/AppLeftSideBar.vue";
Vue.component("AppLeftSideBar", AppLeftSideBar);
// ラジオボタングループ
import AppRadioGroup from "#/components/AppRadioGroup.vue";
Vue.component("AppRadioGroup", AppRadioGroup);
// ラジオボタン
import AppRadio from "#/components/AppRadio.vue";
Vue.component("AppRadio", AppRadio);
// 検索パネル
import SearchPanel from "#/components/SearchPanel.vue";
Vue.component("SearchPanel", SearchPanel);
// チップ
import AppChip from "#/components/AppChip.vue";
Vue.component("AppChip", AppChip);
// 絞り込みボタン
import AppFilterButton from "#/components/AppFilterButton.vue";
Vue.component("AppFilterButton", AppFilterButton);
// 画像 or 頭文字1文字 アイコン
import AppIconOrInitials from "#/components/AppIconOrInitials.vue";
Vue.component("AppIconOrInitials", AppIconOrInitials);
// チャット基盤
import ChatBase from "#/components/chat/AppChatBase.vue";
Vue.component("ChatBase", ChatBase);
// ファイルをドラッグアンドドロップで取り込めるやつ
import AppFileDragAndDrop from "#/components/AppFileDragAndDrop.vue";
Vue.component("AppFileDragAndDrop", AppFileDragAndDrop);

// エキスパンション群
import AppExpansionPanelGroups from "#/components/AppExpansionPanelGroups.vue";
Vue.component("AppExpansionPanelGroups", AppExpansionPanelGroups);
import AppExpansionPanels from "#/components/expantion/AppExpansionPanels.vue";
Vue.component("AppExpansionPanels", AppExpansionPanels);
import AppExpansionPanel from "#/components/expantion/AppExpansionPanel.vue";
Vue.component("AppExpansionPanel", AppExpansionPanel);
import AppExpansionPanelHeader from "#/components/expantion/AppExpansionPanelHeader.vue";
Vue.component("AppExpansionPanelHeader", AppExpansionPanelHeader);
import AppExpansionPanelContent from "#/components/expantion/AppExpansionPanelContent.vue";
Vue.component("AppExpansionPanelContent", AppExpansionPanelContent);
import AppConfirm from "#/components/AppAlertConfirm.vue";
Vue.component("AppConfirm", AppConfirm);

declare module "vue/types/vue" {
  interface Vue {
    $openAlert: (text: string, saveLabel?: string) => Promise<boolean>;
    $openConfirm: (
      text: string,
      saveLabel?: string,
      cancelLabel?: string
    ) => Promise<boolean>;
  }
}

/** 遷移時の編集状態チェック */
export const cantMoving = async () => {
  //編集状態でない場合は、遷移OK
  if (!store.state.is_edit) {
    return false;
  }

  if (
    await Vue.prototype.$openConfirm(
      "変更した内容が破棄されます。よろしいですか？"
    )
  ) {
    //OKの場合
    store.commit(SET_IS_EDIT, false);
    return false;
  }

  //キャンセルの場合
  return true;
};

// postMessageをトリガーとする画面遷移
export const moveByPostMessage = (event: MessageEvent) => {
  if (event.origin !== location.origin) {
    return;
  }
  const msgData: WindowPostMessage = event.data;
  if (msgData.movePath) {
    // このタブを開いた元の画面が、画面遷移するよう命令した時、URLが別なら遷移する
    if (location.href !== location.origin + msgData.movePath) {
      router.push(msgData.movePath);
    }
  }
};

//before ナビゲーション定義
router.beforeEach(async (to, from, next) => {
  if (await cantMoving()) {
    next(false);
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
