
































import { Component } from "vue-property-decorator";
import VisitInfo from "./VisitInfo.vue";

@Component
export default class VisitInfoV3 extends VisitInfo {
  protected chackOnlineVisit(): boolean | string {
    if (this.event.start.length < 10) {
      return true;
    }
    if (
      this.receiptVisitRecord.online_visit_flg == 1 &&
      !this.ServiceVisit &&
      10 <= this.event.start.length &&
      "2023-07-31" < this.event.start.substring(0, 10)
    ) {
      return (
        "コロナ5類対応によりオンライン訪問（電話訪問）の臨時対応が以下の期限で対応終了になります。\r\n" +
        "　医療：〜2023年7月31日\r\n" +
        "　介護：〜2023年5月7日\r\n" +
        "オンライン訪問（電話対応）をオンにする場合は、「サービス訪問（自費）」を選択してください。"
      );
    }
    return true;
  }
}
