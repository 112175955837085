
































































































































import { Component, Watch } from "vue-property-decorator";
import EveryTimeV2 from "./EveryTimeV2.vue";

@Component
export default class EveryTimeV3 extends EveryTimeV2 {
  created(): void {
    this.setServiceFlg();
    this.setSameBuildingFlg();
    this.setTwoPersonFlg();
    this.setNursingLeaveInstFlg();
  }

  //サービス提供体制
  protected setServiceFlg() {
    if (this.receiptVisitRecord.service_flg == 1) {
      this.service_flg1 = true;
      this.service_flg2 = false;
    }
    if (this.receiptVisitRecord.service_flg == 2) {
      this.service_flg1 = false;
      this.service_flg2 = true;
    }
  }

  /** サービス提供体制Ⅰ */
  protected service_flg1 = false;

  /** サービス提供体制Ⅱ */
  protected service_flg2 = false;

  //サービス提供体制Ⅰ
  @Watch("service_flg1")
  protected handleServiceFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.service_flg1 == true) {
      this.receiptVisitRecord.service_flg = 1;
      this.service_flg2 = false;
    }
    if (this.service_flg1 == false && this.service_flg2 == false) {
      this.receiptVisitRecord.service_flg = 0;
    }
  }

  //サービス提供体制Ⅱ
  @Watch("service_flg2")
  protected handleServiceFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.service_flg2 == true) {
      this.receiptVisitRecord.service_flg = 2;
      this.service_flg1 = false;
    }
    if (this.service_flg1 == false && this.service_flg2 == false) {
      this.receiptVisitRecord.service_flg = 0;
    }
  }

  //サービス提供体制Ⅰ
  protected disableCheckServiceFlg1V3(): boolean {
    let result = false;
    if (
      this.officeHistory.care_service_supply_add == 0 ||
      this.officeHistory.care_service_supply_add == 2
    ) {
      result = true;
    }
    return result;
  }

  //サービス提供体制Ⅱ
  protected disableCheckServiceFlg2V3(): boolean {
    let result = false;
    if (
      this.officeHistory.care_service_supply_add == 0 ||
      this.officeHistory.care_service_supply_add == 1
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //サービス提供体制Ⅰ
    if (this.disableCheckServiceFlg1V3() && this.service_flg2 == false) {
      this.service_flg1 = false;
      this.receiptVisitRecord.service_flg = 0;
    }
    //サービス提供体制Ⅱ
    if (this.disableCheckServiceFlg2V3() && this.service_flg1 == false) {
      this.service_flg2 = false;
      this.receiptVisitRecord.service_flg = 0;
    }
    //サービス提供体制加算なし
    if (this.disableCheckServiceFlg1V3() && this.disableCheckServiceFlg2V3()) {
      this.service_flg1 = false;
      this.service_flg2 = false;
      this.receiptVisitRecord.service_flg = 0;
    }

    //同一建物
    if (this.disableCheckSameBuildingFlgV2()) {
      this.same_building_flg1 = false;
      this.same_building_flg2 = false;
      this.receiptVisitRecord.same_building_flg = 0;
    }
    //提供時間
    if (this.disableCheckNursingTimeZoneDiv()) {
      this.receiptVisitRecord.nursing_time_zone_div = 0;
    }
    //2人訪問
    if (this.disableCheckTwoPersonV2()) {
      this.two_person_flg1 = false;
      this.two_person_flg2 = false;
      this.receiptVisitRecord.two_person_flg = 0;
      this.receiptVisitRecord.half_hour_flg = 0;
    }
    //退院時共同指導日
    if (this.disableCheckNursingLeaveInstDate()) {
      this.receiptVisitRecord.nursing_leave_inst_date = "";
      this.receiptVisitRecord.nursing_leave_inst_date2 = "";
    }
  }
}
