
































import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";
import { EventType } from "#/model/schedule/calendarType";

@Component
export default class TimeDiv extends CarePartBase {
  //提供時間表示
  protected get dispNursingTimeDiv(): string {
    let nursingTimeDiv = "";
    //職員区分がPT,OT,STの場合
    if (
      this.receiptVisitRecord.staff_div == 4 ||
      this.receiptVisitRecord.staff_div == 5 ||
      this.receiptVisitRecord.staff_div == 6
    ) {
      let count = 0;
      for (const monthEvent of this.events) {
        //同日のリハビリ訪問が対象
        if (
          monthEvent.type == EventType.Record2NursingCare &&
          this.event.visit_record.visit_date_wareki ==
            monthEvent.visit_record.visit_date_wareki &&
          (monthEvent.visit_record.staff.job == "PT" ||
            monthEvent.visit_record.staff.job == "OT" ||
            monthEvent.visit_record.staff.job == "ST")
        ) {
          //提供時間区分 介護保険/OT・PT・ST（他ステーション訪問あり）の場合 12:訪看I5・2超(20分) 13:訪看I5・2超(40分)
          //提供時間区分が上記の場合は「訪看I5・2超」確定
          if (
            monthEvent.visit_record.service_time_div == 12 ||
            monthEvent.visit_record.service_time_div == 13
          ) {
            return "訪看I5・2超";
          }
          //対象訪問以外
          if (this.event.id != monthEvent.id) {
            count++;
          }
        }
      }

      if (count <= 1) {
        nursingTimeDiv = "訪看I5";
      }
      if (count >= 2) {
        nursingTimeDiv = "訪看I5・2超";
      }
    }
    return nursingTimeDiv;
  }

  //時間区分
  protected chackNursingTimeDiv(): boolean | string {
    //オンライン訪問の算定は5/7までなので下記チェックもその期間までで終了
    if (
      10 <= this.event.start.length &&
      this.event.start.substring(0, 10) <= "2023-05-07"
    ) {
      //オンライン訪問の場合20分未満しか選択不可（職員区分:看護師・准看護師・専門）
      if (
        this.receiptVisitRecord.online_visit_flg == 1 &&
        this.receiptVisitRecord.nursing_time_div != 1 &&
        (this.receiptVisitRecord.staff_div == 1 ||
          this.receiptVisitRecord.staff_div == 2 ||
          this.receiptVisitRecord.staff_div == 3)
      ) {
        return "オンライン訪問のときは時間区分に20分未満のみ選択できます";
      }
    }

    return true;
  }

  //時間区分
  @Watch("receiptVisitRecord.nursing_time_div")
  protected handleNursingTimeDiv() {
    if (!this.isLoaded) {
      return;
    }
    this.handleHalfHourFlg();
  }
}
