














import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import Draggable from "vuedraggable";
import { DiseaseWord } from "#/model/patient";

@Component({
  components: {
    Draggable
  }
})
export default class AppDiseaseChip extends Mixins(AxiosMixin) {
  @Prop({ default: {} }) private word!: DiseaseWord;
  @Prop({ default: false }) private dispCode!: boolean;
  @Prop({ default: false }) private supCode!: boolean;

  private dispText(word: DiseaseWord) {
    return this.dispCode
      ? word.code
      : this.supCode
      ? `${word.name}(${word.code})`
      : word.name;
  }

  /** チップスタイル用修飾子 */
  public getChipClassModifier(word: DiseaseWord) {
    if (this.isPrefix(word)) {
      return "--prefix";
    } else if (this.isSuffix(word)) {
      return "--suffix";
    }
    return "";
  }

  /** 接頭語ワード判定 */
  private isPrefix(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 4) {
      if ("0000" <= code && code <= "7999") {
        return true;
      }
    }
    return false;
  }

  /** 接尾語ワード判定 */
  private isSuffix(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 4) {
      if ("8000" <= code && code <= "8999") {
        return true;
      }
    }
    return false;
  }

  /** 傷病名ワード判定 */
  private isDisease(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 7) {
      return true;
    }
    return false;
  }

  @Emit("click:close")
  private click() {
    return;
  }
}
