




























































































import { Component } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { BreadItem } from "@/store/modules/patient/types";
import {
  ChartDataDetail,
  TableDataDetail
} from "@/components/officeAggregate/receiptSales/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component
export default class ReceiptSalesDetail extends Base {
  private breadItems: BreadItem[] = [
    {
      text: "レセプト売上情報",
      disabled: false,
      to: "/office_aggregate/receipt_sales",
      exact: true
    },
    {
      text: "レセプト売上情報詳細",
      disabled: true,
      to: ""
    }
  ];

  private officeId = 0; //事業所ID
  private officeName = ""; //事業所名
  private yearmonth = ""; //年月
  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartDataDetail[] = []; //チャートデータ
  private tableDatas: TableDataDetail[] = []; //テーブルデータ

  //画面上部情報
  private get receiptDate() {
    const [year, month] = this.yearmonth.split("-");
    return `年月：${year}年${month}月`;
  }

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      value: "kind",
      text: "",
      width: "70px"
    },
    {
      value: "total_sales",
      text: "売上合計",
      width: "96px"
    },
    {
      value: "insurance_claim",
      text: "保険請求",
      width: "96px"
    },
    {
      value: "public_funding_request",
      text: "介護公費請求",
      width: "96px"
    },
    {
      value: "user_request_subtotal",
      text: "利用者請求（小計）",
      width: "120px"
    },
    {
      value: "user_request_insurance",
      text: "利用者請求（保険内）",
      width: "120px"
    },
    {
      value: "user_request_overinsurance",
      text: "利用者請求（保険超過）",
      width: "120px"
    },
    {
      value: "adjust_amount",
      text: "調整",
      width: "48px"
    }
  ];

  public async mounted() {
    this.officeId = Number(this.$route.query.id);
    this.officeName = String(this.$route.query.office);
    this.yearmonth = String(this.$route.query.yearmonth);

    await this.getDetail();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "kind";
    categoryAxis.renderer.grid.template.location = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Legend
    chart.legend = new am4charts.Legend();

    return chart;
  }

  // Create series
  private createSeries(field: string, name: string) {
    // Set up series
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "kind";
    series.sequencedInterpolation = true;

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText =
      "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

    // Add label
    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.hideOversized = true;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;

    this.createSeries("insurance_claim", "保険請求");
    this.createSeries("public_funding_request", "介護公費請求");
    this.createSeries("user_request_subtotal", "利用者請求（小計）");
    this.createSeries("user_request_insurance", "利用者請求（保険内）");
    this.createSeries("user_request_overinsurance", "利用者請求（保険超過）");
    this.createSeries("adjust_amount", "調整");
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  private receiptSalesPatient() {
    const url = "/office_aggregate/receipt_sales/detail/patient";
    this.$router.push(
      url +
        `?id=${this.officeId}` +
        `&office=${this.officeName}` +
        `&yearmonth=${this.yearmonth}`
    );
  }

  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url +
        "/api/officeAggregate/receiptSales/detail/downloadexcel",
      {
        table_datas: this.tableDatas,
        office_name: this.officeName,
        yearmonth: this.yearmonth
      },
      res => {
        const fileName = "receiptSalesDetail.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 詳細データ取得
  //-----------------------------------------------------
  private async getDetail() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/receiptSales/getdetail",
        {
          office_id: this.officeId,
          yearmonth: this.yearmonth
        },
        res => {
          this.chartDatas = res.data.chart_datas;
          this.tableDatas = res.data.table_datas;
          resolve(0);
        }
      );
    });
  }
}
