































































































































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { DiseaseMeta } from "#/model/patient";
import Sortable from "sortablejs";
import AppDiseaseChip from "@/components/disease/AppDiseaseChip.vue";

@Component({
  components: {
    AppDiseaseChip
  }
})
export default class DiseaseTable extends Vue {
  @Prop({ default: [] }) private value!: DiseaseMeta[];

  private sortable?: Sortable = undefined; //SortableObject
  private isSort = false;
  private prevMedicalDiseases: DiseaseMeta[] = [];
  private firstMedicalDisease: DiseaseMeta[] = [];
  /** 主傷病名テーブルヘッダ */
  private medicalDiseaseHeaders: DataTableHeader[] = [
    {
      text: "主傷病名",
      value: "name",
      sortable: false,
      width: "100%",
      align: "start",
      class: "pl-10 py-6 font-weight-bold"
    },
    // {
    //   text: "コード",
    //   value: "words",
    //   sortable: false,
    //   width: "50%",
    //   align: "start",
    //   class: "pa-0 font-weight-bold",
    // },

    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
      width: "50px",
      class: "px-0",
      cellClass: "px-0"
    }
  ];

  mounted() {
    //傷病名テーブルのDnD設定
    const table = document.querySelector(".v-data-table tbody") as HTMLElement;
    if (table) {
      this.sortable = new Sortable(table, {
        animation: 100,
        ghostClass: "ghost",
        onEnd: event => {
          if (event.oldIndex !== undefined && event.newIndex !== undefined) {
            const rowSelected = this.Value.splice(event.oldIndex, 1)[0];
            this.Value.splice(event.newIndex, 0, rowSelected);
          }
        }
      });
    }
    this.sortable?.option("disabled", true);
  }

  /** 並び替え：開始 */
  private sortStart() {
    this.isSort = true;
    this.prevMedicalDiseases = [...this.Value];
    this.sortable?.option("disabled", false);
  }

  /** 並び替え：キャンセル */
  private async sortCancel() {
    if (
      !(await this.$openConfirm("変更した内容が破棄されます。よろしいですか？"))
    ) {
      return;
    }
    this.isSort = false;
    this.Value = [...this.prevMedicalDiseases];
    this.sortable?.option("disabled", true);
  }

  /** 並び替え：完了 */
  private async sortComplete() {
    if (!(await this.$openConfirm("保存します。よろしいですか？"))) {
      return;
    }
    this.isSort = false;
    this.sortable?.option("disabled", true);
  }

  private breakPointSp() {
    return this.$vuetify.breakpoint.width < 720;
  }

  private get Value() {
    return this.value;
  }

  private set Value(value: DiseaseMeta[]) {
    this.input(value);
  }

  @Emit()
  private input(value: DiseaseMeta[]) {
    return value;
  }

  /** 主傷病名を追加（傷病名コード化） */
  @Emit("add")
  private addDisease() {
    return;
  }

  /** 主傷病名を編集（傷病名コード化） */
  @Emit("edit")
  private editDisease(meta: DiseaseMeta) {
    return meta;
  }

  /** 主傷病名を削除（傷病名コード化） */
  @Emit("delete")
  private deleteDisease(idx: number) {
    return idx;
  }
}
