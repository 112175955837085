



















































import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import { Group, Icon } from "@/components/master/group/common";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component
export default class GroupEdit extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  private groupId = 0; // グループID

  private isShown = false; // ダイアログ表示フラグ

  private group: Group = this.initGroup(); // グループ情報

  private icons: Icon[] = []; // アイコン一覧

  //初期化
  private initGroup(): Group {
    const group: Group = {
      id: 0,
      icon_id: 0,
      name: "",
      description: "",
      sort_no: 0
    };
    return group;
  }

  //ダイアログ開く（新規）
  public openNew() {
    this.groupId = 0;
    this.postJsonCheck(
      window.base_url + "/api/master/group/get",
      { group_id: this.groupId },
      res => {
        this.group = this.initGroup();
        this.icons = res.data.icons;
        this.isShown = true;
        this.$nextTick(() => {
          this.form.resetValidation();
        });
      }
    );
  }

  //ダイアログ開く（編集）
  public openEdit(groupId: number) {
    this.groupId = groupId;
    this.postJsonCheck(
      window.base_url + "/api/master/group/get",
      { group_id: this.groupId },
      res => {
        this.group = res.data.group;
        this.icons = res.data.icons;
        this.isShown = true;
        this.$nextTick(() => {
          this.form.resetValidation();
        });
      }
    );
  }

  // 保存クリック
  private async clickSave(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/group/save",
      { group: this.group },
      () => {
        this.update();
        this.isShown = false;
      }
    );
  }

  // 削除クリック
  private async clickDelete(): Promise<void> {
    if (
      !(await this.$openConfirm(
        "既に帳票等で使用している場合、削除を行うと元に戻せなくなります。それでも削除しますか？"
      ))
    )
      return;
    this.postJsonCheck(
      window.base_url + "/api/master/group/delete",
      { group: this.group },
      () => {
        this.update();
        this.isShown = false;
      }
    );
  }

  @Emit()
  private update() {
    return;
  }
}
