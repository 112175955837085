import { Component, Mixins } from "vue-property-decorator";
import { Master, DefaultMaster } from "@/components/patient/record2/types";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import * as constant from "#/const";
import {
  DefaultObservation,
  Vital,
  DefaultVital,
  CareContent
} from "#/model/report";
import { DefaultPatientLinkCondition } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  CareLargeContent,
  CareMediumContent,
  DefaultCareLargeContent
} from "#/model/master";
import VisitTmpDataMixin from "@/mixins/firestore/visitTmpDataMixin";
import JokusoMixin from "@/mixins/jokusoMixin";
import {
  VisitModelExt,
  DefaultVisitModelExt,
  VisitPatient,
  VisitOffice
} from "@/components/visit/types";

@Component
export default class BaseVisit extends Mixins(
  FireStoreMixin,
  UtilMixin,
  AxiosMixin,
  VisitTmpDataMixin,
  JokusoMixin
) {
  // 利用者ID
  protected patientId = 0;

  // モデル
  protected model: VisitModelExt = DefaultVisitModelExt();

  // マスタ
  protected master: Master = DefaultMaster();

  // 利用者
  protected patient: VisitPatient = {
    id: 0,
    agreement_id: 0,
    ins_div: 0,
    mental_div: 0,
    regular_visit_div: 0,
    info: {
      office_id: 0,
      last_name: "",
      first_name: "",
      birthday_wareki: "",
      age: 0
    }
  };

  // 事業所
  protected office: VisitOffice = {
    office_info: {
      care_is_senmon_kangoshi: 0, // 介護_専門看護師 0:なし 1:あり
      care_service_time_required: 0, // 介護_介護訪問提供時間必須フラグ 0:必須にしない 1:必須にする
      adl_setting_div: 0, // 観察項目設定区分 0:観察項目最大21項目とし、記録書Ⅱ（カルテ部分）をA4１枚に収める 1:観察項目の登録数制限を解除する（観察項目カテゴリ表示）
      medical_is_senmon_kangoshi: 0, // 医療_専門看護師 0:なし 1:あり
      medical_kinkyu_houmon_kango_add: 0 // 医療_緊急時訪問看護加算 0:加算なし 1:加算あり
    }
  };

  // リンクエリア状態
  protected linkCondition = DefaultPatientLinkCondition();

  //可変褥瘡リンク
  protected get linkJokuso() {
    return this.getLinkJokuso(this.master.bedsores, this.model.is_risk_factor);
  }

  //可変精神リンク
  protected get linkMental() {
    // 訪問日が精神指示書の期間内の場合だけ表示する
    if (!this.patient || this.patient.mental_div != 2) return [];
    let addInfo = this.model.gaf.gaf_score + "点";
    if (this.model.gaf.gaf_score < 0 || this.model.gaf.is_family_visit) {
      addInfo = "評価無し";
    }
    return [
      {
        text: "GAF尺度の評価",
        key: "gafEvaluate",
        add_info: addInfo
      }
    ];
  }

  // 加算項目
  protected get addLink() {
    const attentions: Array<string> = [];
    // 訪問時間区分
    if (this.model.time_type) {
      const time = ["", "夜間訪問看護", "早朝訪問看護", "深夜訪問看護"][
        this.model.time_type
      ];
      if (time) attentions.push(time);
    }
    if (this.model.is_long_care) {
      attentions.push("長時間訪問看護");
    }
    // 緊急訪問
    if (this.model.is_emergency_care) {
      attentions.push("緊急訪問看護");
    }
    return [
      {
        text: "加算項目",
        key: "addItem",
        attention: attentions.length > 0 ? attentions.join("/") : ""
      }
    ];
  }

  // バイタル1回目
  protected get firstVital(): Vital {
    let vi = this.model.vitals.findIndex(vital => vital.vital_count == 1);
    if (vi == -1) {
      // 1回目のバイタルがない場合追加
      const v = DefaultVital();
      v.vital_count = 1;
      this.model.vitals.push(v);
      vi = this.model.vitals.length - 1;
    }
    return this.model.vitals[vi];
  }

  // バイタル2回目
  protected get secondVital(): Vital {
    const vi = this.model.vitals.findIndex(vital => vital.vital_count == 2);
    return this.model.vitals[vi];
  }

  // バイタル2回目計測フラグ
  protected get need_second_vital() {
    // 2回目バイタルが存在する
    return this.model.vitals.findIndex(vital => vital.vital_count == 2) > -1;
  }
  protected set need_second_vital(val: boolean) {
    if (val) {
      const v = DefaultVital();
      v.vital_count = 2;
      this.model.vitals.push(v);
    } else {
      const vi = this.model.vitals.findIndex(vital => vital.vital_count == 2);
      this.model.vitals.splice(vi, 1);
    }
  }

  /** バイタル保存前処理 */
  protected onSaveVitals() {
    // 2回目だけ入力していた既存データを保存する場合、DB保存時に順番通りにするため、新規保存扱いにする
    if (this.model.vitals?.[0]?.vital_count === 2) {
      this.model.vitals.forEach(vital => (vital.id = 0));
    }
    // バイタルは1回目を先頭にする
    this.model.vitals.sort((a, b) => (a.vital_count < b.vital_count ? -1 : 1));
  }

  // 写真（訪問）
  protected get fileVisit() {
    return this.model.files.filter(file => file.file_type == 1);
  }

  // 写真（記録書２スキャン）
  protected get fileScan() {
    return this.model.files.filter(file => file.file_type == 2);
  }

  // モデル初期化
  protected initmodel() {
    // 契約ID、事業所IDが設定されていない場合利用者の事業所をセット
    if (!this.model.agreement_id) {
      this.model.agreement_id = this.patient.agreement_id;
    }
    if (!this.model.office_id) {
      this.model.office_id = this.patient.info.office_id;
    }
    if (!this.model.patient_id) {
      this.model.patient_id = this.patient.id;
    }
    this.initModelObservation();
    this.initModelCareContents();
  }

  private initModelCareContents() {
    if (!this.model.care_contents) {
      this.model.care_contents = [];
    }

    this.model.care_contents.forEach((careContent: CareContent) => {
      careContent.care_text = careContent.care_content.medium_name;
    });

    // マスタ看護内容の中項目が存在しなければ空で作っておく
    if (!this.master.nursing_details.care_large_contents) {
      this.master.nursing_details.care_large_contents = [];
    }
    /** マスタに存在する看護内容中項目ID */
    const masterMediumIds: number[] = [];
    this.master.nursing_details.care_large_contents.forEach(large => {
      if (!large.care_medium_contents) {
        large.care_medium_contents = [];
      }
      // マスタに存在する中項目ID一覧を保持
      large.care_medium_contents.forEach(medium => {
        masterMediumIds.push(medium.id);
      });
    });

    // 選択していた看護内容項目がマスタから削除された場合、画面からもチェックを外す
    this.model.care_contents = this.model.care_contents.filter(careContent => {
      return masterMediumIds.includes(careContent.care_medium_content_id);
    });
  }
  // 初期化 モデル 観察項目内容
  private initModelObservation() {
    if (this.model.observations.length == 0) {
      if (!this.master.observations.observation_categories) return;
      for (const category of this.master.observations.observation_categories) {
        if (!category.ovservation_items) continue;
        for (const item of category.ovservation_items) {
          this.model.observations.push(
            DefaultObservation(category.id, item.id)
          );
        }
      }
    } else {
      /** マスタに存在する観察項目ID */
      const masterItemIds: number[] = [];
      /** マスタに存在する観察項目選択肢ID */
      const masterSelectItemIds: number[] = [];
      const masterCats = this.master.observations.observation_categories || [];
      for (const category of masterCats) {
        for (const item of category.ovservation_items || []) {
          masterItemIds.push(item.id);
          for (const selectItem of item.selet_items || []) {
            masterSelectItemIds.push(selectItem.value);
          }
        }
      }
      // 選択していた観察項目選択肢がマスタから削除された場合、画面からも消す
      this.model.observations = this.model.observations.filter(obs => {
        if (
          !obs.observation_select_item_id &&
          !obs.observation_comment &&
          !masterItemIds.includes(obs.observation_item_id)
        ) {
          // 記録書II観察項目は未入力の項目も保存されるので、未選択でコメント空でマスタから削除済みの観察項目は一覧から消す
          return false;
        }
        return masterSelectItemIds.includes(obs.observation_select_item_id);
      });
    }
  }

  protected alertVisitMoving = "現在移動中ですので、移動中画面へ移ります。";
  protected alertVisitStaying = "現在訪問中ですので、訪問中画面へ移ります。";
  protected alertVisitNotStay =
    "移動中・訪問中ではありませんので、ホーム画面へ移ります。";

  /** Firestoreから訪問データの取得 */
  protected async getVisitFirestoreData(): Promise<VisitModelExt | undefined> {
    if (await this.getVisitTmpData(String(this.loginUser.id))) {
      return undefined;
    }

    // バイタル値を数値から文字列で扱うようになったので、訪問中データが数値のことを考慮して文字列に変換する
    const documentData = this.visitTmpDataState.data;
    if (documentData && documentData.vitals) {
      documentData.vitals = documentData.vitals.map(
        (vital: {
          breath: string;
          pulse: string;
          bp_high_right: string;
          bp_low_right: string;
          bp_high_left: string;
          bp_low_left: string;
          spo2: string;
          spo2_amount: string;
          blood_sugar: string;
          weight: string;
          abd_girth_navel: string;
          abd_girth: string;
        }) => {
          vital.breath = String(vital.breath);
          vital.pulse = String(vital.pulse);
          vital.bp_high_right = String(vital.bp_high_right);
          vital.bp_low_right = String(vital.bp_low_right);
          vital.bp_high_left = String(vital.bp_high_left);
          vital.bp_low_left = String(vital.bp_low_left);
          vital.spo2 = String(vital.spo2);
          vital.spo2_amount = String(vital.spo2_amount);
          vital.blood_sugar = String(vital.blood_sugar);
          vital.weight = String(vital.weight);
          vital.abd_girth_navel = String(vital.abd_girth_navel);
          vital.abd_girth = String(vital.abd_girth);

          return vital;
        }
      );
    }

    const model = documentData as VisitModelExt;
    if (await this.validModel(model)) {
      return undefined;
    }
    return model;
  }

  /**
   * 訪問記録モデルをチェックし、同職員の保存済みモデルがあればそれに応じて遷移する
   *
   * true:エラーあり false:正常
   * */
  protected async validModel(model: VisitModelExt | null): Promise<boolean> {
    if (!model) {
      //移動中・訪問中データが存在しない場合、ホーム画面に戻す
      await this.$openAlert(this.alertVisitNotStay);
      this.$router.replace({ name: "Home" });
      return true;
    }

    if (model.patient_id === 0) {
      //利用者0の場合、削除してから、ホーム画面に戻す
      await this.deleteVisitTmpData();
      await this.$openAlert(this.alertVisitNotStay);
      this.$router.replace({ name: "Home" });
      return true;
    }

    // URLの利用者IDと訪問情報の利用者IDが同じであれば、マスタを再取得する必要がないため、$routerのpushやreplaceを使う
    const isSoft = model.patient_id === this.patientId;

    switch (model.mode) {
      case constant.VISIT_RECORD.MODE_MOVE: //移動中
        if (
          model.patient_id !== this.patientId ||
          this.$route.name === "VisitStay"
        ) {
          // 別の利用者の移動中画面、または訪問中画面にいる場合、移動中画面に遷移する
          await this.$openAlert(this.alertVisitMoving);
          const routeTo = this.$router.resolve({
            name: "VisitMove",
            params: { id: model.patient_id + "" }
          });
          if (isSoft) {
            this.$router.replace(routeTo.route.path);
          } else {
            location.href = routeTo.route.path;
          }
          return true;
        }
        break;
      case constant.VISIT_RECORD.MODE_STAY: //訪問中
        if (
          model.patient_id !== this.patientId ||
          this.$route.name === "VisitMove"
        ) {
          // 移動中で訪問中画面にいる場合、移動中画面に遷移する
          await this.$openAlert(this.alertVisitStaying);
          const routeTo = this.$router.resolve({
            name: "VisitStay",
            params: { id: model.patient_id + "" }
          });
          if (isSoft) {
            this.$router.replace(routeTo.route.path);
          } else {
            location.href = routeTo.route.path;
          }
          return true;
        }
        break;
    }
    return false;
  }

  // 利用者リンク状態取得
  protected fetchPatientLink(resolve?: Function) {
    this.postJsonCheck(
      window.base_url + "/api/patient/linkcondition",
      { patient_id: Number(this.patientId) },
      res => {
        if (!res.data) return;
        this.linkCondition = res.data;
        resolve?.();
      }
    );
  }

  // イベント 前月報告書
  protected clickBeforeMonthReport() {
    this.makePdf(window.base_url + "/api/patient/report/preview/ids", {
      patient_id: this.patientId,
      month_report_ids: [this.linkCondition.before_month_report_id],
      month_reports: [this.linkCondition.before_month_report]
    });
  }

  // イベント 最新計画書
  protected clickLatestVnPlan() {
    this.makePdf(window.base_url + "/api/patient/vnplan/preview/ids", {
      patient_id: this.patientId,
      vn_plan_ids: [this.linkCondition.latest_vn_plant_id],
      vn_plans: [this.linkCondition.latest_vn_plant]
    });
  }

  // イベント 前3回の記録（看護）
  protected clickLast3TimesRecordNursing() {
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
      patient_id: this.patientId,
      visit_records: this.linkCondition.last3_nursing_records,
      visit_record_ids: this.linkCondition.last3_nursing_record_ids,
      order: "visit_start_datetime DESC"
    });
  }

  // イベント 全3回の記録（リハ）
  protected clickLast3TimesRecordRehab() {
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
      patient_id: this.patientId,
      visit_records: this.linkCondition.last3_rehab_records,
      visit_record_ids: this.linkCondition.last3_rehab_record_ids,
      order: "visit_start_datetime DESC"
    });
  }

  // イベント 経過観察
  protected clickProgress() {
    const resolvedRoute = this.$router.resolve({
      name: "VisitProgress",
      params: { id: String(this.patientId) }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  // イベント フェイスシート
  protected clickFaceSheet() {
    this.makePdf(window.base_url + "/api/patient/facesheet/preview", {
      patient_ids: [this.patientId]
    });
  }

  // イベント 24時間体制
  protected clickHour24() {
    // 3ヶ月以内対象
    const date = new Date();
    const endDate = `${date.getFullYear()}-${date.getMonth() +
      1}-${date.getDate()}`;
    date.setMonth(date.getMonth() - 3);
    const startDate = `${date.getFullYear()}-${date.getMonth() +
      1}-${date.getDate()}`;
    this.makePdf(window.base_url + "/api/fulltime/preview", {
      patient_ids: [this.patientId],
      start_date: startDate,
      end_date: endDate
    });
  }

  // イベント 共有ファイル
  protected clickShareFiles() {
    const resolvedRoute = this.$router.resolve({ name: "VisitShareFiles" });
    window.open(resolvedRoute.href, "_blank");
  }

  // イベント 利用者ファイル
  protected clickPatientFiles() {
    const resolvedRoute = this.$router.resolve({
      name: "VisitPatientFiles",
      params: { id: this.patientId + "" }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  // イベント 前3回のADL
  protected clickLast3TimesAdl() {
    this.makePdf(window.base_url + "/api/patient/adl/preview", {
      patient_id: this.patientId
    });
  }

  // イベント 最新記録書I
  protected clickLatestVnrecord(): void {
    this.makePdf(window.base_url + "/api/patient/vnrecords/pdf/get", {
      patient_id: this.patientId,
      pdf_path: this.linkCondition.latest_vnrecord_pdf_path
    });
  }

  // イベント 24時間体制登録
  protected clickHour24Regist() {
    const resolvedRoute = this.$router.resolve({
      name: "VisitHour24Regist",
      params: { id: String(this.patientId), fulltimeid: "0" }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  // イベント 服薬管理表
  protected clickMedicinetaking() {
    const resolvedRoute = this.$router.resolve({
      name: "VisitMedicinetaking",
      params: { id: String(this.patientId) }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  /** 看護内容の更新時 */
  protected updateCareContents(newValue: CareContent[]) {
    this.$nextTick(() => {
      this.model.care_contents = newValue.slice();
    });
  }

  /** 登録用看護内容作成 */
  protected createRegisteredCareContent() {
    // 大項目の存在チェック
    if (!this.master.nursing_details.care_large_contents) {
      this.master.nursing_details.care_large_contents = [] as CareLargeContent[];
    }

    // AI用大項目取得
    const aiContents = this.master.nursing_details.care_large_contents.filter(
      (masterContent: CareLargeContent) => {
        return masterContent.is_ai == 2;
      }
    );

    // AI用大項目が存在しなければ、デフォルト値で返す
    if (aiContents.length == 0) {
      return DefaultCareLargeContent();
    }

    const aiContent = aiContents[0];

    // AI用の中項目が1つも作られていない場合は空で返す
    if (!aiContent.care_medium_contents) {
      aiContent.care_medium_contents = [] as CareMediumContent[];
      return aiContent;
    }

    aiContent.care_medium_contents = aiContent.care_medium_contents.filter(
      (medium: CareMediumContent) => {
        let isSelected = false;
        this.model.care_contents.forEach((modelCareContent: CareContent) => {
          if (
            medium.name == modelCareContent.care_text &&
            medium.id == modelCareContent.care_medium_content_id
          ) {
            isSelected = true;
          }
        });

        // 今回新しく追加されたAI枠で選択状態にない枠は排除する
        if (!isSelected && medium.change_flag == 1) {
          return false;
        }

        return true;
      }
    );

    return aiContent;
  }
}
