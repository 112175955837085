
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import { Location } from "vue-router";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import ReportListMixin from "@/mixins/reportListMixin";
import {
  COLLECTION_REPORTLIST_COLUMN_DISPLAY,
  COLLECTION_EMERGENCY_CONTACT_LIST_LAST_OUTPUT_DATETIME
} from "@/const/envFireStore";
import { OFFICE_STAFF } from "#/const";
import * as appDate from "#/utility/appDate";
import { VForm, Choice, ChoiceAddOther, WindowPostMessage } from "@/types";
import { StatusList } from "@/components/patientsearch/search";
import { Group } from "@/components/master/group/common";
import * as Types from "@/components/report_list/types";
import {
  ReportCategory,
  ReportType,
  ValidCheckOption
} from "@/components/report_list/types";
import * as Headers from "@/components/report_list/headers";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import StaffSelect from "@/components/common_ibow/StaffSelect.vue";
import TableColumnGuide from "@/components/common_ibow/TableColumnGuide.vue";
import StateButton from "@/components/common_ibow/StateButton.vue";
import AddUpOfficeSelect from "@/components/common_ibow/AddUpOfficeSelect.vue";
import { EditMode } from "@/views/patient/record2/Record2.vue";
import { AxiosError, AxiosResponse } from "axios";

/** 緊急連絡先リスト前回出力情報 */
interface LastOutput {
  /** 出力日時 */
  output_datetime: string;
  /** 取得失敗フラグ */
  is_fetch_error: boolean;
}

@Component({
  components: {
    OfficeSelects,
    OfficeSelect,
    StaffSelect,
    TableColumnGuide,
    StateButton,
    AddUpOfficeSelect
  }
})
export default class Dashboard extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin,
  FireStoreMixin,
  ReportListMixin
) {
  @Prop({ default: 0 }) showCategoryValue!: number; // 帳票のカテゴリ 0:帳票 1:集計 2:ハイライト

  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  @Ref("reportSearchForm") private readonly form!: VForm;

  private Headers = Headers;

  private Category = {
    // 帳票
    REPORT: 0,
    // 集計
    STATISTICS: 1,
    // ハイライト
    HIGHLIGHT: 2
  };

  private ReportType = {
    // 帳票
    VNRECORD: 101,
    VNPLAN: 102,
    VNPLANMONTH: 103,
    SERVICEREPORT: 104,
    VISITRECORD: 105,
    VISITRECORDUNSUBMIT: 106,
    PROVISIONREPORT: 107, // 情報提供書
    PROVISIONEACHREPORT: 123, // 情報提供書+送付状
    MONTHREPORT: 108,
    INDICATEEXPIRED: 115,
    BEDSOREPLAN: 109,
    OCCURREPORT: 110,
    FULLTIME: 111,
    FACESHEET: 112,
    CARESUMMARY: 113,
    MEDICINETAKING: 114,
    CONFERENCEREPORT: 116,
    SERVICEOFFER: 117,
    MONTHLYEXIST: 118, // 月次書類確認リスト
    MONTHLYBATCH: 119, // 月次一括
    EMERGENCY_CONTACT_LIST: 120, // 緊急連絡先リスト
    NEWRECEPTION: 121, // 新規受付チェックリスト
    PUBLICMONEYXPIRED: 122, // 公費有効期限管理
    // 集計
    CAREENHANCED: 201, // 看護体制強化届出資料
    SERVICEPROVISION: 202, // サービス提供状況
    IMPLEMENTSUMMARY: 203, // 実施内容集計表
    IMPLEMENTREPORT: 204, // 実施報告書
    DAILYREPORT: 205, // 業務日報
    MONTHSUMMARY: 206, // 月間訪問実績集計表
    RECEIPTRESULT: 207, //レセプト実績(売上)
    BUSINESS_WORKER_REPORT: 208, // 保健師・助産師・看護師・准看護師業務従事者届
    // ハイライト
    HIGHLIGHTRECORD: 301,
    HIGHLIGHTURGENCY: 302,
    HIGHLIGHTPHOTO: 303
  };

  /** 緊急連絡先リスト前回出力日時 */
  private emergencyContactListLastOutput: LastOutput = {
    output_datetime: "-",
    is_fetch_error: false
  };

  private selectedRadioItemValue = 0;
  private get selectedRadioItem(): ReportType {
    const selected = this.reportCategories
      .map(cat => cat.types)
      .flat()
      .find(reportType => reportType.value === this.selectedRadioItemValue);
    return selected ?? { text: "", value: 0 };
  }
  private searchPanelOpened = false;
  private joinServiceOfferType = 0;

  /** 表ヘッダー関連 */
  private isColumnShowDialogOpen = false;
  private columnShowSetting: Headers.ShowColumn = {};
  private columnSelectName(header: Headers.ReportListHeader) {
    return header.selectName ?? header.text;
  }

  /** 表示設定を保存する */
  private saveShowSetting() {
    const showSetting: Headers.ShowSetting = {
      column: this.deepCopy(this.columnShowSetting),
      is_need_child_open: this.isNeedChildOpen
    };
    // 設定内容をFirestoreに保存
    this.collection = COLLECTION_REPORTLIST_COLUMN_DISPLAY;
    this.documentId = String(this.loginUser.id);
    this.documentSave(showSetting);
  }

  /** 表示列の設定が可能なヘッダー列（キー） */
  private get hidableColumns() {
    const headers = this.selectedRadioItem.headers;
    if (!headers) {
      return [];
    }

    // 「緊急連絡先リスト」のみ、実装方法を変えているので個別に対応
    // ヘッダーの定義を参照
    if (
      this.selectedRadioItemValue === this.ReportType.EMERGENCY_CONTACT_LIST
    ) {
      return headers.filter(header => header.hidable === true);
    }

    // それ以外の帳票は、あらかじめ決められたキーに対応するヘッダー列に対して設定が可能
    return headers.filter(header => {
      // 非表示設定可能な列は以下のみ
      return [
        "patient_display_id", // 利用者表示用ID
        "patient_name_kana", // 利用者名ふりがな
        "patient_birthday", // 利用者生年月日
        "patient_age", // 利用者年齢
        "office_name", // 所属ステーション名
        "submit_datetime", // 提出日時
        "address_name", // 送付状宛名
        "address_name_kana", // 送付状宛名ふりがな
        "address_name_vnplan",
        "address_name_monthreport",
        "address_name_kana_vnplan",
        "address_name_kana_monthreport",
        "indicate_type", // 指示書+送付状 指示書種類
        "indicate_start_wareki", // 指示書+送付状 指示書開始日
        "indicate_expired_wareki", // 指示書+送付状 指示書有効期限
        "doctor_inst", // 指示書+送付状 病院名
        "doctor_name", // 指示書+送付状 主治医名
        "doctor_inst_kana", // 指示書+送付状 病院名ふりがな
        "doctor_name_kana", // 指示書+送付状 主治医名ふりがな
        "per_pdf_format", // 情報提供書+送付状 PDF様式
        "per_provide_name", // 情報提供書+送付状 情報提供先機関名・氏名
        "per_provide_name_kana", // 情報提供書+送付状 情報提供先機関名・氏名ふりがな
        "per_editor_name", // 情報提供書+送付状 作成者
        "pro_report_type_name", // 情報提供書種類
        "care_personnel", // サービス提供票 居宅名/担当者名
        "care_personnel_kana", // サービス提供票 居宅名/担当者名ふりがな
        "reception_date", // 新規受付チェックリスト 受付日
        "requester_type", // 新規受付チェックリスト 依頼元
        "requester_name", // 新規受付チェックリスト 依頼元機関名/氏名
        "first_visit_date", // 新規受付チェックリスト 初回実績
        "first_visit_info_date", // 新規受付チェックリスト 初回訪問日
        "care_start_date", // 新規受付チェックリスト 介護サービス開始日
        "nr_insurance", // 新規受付チェックリスト 保険
        "nr_indicate", // 新規受付チェックリスト 指示書
        "nr_vn_record", // 新規受付チェックリスト 看護記録I
        "nr_vn_plan", // 新規受付チェックリスト 看護計画
        "nr_service_report", // 新規受付チェックリスト サービス確認
        "nr_bedsore_plan", // 新規受付チェックリスト 褥瘡対策
        "pm_insurance_div", // 公費有効期限管理 区分
        "pm_name", // 公費有効期限管理 公費種類
        "pm_start_wareki", // 公費有効期限管理 開始日
        "pm_end_wareki", // 公費有効期限管理 終了日
        "pm_insurer_no", // 公費有効期限管理 負担者番号
        "pm_insured_no" // 公費有効期限管理 受給者番号
      ].includes(header.value);
    });
  }

  private get showColumns() {
    const headers = this.selectedRadioItem.headers;
    if (!headers) {
      return [];
    }
    // 選択肢の表示/非表示を制御する
    return headers.filter(header => {
      return this.columnShowSetting[header.value] !== false;
    });
  }

  /** 帳票種類のヘッダー表示非表示を初期化 */
  private async initShowColumn() {
    // Firestoreに保存した表示列設定を優先
    this.collection = COLLECTION_REPORTLIST_COLUMN_DISPLAY;
    this.documentId = String(this.loginUser.id);
    const documentSetting = await this.documentGet();
    if (documentSetting) {
      // 画面表示設定の保存データ構造を変えたので、columnプロパティがあるかで判定
      const set = documentSetting as Headers.ShowSetting | Headers.ShowColumn;
      if ("column" in set) {
        // 新構造
        this.columnShowSetting = set.column as Headers.ShowColumn;
        this.isNeedChildOpen = set.is_need_child_open;
      } else {
        // 旧構造
        this.columnShowSetting = set;
      }
    } else if (documentSetting === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }

    // Firestoreに保存した設定がない場合のデフォルト値設定
    this.reportCategories
      .flatMap(cat => cat.types)
      .forEach(reportItem => {
        if (!reportItem.headers) return;
        reportItem.headers.forEach(header => {
          if (this.columnShowSetting[header.value] == null) {
            // 設定がない場合、全ての列を表示する
            // ただし、「緊急連絡先リスト」の場合は、あらかじめ決められた項目のみ表示
            if (reportItem.is_use_default_shown_columns === true) {
              this.columnShowSetting[header.value] =
                header.is_shown_default === true;
            } else {
              this.columnShowSetting[header.value] = true;
            }
          }
        });
      });
  }

  /** 前回出力日時取得 */
  private async fetchLastOutput() {
    // 事業所未所属の場合は、デフォルト値のまま
    if (this.loginUser.office_id === 0) {
      return;
    }

    // Firestoreからデータ取得
    this.collection = COLLECTION_EMERGENCY_CONTACT_LIST_LAST_OUTPUT_DATETIME;
    this.documentId = String(this.loginUser.office_id);
    const storedLastOutput = await this.documentGet();
    if (storedLastOutput) {
      this.emergencyContactListLastOutput = storedLastOutput as LastOutput;
      return;
    } else if (storedLastOutput === false) {
      // firestore取得失敗時は、フラグを立てる
      this.emergencyContactListLastOutput.is_fetch_error = true;
      return;
    }
  }

  /** 表関連 */
  private selectedTableItem: [] = [];
  private itemsPerPage = 25;
  private reportsPageNo = 1;
  private get tableUniqueColumn() {
    switch (this.selectedRadioItemValue) {
      case this.ReportType.PROVISIONEACHREPORT:
        return "pdf_id";
      case this.ReportType.FULLTIME:
      case this.ReportType.FACESHEET:
      case this.ReportType.MEDICINETAKING:
      case this.ReportType.MONTHLYEXIST:
      case this.ReportType.NEWRECEPTION:
        return "patient_id";
      case this.ReportType.INDICATEEXPIRED:
      case this.ReportType.MONTHLYBATCH:
        return "id_and_type";
    }
    return "id";
  }
  private changePage(newPage: number) {
    this.reportsPageNo = newPage;
  }

  /** 差し戻し関連 */
  private remandReportType = 0;
  private isRemandDialogOpen = false;
  private remandReportId = 0;
  private remandTo = "";
  private remandComment = "";

  /** サービス提供表ダイアログ関連 */
  private isServiceOffersDialogOpen = false;
  private needMaskedPdfServiceOffers = false;

  private tableHeader: Headers.ReportListHeader[] = [];

  private vnrecordItems: Types.Vnrecord[] = [];
  private vnplanItems: Types.VnPlan[] = [];
  private vnplanMonthItems: Types.VnPlanMonth[] = [];
  private serviceReportItems: Types.ServiceReport[] = [];
  private visitRecordItems: Types.VisitRecord[] = [];
  private visitRecordUnsubmitItems: Types.VisitRecordUnsubmit[] = [];
  private indicateExpiredItems: Types.IndicateExpired[] = [];
  private bedsorePlanItems: Types.BedsorePlan[] = [];
  private occurReportItems: Types.OccurReport[] = [];
  private provisionReportItems: Types.ProvisionReport[] = [];
  private provisionEachReportItems: Types.ProvisionEachReport[] = [];
  private monthReportItems: Types.MonthReport[] = [];
  private careSummaryItems: Types.CareSummary[] = [];
  private conferenceReportItems: Types.ConferenceReport[] = [];
  private serviceOfferItems: Types.ServiceOffer[] = [];
  private monthlyExistItems: Types.MonthlyExist[] = [];
  private highlightRecordItems: Types.HighlightRecord[] = [];
  private highlightUrgencyItems: Types.HighlightUrgency[] = [];
  private tableItems: Types.Table[] = [];
  private monthlyBatchItems: Types.MonthlyBatch[] = [];
  private emergencyContactItems: Types.EmergencyContact[] = [];
  private newReceptionItems: Types.NewReception[] = [];
  private publicMoneyExpiredItems: Types.PublicMoneyeExpired[] = [];

  private careEnhancedData?: Types.CareEnhanced = undefined;
  private serviceProvisionData?: Types.ServiceProvision = undefined;
  private implementSummaryData?: Types.ImplementSummary = undefined;
  private implementReportData?: Types.ImplementReport = undefined;
  private monthSummaryData?: Types.MonthSummaryReport = undefined;
  private dailyReportData?: Types.DailyReports = undefined;
  private receiptResultData?: Types.ReceiptResult = undefined;
  private businessWorkReportData: unknown[][] = [];

  // 職員一覧
  private staffs: Choice[] = [];
  // グループ一覧
  private groupItems: Group[] = [];

  private get dateByList() {
    return (displayMonthLabel: string): Choice[] => [
      { value: 0, text: displayMonthLabel },
      { value: 1, text: "記載日" },
      { value: 2, text: "提出日" }
    ];
  }

  private patientStatusList = StatusList;

  private indicateTypeList: Choice[] = [
    { value: 1, text: "普通指示" },
    { value: 2, text: "特別指示" },
    { value: 3, text: "点滴注射" }
  ];

  private provisionPdfFormatList: Choice[] = [
    { value: 1, text: "医療機関宛" },
    { value: 2, text: "学校等宛" },
    { value: 3, text: "保健所長等宛" },
    { value: 4, text: "市町村等宛" },
    { value: 5, text: "医療機関宛(口腔)" },
    { value: 6, text: "居宅介護支援事業所(口腔)" }
  ];

  private serviceOfferTypeList: Choice[] = [
    { value: 1, text: "介護＋別表" },
    { value: 2, text: "介護＋給付費" },
    { value: 3, text: "医療" }
  ];

  private careEnhancedTypeList: Choice[] = [
    { value: 1, text: "要介護（看護体制強化加算（Ⅰ））" },
    { value: 2, text: "要介護（看護体制強化加算（Ⅱ））" },
    { value: 3, text: "要支援（看護体制強化加算）" }
  ];

  private implReportOrderList: Choice[] = [
    { value: 1, text: "日付順" },
    { value: 2, text: "ID順" },
    { value: 3, text: "登録順" },
    { value: 4, text: "氏名順" }
  ];

  private monthSummaryOrderList: Choice[] = [
    { value: 1, text: "ID順" },
    { value: 2, text: "登録順" },
    { value: 3, text: "氏名順" }
  ];

  private dailyReportSortTypes: Choice[] = [
    { value: 1, text: "コメント順" },
    { value: 2, text: "職員氏名順" }
  ];

  private addressItemsVnPlan: Choice[] = [
    { value: 1, text: "主治医" },
    { value: 7, text: "かかりつけ医" },
    { value: 2, text: "ケアマネ" },
    { value: 8, text: "訪問看護ST" },
    { value: 3, text: "利用者" },
    { value: 5, text: "同意書" },
    { value: 4, text: "宛名なし" },
    { value: 99, text: "その他" }
  ];
  private addressItemsMonthReport: Choice[] = [
    { value: 1, text: "主治医" },
    { value: 7, text: "かかりつけ医" },
    { value: 2, text: "ケアマネ" },
    { value: 8, text: "訪問看護ST" },
    { value: 3, text: "利用者" },
    { value: 4, text: "宛名なし" },
    { value: 99, text: "その他" }
  ];
  private addressItemsVnPlanMonth = this.addressItemsMonthReport;
  /** 送付状の対象宛先 */
  private letterAddresses = [1, 7, 2, 8, 99];

  private patientId = 0;
  private officeIds: number[] = [];

  /** 今日/今月 */
  private today = new Date();
  /** 去年同日の次月初 */
  private justLastYearDate = new Date(
    this.today.getFullYear() - 1,
    this.today.getMonth() + 1,
    1
  );
  /** 90日前 */
  private before90Date = new Date(
    new Date(this.today).setDate(this.today.getDate() - 89)
  );
  /** 先月1日/先月 */
  private lastMonthFirstDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth() - 1,
    1
  );
  /** 先月末 */
  private lastMonthLastDate = new Date(new Date(this.today).setDate(0));
  /** 今月1日 */
  private monthFirstDate = new Date(new Date(this.today).setDate(1));
  /** 今月末 */
  private monthLastDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth() + 1,
    0
  );
  /** 翌月 */
  private nextMonth = new Date(
    this.today.getFullYear(),
    this.today.getMonth() + 1,
    1
  );

  /** デフォルト検索条件 */
  private DefaultSearchCond = (): Types.SearchCondition => ({
    startMonthCurrent: this.dateToStr(this.today, "yyyy-MM"),
    endMonthCurrent: this.dateToStr(this.today, "yyyy-MM"),
    viewMonthLast: this.dateToStr(this.lastMonthFirstDate, "yyyy-MM"),
    viewMonthCurrent: this.dateToStr(this.today, "yyyy-MM"),
    viewMonthNext: this.dateToStr(this.nextMonth, "yyyy-MM"),
    viewMonthVnPlan: this.dateToStr(this.today, "yyyy-MM"),
    viewMonthMonthReport: this.dateToStr(this.lastMonthFirstDate, "yyyy-MM"),
    viewMonthServiceProvision: this.getThisSeptember(),
    startDateJustLastYear: this.dateToStr(this.justLastYearDate, "yyyy-MM-dd"),
    startDateBefore90: this.dateToStr(this.before90Date, "yyyy-MM-dd"),
    startDateLastMonthFirst: this.dateToStr(
      this.lastMonthFirstDate,
      "yyyy-MM-dd"
    ),
    startDateMonthFirst: this.dateToStr(this.monthFirstDate, "yyyy-MM-dd"),
    endDatelastMonthLast: this.dateToStr(this.lastMonthLastDate, "yyyy-MM-dd"),
    endDateCurrent: this.dateToStr(this.today, "yyyy-MM-dd"),
    endDateMonthLast: this.dateToStr(this.monthLastDate, "yyyy-MM-dd"),
    viewDateCurrent: this.dateToStr(this.today, "yyyy-MM-dd"),
    keyword: "",
    officeIds: this.officeIds,
    officeId: this.officeIds[0] ?? 0,
    staffId: 0,
    selectedGroupItems: new Array(5).fill(0),
    selectedGroupOperator: 1,
    selectedDateBy: 0,
    selectedServiceOfferType: 2,
    selectedCareEnhancedType: 1,
    selectedAddressVnPlan: 1,
    selectedAddressMonthReport: 1,
    selectedAddressVnPlanMonth: 1,
    isExcludeIndicateHaveFuture: false,
    isIncludeIndicateNotArrive: false,
    selectedIndicateTypes: [1],
    isExcludePubMoneyHaveFuture: false,
    selectedProvisionPdfFormats: [1, 2, 3, 4, 5, 6],
    selectedPatientStatus: 1,
    hasInsurance: false,
    isIncludeAll: true,
    isIncludeSatelite: true,
    checkTargetDate: true,
    dailyReportSortType: 1,
    isMedicineIncludeStop: true,
    willCountUrgency: "0",
    willCountSpecial: "0",
    willCountNewUser: "0",
    viewMonthLastYear: this.dateToStr(this.justLastYearDate, "yyyy-MM")
  });
  /** 検索条件 */
  private searchCond: Types.SearchCondition = this.DefaultSearchCond();
  /** 検索後帳票出力用に期間を保持 */
  private searchCondSave: Types.SearchCondition = this.DefaultSearchCond();
  /** デフォルト検索条件：事業所 */
  private get DefaultOffices(): number[] | undefined {
    if (this.loginUser.office_id !== 0) {
      // 所属している場合はログインユーザーの所属事業所
      return [this.loginUser.office_id];
    } else if (this.HeadOffice !== 0) {
      // 所属していない場合は先頭の事業所
      return [this.HeadOffice];
    }
    return undefined;
  }
  // 今年度の9月を取得する
  private getThisSeptember(): string {
    const t = new Date();
    const y = t.getMonth() + 1 < 4 ? t.getFullYear() - 1 : t.getFullYear();
    return y.toString() + "-09";
  }

  /** グループ検索条件のAND/ORとチェックボックスを合わせる */
  private get searchGroupOperator(): boolean {
    return !this.searchCond.selectedGroupOperator;
  }
  private set searchGroupOperator(newCond: boolean) {
    this.searchCond.selectedGroupOperator = Number(!newCond);
  }

  /** 絞り込みしているかどうか */
  private get IsFiltered(): boolean {
    //デフォルト検索条件
    const defaultCond = this.DefaultSearchCond();
    // タブ判定
    const selectTab = this.selectedRadioItemValue / 100;

    // 帳票
    if (selectTab < 2) {
      // 全帳票共通絞り込み項目
      if (
        (!this.patientId && // 利用者情報のとき以外
          JSON.stringify(this.searchCond.officeIds) !==
            JSON.stringify(this.DefaultOffices)) ||
        this.searchCond.keyword ||
        this.searchCond.selectedGroupItems.find(
          id => id !== 0 && id !== null
        ) ||
        this.searchCond.selectedGroupOperator === 0
      ) {
        // 上記で絞り込みがある場合
        return true;
      }
      switch (this.selectedRadioItemValue) {
        case this.ReportType.VNPLAN: // 看護計画書＋送付状
          return !(
            !this.searchCond.selectedDateBy &&
            this.searchCond.startMonthCurrent ===
              defaultCond.startMonthCurrent &&
            this.searchCond.endMonthCurrent === defaultCond.endMonthCurrent &&
            this.searchCond.isIncludeAll
          );
        case this.ReportType.VNRECORD: // 看護記録Ⅰ
          return !(
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            this.searchCond.startDateJustLastYear ===
              defaultCond.startDateJustLastYear
          );
        case this.ReportType.VNPLANMONTH: // 看護計画書＋月次報告書＋サービス提供票＋送付状
          return !(
            this.searchCond.viewMonthVnPlan === defaultCond.viewMonthVnPlan &&
            this.searchCond.viewMonthMonthReport ===
              defaultCond.viewMonthMonthReport
          );
        case this.ReportType.SERVICEREPORT: // サービス内容確認書
          return !(
            this.searchCond.startDateLastMonthFirst ===
              defaultCond.startDateLastMonthFirst &&
            this.searchCond.endDatelastMonthLast ===
              defaultCond.endDatelastMonthLast
          );
        case this.ReportType.VISITRECORD: // 看護記録書Ⅱ
          return !(
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            !this.searchCond.hasInsurance
          );
        case this.ReportType.VISITRECORDUNSUBMIT: // 看護記録書Ⅱ(未提出)
          return !(
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast
          );
        case this.ReportType.PROVISIONREPORT: // 情報提供書
          return !(this.searchCond.viewMonthLast === defaultCond.viewMonthLast);
        case this.ReportType.PROVISIONEACHREPORT: // 情報提供書＋送付状
          return !(
            this.searchCond.viewMonthLast === defaultCond.viewMonthLast &&
            JSON.stringify(this.searchCond.selectedProvisionPdfFormats) ===
              JSON.stringify(defaultCond.selectedProvisionPdfFormats)
          );
        case this.ReportType.MONTHREPORT: // 月次報告書＋送付状
          return !(
            this.searchCond.viewMonthLast === defaultCond.viewMonthLast &&
            this.searchCond.isIncludeAll
          );

        case this.ReportType.INDICATEEXPIRED: // 指示書＋送付状
          return !(
            this.searchCond.viewDateCurrent === defaultCond.viewDateCurrent &&
            JSON.stringify(this.searchCond.selectedIndicateTypes) ===
              JSON.stringify(defaultCond.selectedIndicateTypes) &&
            this.searchCond.selectedPatientStatus ===
              defaultCond.selectedPatientStatus &&
            this.searchCond.isExcludeIndicateHaveFuture ===
              defaultCond.isExcludeIndicateHaveFuture &&
            this.searchCond.isIncludeIndicateNotArrive ===
              defaultCond.isIncludeIndicateNotArrive
          );
        case this.ReportType.BEDSOREPLAN: // 褥瘡対策看護計画書
          return !(
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast
          );
        case this.ReportType.OCCURREPORT: // 事故ヒヤリハット報告書
          return !(
            this.searchCond.startDateBefore90 ===
              defaultCond.startDateBefore90 &&
            this.searchCond.endDateCurrent === defaultCond.endDateCurrent
          );
        case this.ReportType.FULLTIME: // 24時間体制
          return !(
            this.searchCond.startDateBefore90 ===
              defaultCond.startDateBefore90 &&
            this.searchCond.endDateCurrent === defaultCond.endDateCurrent
          );
        case this.ReportType.FACESHEET: // フェイスシート
          return false; // フェイスシート独自の絞り込みはない
        case this.ReportType.CARESUMMARY: //看護サマリー
          return !(
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast
          );
        case this.ReportType.MEDICINETAKING: // 服薬管理表
          return !(
            this.searchCond.startMonthCurrent ===
              defaultCond.startMonthCurrent &&
            this.searchCond.endMonthCurrent === defaultCond.endMonthCurrent &&
            this.searchCond.isMedicineIncludeStop
          );
        case this.ReportType.CONFERENCEREPORT: // 会議録
          return !(
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast
          );
        case this.ReportType.SERVICEOFFER: // サービス提供票
          return !(
            this.searchCond.selectedServiceOfferType ===
              defaultCond.selectedServiceOfferType &&
            this.searchCond.viewMonthCurrent === defaultCond.viewMonthCurrent
          );
        case this.ReportType.MONTHLYEXIST: // 月次書類確認リスト
          return !(
            this.searchCond.viewMonthCurrent === defaultCond.viewMonthCurrent &&
            this.searchCond.selectedPatientStatus ===
              defaultCond.selectedPatientStatus
          );
        case this.ReportType.MONTHLYBATCH:
          return !(
            this.searchCond.endMonthCurrent === defaultCond.endMonthCurrent &&
            this.searchCond.viewMonthLastYear === defaultCond.viewMonthLastYear
          );
        // 緊急連絡先リスト
        case this.ReportType.EMERGENCY_CONTACT_LIST:
          return !(
            this.searchCond.selectedPatientStatus ===
            defaultCond.selectedPatientStatus
          );
        case this.ReportType.NEWRECEPTION: // 新規受付チェックリスト
          return !(
            this.searchCond.viewMonthCurrent === defaultCond.viewMonthCurrent
          );
        case this.ReportType.PUBLICMONEYXPIRED: // 公費有効期限管理
          return !(
            this.searchCond.viewDateCurrent === defaultCond.viewDateCurrent &&
            this.searchCond.selectedPatientStatus ===
              defaultCond.selectedPatientStatus &&
            this.searchCond.isExcludePubMoneyHaveFuture ===
              defaultCond.isExcludePubMoneyHaveFuture
          );
      }
    }

    // 集計
    else if (selectTab < 3) {
      switch (this.selectedRadioItemValue) {
        case this.ReportType.CAREENHANCED: // 看護体制強化届出資料
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            this.searchCond.selectedCareEnhancedType ===
              defaultCond.selectedCareEnhancedType &&
            this.searchCond.willCountSpecial === defaultCond.willCountSpecial &&
            this.searchCond.willCountNewUser === defaultCond.willCountNewUser &&
            this.searchCond.willCountUrgency === defaultCond.willCountUrgency &&
            this.searchCond.viewMonthNext === defaultCond.viewMonthNext
          );
        case this.ReportType.SERVICEPROVISION: // サービス提供状況
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            this.searchCond.viewMonthServiceProvision ===
              defaultCond.viewMonthServiceProvision
          );
        case this.ReportType.IMPLEMENTSUMMARY: // 実施内容集計表
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            this.searchCond.viewMonthLast === defaultCond.viewMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
        case this.ReportType.IMPLEMENTREPORT: // 実施報告書
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            this.searchCond.staffId === defaultCond.staffId &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
        case this.ReportType.MONTHSUMMARY: // 月間訪問実績集計表
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.viewMonthLast === defaultCond.viewMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
        case this.ReportType.DAILYREPORT: // 業務日報
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            this.searchCond.dailyReportSortType ===
              defaultCond.dailyReportSortType &&
            this.searchCond.checkTargetDate
          );
        case this.ReportType.RECEIPTRESULT: // レセプト実績(売上)
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.viewMonthLast === defaultCond.viewMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
        //
        case this.ReportType.BUSINESS_WORKER_REPORT:
          return false;
      }
    }

    // ハイライト
    else {
      switch (
        this.selectedRadioItemValue // 看護記録ハイライト
      ) {
        case this.ReportType.HIGHLIGHTRECORD:
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
        case this.ReportType.HIGHLIGHTURGENCY: // 緊急対応ハイライト
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.startDateBefore90 ===
              defaultCond.startDateBefore90 &&
            this.searchCond.endDateCurrent === defaultCond.endDateCurrent &&
            this.searchCond.selectedGroupOperator === 1
          );
        case this.ReportType.HIGHLIGHTPHOTO: // 記録写真ハイライト
          return !(
            JSON.stringify(this.searchCond.officeIds) ===
              JSON.stringify(this.DefaultOffices) &&
            !this.searchCond.keyword &&
            !this.searchCond.selectedGroupItems.find(
              id => id !== 0 && id !== null
            ) &&
            this.searchCond.startDateMonthFirst ===
              defaultCond.startDateMonthFirst &&
            this.searchCond.endDateMonthLast === defaultCond.endDateMonthLast &&
            this.searchCond.selectedGroupOperator === 1
          );
      }
    }

    return true;
  }

  // 別タブ関連
  /** 編集用別タブ */
  private childEditWindow: Window | null = null;
  /** 別の画面で開くボタンを表示するか */
  private get IsShowChildToggle() {
    if (this.IsiOS || this.showCategoryValue !== this.Category.REPORT) {
      return false;
    }
    if (this.selectedRadioItem.value === this.ReportType.VISITRECORD) {
      // 編集して差戻しボタンは差戻し可能な権限のみ表示する
      return this.IsRemandable;
    } else if (this.selectedRadioItem.value === this.ReportType.MONTHLYEXIST) {
      return true;
    }
    return false;
  }
  /** 利用者の帳票一覧/レセプトなどを別タブで開くか */
  private isNeedChildOpen = false;
  private toggleChildOpen() {
    this.isNeedChildOpen = !this.isNeedChildOpen;
    this.saveShowSetting();
  }
  private get IsNeedChildOpen() {
    return this.isNeedChildOpen && !this.IsiOS;
  }
  /** 通常の画面と別タブ用画面の切り替え */
  private pageForChild(routeName: string) {
    if (!this.IsNeedChildOpen) {
      // 別タブ用ではなく通常
      return routeName;
    }
    // 別タブ用の画面に替える
    switch (routeName) {
      case "PatientRecord2Edit":
        return "PatientRecord2EditFocus";
      case "PatientRecieptDetail":
        return "PatientRecieptDetailFocus";
      case "PatientPlanList":
        return "PatientPlanListFocus";
      case "PatientReportList":
        return "PatientReportListFocus";
      case "PatientProvisionList":
        return "PatientProvisionListFocus";
      default:
        return routeName;
    }
  }

  /** 帳票カテゴリ及び種類 */
  private basicReportItems: ReportType[] = [
    {
      text: "看護計画書＋送付状",
      shortName: "看護計画書",
      value: this.ReportType.VNPLAN,
      headers: Headers.vnplan,
      items: this.vnplanItems,
      linkTo: "PatientPlanList",
      fetchFunc: this.fetchVnPlans,
      previewFunc: this.previewVnPlansPdf
    },
    {
      text: "月次報告書＋送付状",
      shortName: "月次報告書",
      value: this.ReportType.MONTHREPORT,
      headers: Headers.monthReport,
      items: this.monthReportItems,
      linkTo: "PatientReportList",
      fetchFunc: this.fetchMonthReports,
      previewFunc: this.previewMonthReportsPdf
    },
    {
      text: "看護計画書＋月次報告書＋サービス提供票＋送付状",
      shortName: "看護計画書＋月次報告書",
      value: this.ReportType.VNPLANMONTH,
      headers: Headers.vnplanMonth,
      items: this.vnplanMonthItems,
      linkTo: "PatientPlanList",
      fetchFunc: this.fetchVnPlanMonths,
      previewFunc: this.previewVnPlanMonthsPdf
    },
    {
      text: "指示書＋送付状",
      value: this.ReportType.INDICATEEXPIRED,
      headers: Headers.indicateExpired,
      items: this.indicateExpiredItems,
      linkTo: "PatientIndicate",
      fetchFunc: this.fetchIndicateExpireds,
      previewFunc: this.previewVnPlansPdf
    },
    {
      text: "看護記録書Ⅱ",
      value: this.ReportType.VISITRECORD,
      headers: Headers.visitRecord,
      items: this.visitRecordItems,
      linkTo: "PatientRecord2List",
      fetchFunc: this.fetchVisitRecords,
      previewFunc: this.previewVisitRecordsPdf
    },
    {
      text: "看護記録書Ⅱ(未提出)",
      value: this.ReportType.VISITRECORDUNSUBMIT,
      headers: Headers.visitRecordUnsubmit,
      items: this.visitRecordUnsubmitItems,
      linkTo: "PatientRecord2List",
      fetchFunc: this.fetchVisitRecordUnsubmits,
      previewFunc: this.previewVisitRecordUnsubmitsPdf
    },
    {
      text: "看護記録Ⅰ",
      value: this.ReportType.VNRECORD,
      headers: Headers.vnrecord,
      items: this.vnrecordItems,
      linkTo: "PatientVnrecordList",
      fetchFunc: this.fetchVnrecords,
      previewFunc: this.previewVnrecordsPdf
    },
    {
      text: "情報提供書",
      value: this.ReportType.PROVISIONREPORT,
      headers: Headers.provisionReport,
      items: this.provisionReportItems,
      linkTo: "PatientProvisionList",
      fetchFunc: this.fetchProvisionReports,
      previewFunc: this.previewProvisionReportsPdf
    },
    {
      text: "情報提供書＋送付状",
      value: this.ReportType.PROVISIONEACHREPORT,
      headers: Headers.provisionEachReport,
      items: this.provisionEachReportItems,
      linkTo: "PatientProvisionList",
      fetchFunc: this.fetchProvisionEachReports,
      previewFunc: this.previewProvisionEachReportsPdf
    },
    {
      text: "褥瘡対策看護計画書",
      value: this.ReportType.BEDSOREPLAN,
      headers: Headers.bedsorePlan,
      items: this.bedsorePlanItems,
      linkTo: "PatientBedsoreList",
      fetchFunc: this.fetchBedsorePlans,
      previewFunc: this.previewBedsorePlansPdf
    },
    {
      text: "事故/ヒヤリハット報告書",
      value: this.ReportType.OCCURREPORT,
      headers: Headers.occurReport,
      items: this.occurReportItems,
      linkTo: "PatientOccurList",
      fetchFunc: this.fetchOccurReports,
      previewFunc: this.previewOccurReportsPdf
    },
    {
      text: "24時間体制",
      value: this.ReportType.FULLTIME,
      headers: Headers.patient,
      items: this.tableItems,
      linkTo: "PatientHour24List",
      fetchFunc: this.fetchFulltimePatients,
      previewFunc: this.previewFulltimesPdf
    },
    {
      text: "フェイスシート",
      value: this.ReportType.FACESHEET,
      headers: Headers.patient,
      items: this.tableItems,
      linkTo: "PatientInfo",
      fetchFunc: this.fetchFacesheetPatients,
      previewFunc: this.previewFaceSheetsPdf
    },
    {
      text: "看護サマリー",
      value: this.ReportType.CARESUMMARY,
      headers: Headers.careSummary,
      items: this.careSummaryItems,
      linkTo: "PatientCareSummaryList",
      fetchFunc: this.fetchCareSummaries,
      previewFunc: this.previewCareSummarysPdf
    },
    {
      text: "服薬管理表",
      value: this.ReportType.MEDICINETAKING,
      headers: Headers.patient,
      items: this.tableItems,
      linkTo: "PatientMedicineTaking",
      fetchFunc: this.fetchMedicineTakingPatients,
      previewFunc: this.previewMedicineTakingsPdf
    },
    {
      text: "会議録",
      value: this.ReportType.CONFERENCEREPORT,
      headers: Headers.conferenceReport,
      items: this.conferenceReportItems,
      linkTo: "PatientConferenceList",
      fetchFunc: this.fetchConferenceReports,
      previewFunc: this.previewConferenceReportsPdf
    },
    {
      text: "サービス提供票",
      value: this.ReportType.SERVICEOFFER,
      headers: Headers.serviceOffer,
      items: this.serviceOfferItems,
      linkTo: "PatientRecieptDetail",
      fetchFunc: this.fetchServiceOffers,
      previewFunc: this.previewServiceOffersPdf
    },
    {
      text: "サービス内容確認書",
      value: this.ReportType.SERVICEREPORT,
      headers: Headers.serviceReport,
      items: this.serviceReportItems,
      linkTo: "PatientServiceList",
      fetchFunc: this.fetchServiceReports,
      previewFunc: this.previewServiceReportsPdf
    },
    {
      text: "月次書類確認リスト",
      value: this.ReportType.MONTHLYEXIST,
      headers: Headers.monthlyExist,
      items: this.monthlyExistItems,
      fetchFunc: this.fetchMonthlyExist
    },
    {
      text: "月次一括",
      value: this.ReportType.MONTHLYBATCH,
      headers: Headers.monthlyBatch,
      items: this.monthlyBatchItems,
      fetchFunc: this.fetchMonthlyBatch,
      previewFunc: this.previewMonthlyBatch
    },
    {
      text: "緊急連絡先リスト",
      value: this.ReportType.EMERGENCY_CONTACT_LIST,
      headers: Headers.emergencyContactList,
      items: this.emergencyContactItems,
      fetchFunc: this.fetchEmergencyContactList,
      is_use_default_shown_columns: true
    },
    {
      text: "新規受付チェックリスト",
      value: this.ReportType.NEWRECEPTION,
      headers: Headers.newReception,
      items: this.newReceptionItems,
      fetchFunc: this.fetchNewReception
    },
    {
      text: "公費有効期限管理",
      value: this.ReportType.PUBLICMONEYXPIRED,
      headers: Headers.publicMoneyExpired,
      linkTo: "PatientInsuranceInfo",
      items: this.publicMoneyExpiredItems,
      fetchFunc: this.fetchPublicMoneyExpireds
    }
  ];
  private highLightItems: ReportType[] = [
    {
      text: "看護記録ハイライト",
      value: this.ReportType.HIGHLIGHTRECORD,
      headers: Headers.highlightRecord,
      items: this.highlightRecordItems,
      linkTo: "PatientRecord2List",
      fetchFunc: this.fetchHighlightRecords,
      previewFunc: this.previewHighlightRecordPdf
    },
    {
      text: "緊急対応ハイライト",
      value: this.ReportType.HIGHLIGHTURGENCY,
      headers: Headers.highlightUrgency,
      items: this.highlightUrgencyItems,
      linkTo: "PatientHour24List",
      fetchFunc: this.fetchHighlightUrgencies,
      previewFunc: this.previewHighlightUrgencyPdf
    },
    {
      text: "記録写真ハイライト",
      value: this.ReportType.HIGHLIGHTPHOTO,
      headers: Headers.visitRecord,
      items: this.visitRecordItems,
      linkTo: "PatientRecord2List",
      fetchFunc: this.fetchHighlightPhotos,
      previewFunc: this.previewVisitRecordsPdf
    }
  ];

  /** 帳票カテゴリ */
  public get reportCategories(): ReportCategory[] {
    return [
      {
        value: this.Category.REPORT,
        text: "帳票",
        types: this.basicReportItems
      },
      {
        value: this.Category.STATISTICS,
        text: "集計",
        types: this.StatisticsItems
      },
      {
        value: this.Category.HIGHLIGHT,
        text: "ハイライト",
        types: this.highLightItems
      }
    ];
  }

  /** 帳票カテゴリのタブ切り替え */
  private get showCategory(): ReportCategory | undefined {
    return this.reportCategories.find(
      cat => cat.value === this.showCategoryValue
    );
  }

  /** 業務日報 日付必須ラベル名 */
  private get dailyReportDateLabel(): string {
    return this.searchCond.checkTargetDate ? "終了日" : "終了日(必須)";
  }

  private tablePagerOption = {
    "items-per-page-text": "1ページあたりの件数:",
    "page-text": "{0} - {1} 件 / 計 {2} 件",
    "items-per-page-all-text": "全て"
  };

  /** 帳票一覧テーブルのボタン属性共通部分 */
  private reportButtonAttrs = {
    color: "grey",
    outlined: true
  };

  /** 検索対象事業所に所属する職員一覧に差し替え */
  @Watch("searchCond.officeIds")
  private onChangeCondOfficeIds(officeIds: number[]) {
    this.fetchStaffs(officeIds).then(staffs => {
      this.staffs = staffs;
      // 職員一覧が更新されたので、選択職員をリセットする
      this.searchCond.staffId = 0;
    });
  }

  /** (サービス提供状況)事業所変更時にチェック状態をリセット */
  @Watch("searchCond.officeId")
  private resetIncludeSatelite(after: number, before: number) {
    const beforeOffice = this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) => officeChoice.value === before
    );
    /** 直前に選択していたのはサテライトか */
    const isBeforeSatelite = beforeOffice && before !== beforeOffice.other;
    /** 現在選択しているのはサテライトか */
    const isNowSatelite =
      this.SelectedOffice && after !== this.SelectedOffice.other;
    // 何か→未選択時はOFF
    // 何か→サテライト選択時はOFF
    // 未選択→事業所変更時はデフォルトのON
    // サテライト→事業所変更時はデフォルトのON
    // 事業所→事業所変更時はチェック状態変更なし
    if (!this.SelectedOffice?.other || isNowSatelite) {
      this.searchCond.isIncludeSatelite = false;
    } else if (!before || isBeforeSatelite) {
      this.searchCond.isIncludeSatelite = !this.IsDisabledIncludeSatelite;
    }
  }

  created(): void {
    this.selectedRadioItemValue = this.$route.query.report
      ? Number(this.$route.query.report as string)
      : 0;
    this.patientId = Number(this.$route.params.id) || 0;

    // 書類確認ページでなければデフォルトの検索事業所を設定
    if (
      this.patientId === 0 &&
      this.searchCond.officeIds.length === 0 &&
      this.DefaultOffices
    ) {
      this.searchCond.officeIds = this.DefaultOffices;
      this.searchCond.officeId = this.DefaultOffices[0];
    }

    // 前回出力日時取得
    this.fetchLastOutput();

    this.initShowColumn();
    this.fetchStaffs(this.searchCond.officeIds).then(staffs => {
      this.staffs = staffs;
    });
    this.fetchGroups();
  }

  /** 集計アイテム */
  private get StatisticsItems(): ReportType[] {
    let items: ReportType[] = [];

    if (this.loginUserAuthId != this.STAFF_COMMON) {
      items = [
        {
          text: "看護体制強化届出資料",
          value: this.ReportType.CAREENHANCED,
          fetchFunc: this.fetchCareEnhanced,
          previewFunc: this.previewCareEnhancedPdf
        },
        {
          text: "サービス提供状況",
          value: this.ReportType.SERVICEPROVISION,
          fetchFunc: this.fetchServiceProvision,
          previewFunc: this.previewServiceProvisionPdf
        },
        {
          text: "実施内容集計表",
          value: this.ReportType.IMPLEMENTSUMMARY,
          fetchFunc: this.fetchImplementSummary,
          previewFunc: this.previewImplementSummaryPdf
        },
        {
          text: "実施報告書",
          value: this.ReportType.IMPLEMENTREPORT,
          fetchFunc: this.fetchImplementReport,
          previewFunc: this.previewImplementReportPdf
        },
        {
          text: "月間訪問実績集計表",
          value: this.ReportType.MONTHSUMMARY,
          fetchFunc: this.fetchMonthSummary,
          previewFunc: this.previewMonthSummaryPdf
        },
        {
          text: "レセプト実績(売上)",
          value: this.ReportType.RECEIPTRESULT,
          fetchFunc: this.fetchReceiptResult,
          previewFunc: this.downloadReceiptResult
        },

        {
          text: "保健師・助産師・看護師・准看護師業務従事者届",
          value: this.ReportType.BUSINESS_WORKER_REPORT,
          fetchFunc: this.fetchBusinessWorkReport,
          previewFunc: this.downloadBusinessWorkReport
        }
      ];
    }

    items.push({
      text: "業務日報",
      value: this.ReportType.DAILYREPORT,
      fetchFunc: this.fetchDailyReport,
      previewFunc: this.previewDailyReportPdf
    });

    return items;
  }

  /** 差戻し可能な権限か */
  private get IsRemandable() {
    return this.loginUserAuthId > OFFICE_STAFF.AUTH.COMMON;
  }

  /** 検索条件で選択している事業所 */
  private get SelectedOffice(): ChoiceAddOther | undefined {
    return this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) =>
        officeChoice.value === this.searchCond.officeId
    );
  }

  /** グループリスト取得 */
  private fetchGroups(): void {
    this.postJsonCheck(window.base_url + "/api/master/groups/get", {}, res => {
      this.groupItems = res.data.groups;
    });
  }

  /** 帳票の選択を全解除 */
  private reportUnselectAll(): void {
    this.selectedTableItem.splice(0);
  }

  /** 集計対象の事業所 */
  private get TargetOffices(): ChoiceAddOther[] {
    const selected = this.SelectedOffice;
    if (!selected) {
      return [];
    }
    if (!this.searchCond.isIncludeSatelite) {
      return [selected];
    }

    // 合算にチェックが入っているなら、選択した事業所のサテライトも含める
    // 先頭が選んだ事業所、続けてサテライト
    const offices: Map<number, ChoiceAddOther> = new Map();
    offices.set(+selected.value, selected);
    this.masters.group_offices
      .filter(choice => choice.other === selected.value)
      .forEach(choice => offices.set(+choice.value, choice));

    return [...offices.values()];
  }

  /** (サービス提供状況)サテライトを検索条件に含める選択を無効化 */
  private get IsDisabledIncludeSatelite(): boolean {
    // 未選択、サテライトを選択している場合は無効化
    if (this.searchCond.officeId === 0) {
      return true;
    }

    const selectedOffice = this.SelectedOffice;
    if (!selectedOffice) {
      return true;
    }

    return selectedOffice.value !== selectedOffice.other;
  }

  /** 検索条件バリデーション：事業所選択 */
  private checkOfficesSelect(): boolean | string {
    //1つも選択していない場合
    if (!this.searchCond.officeIds || this.searchCond.officeIds.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!this.searchCond.officeIds || this.searchCond.officeIds.length > 5) {
      return "最大5事業所まで選択可能です";
    }
    return true;
  }

  /** 検索条件バリデーション：月跨ぎ */
  private checkOverMonth(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr.replace(/-/g, "/"));
    const endDate = new Date(endDateStr.replace(/-/g, "/"));

    if (
      startDate.getMonth() != endDate.getMonth() &&
      !this.searchCond.checkTargetDate
    ) {
      return "開始日と終了日は同月を指定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr.replace(/-/g, "/"));
    const endDate = new Date(endDateStr.replace(/-/g, "/"));

    // 開始日が入力されていない場合
    if (!startDateStr) {
      return "開始日を入力すると検索できます";
    }
    // 有効な開始日として解釈できない場合
    if (Number.isNaN(startDate.getDate())) {
      return "開始日の入力形式に誤りがあります";
    }
    // 開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr.replace(/-/g, "/"));
    const endDate = new Date(endDateStr.replace(/-/g, "/"));

    // 終了日が入力されていない場合
    if (!endDateStr) {
      return "終了日を入力すると検索できます";
    }
    // 有効な終了日として解釈できない場合
    if (Number.isNaN(endDate.getDate())) {
      return "終了日の入力形式に誤りがあります";
    }
    // 終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：検索対象日 */
  private checkTargetDate(targetDateStr: string): boolean | string {
    const targetDate = new Date(targetDateStr.replace(/-/g, "/"));

    // 年月が入力されていない場合
    if (!targetDateStr) {
      return "表示日を入力すると検索できます";
    }
    // 有効な年月として解釈できない場合
    if (Number.isNaN(targetDate.getDate())) {
      return "表示日の入力形式に誤りがあります";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始月 */
  private checkStartMonth(
    startMonthStr: string,
    endMonthStr: string
  ): boolean | string {
    const startMonth = appDate.strToDateObj(startMonthStr);
    const endMonth = appDate.strToDateObj(endMonthStr);
    // 開始月が入力されていない場合
    if (!startMonthStr) {
      return "開始月を入力すると検索できます";
    }
    // 有効な開始月として解釈できない場合
    if (Number.isNaN(startMonth.getDate())) {
      return "開始月の入力形式に誤りがあります";
    }
    // 開始月が終了月よりも後である場合
    if (startMonth > endMonth) {
      return "開始月は終了月よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了月 */
  private checkEndMonth(
    startMonthStr: string,
    endMonthStr: string
  ): boolean | string {
    const startMonth = appDate.strToDateObj(startMonthStr);
    const endMonth = appDate.strToDateObj(endMonthStr);

    // 終了月が入力されていない場合
    if (!endMonthStr) {
      return "終了月を入力すると検索できます";
    }
    // 有効な終了月として解釈できない場合
    if (Number.isNaN(endMonth.getDate())) {
      return "終了月の入力形式に誤りがあります";
    }
    // 終了月が開始月よりも前である場合
    if (startMonth > endMonth) {
      return "終了月は開始月よりも後に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：検索対象月 */
  private checkTargetMonth(targetMonthStr: string): boolean | string {
    const targetMonth = appDate.strToDateObj(targetMonthStr);
    // 年月が入力されていない場合
    if (!targetMonthStr) {
      return "年月を入力すると検索できます";
    }
    // 有効な年月として解釈できない場合
    if (Number.isNaN(targetMonth.getDate())) {
      return "年月の入力形式に誤りがあります";
    }
    return true;
  }

  /** 検索条件バリデーション：看護体制強化届出資料 */
  private checkSearchCareEnhanced(
    targetMonthStr: string,
    targetValuesStr: string[]
  ): boolean | string {
    const targetMonth = appDate.strToDateObj(targetMonthStr);
    const maxValue = Math.max(...targetValuesStr.map(v => +v || 0));

    if (maxValue > 0 && targetMonth < this.today) {
      return "過去分の算定では予定者数を入力できません。";
    }
    return true;
  }

  /** 検索キーワードの入力文字列を検索条件に */
  private updateSearchKeyword(newKeyword: string) {
    this.searchCond.keyword = newKeyword;
  }

  /** 検索条件をデフォルトに戻す */
  private resetSearchCond() {
    const defaultCond = this.DefaultSearchCond();
    if (this.DefaultOffices) {
      defaultCond.officeIds = this.DefaultOffices;
      defaultCond.officeId = this.DefaultOffices[0];
    }
    this.searchCond = defaultCond;
  }

  /** 検索パネルを開く */
  private openSearchPanel() {
    this.searchPanelOpened = true;
  }

  /** 検索条件の検証後に取得処理を実行し、検索できない時は知らせる */
  private checkSearchValid(option?: ValidCheckOption): void {
    this.$nextTick(async () => {
      if (!this.form.validate()) {
        if (option?.isSkipOffice && this.searchCond.officeIds.length == 0) {
          this.form.resetValidation();
        } else if (option?.alert) {
          await this.$openAlert("入力内容に不備があります");
        }
        // 検索条件に不備があれば、パネルを開く
        this.openSearchPanel();
        return;
      }
      this.reportUnselectAll();
      this.fetchReportList();
    });
  }

  /** 利用者名のクリックでどの画面に遷移するか */
  private get patientLinkTo() {
    /** 提出済みタブへ遷移するか */
    const isSubmit = [
      this.ReportType.VNPLAN,
      this.ReportType.MONTHREPORT,
      this.ReportType.VNPLANMONTH,
      this.ReportType.VISITRECORD,
      this.ReportType.VNRECORD,
      this.ReportType.PROVISIONREPORT,
      this.ReportType.BEDSOREPLAN,
      this.ReportType.OCCURREPORT,
      this.ReportType.CARESUMMARY,
      this.ReportType.CONFERENCEREPORT,
      this.ReportType.SERVICEREPORT,
      this.ReportType.HIGHLIGHTRECORD,
      this.ReportType.HIGHLIGHTPHOTO
    ].includes(Number(this.selectedRadioItem.value));

    let routeName = this.selectedRadioItem.linkTo ?? "PatientCalendar";
    if (!this.IsRemandable && routeName === "PatientRecieptDetail") {
      // 一般職員で見られない画面の場合、代わりに利用者カレンダーへ遷移
      routeName = "PatientCalendar";
    }

    return (item: { patient_id: number }) => {
      const addQuery: { [key: string]: string } = {};
      if (isSubmit) addQuery.reporttab = "1";

      return this.redirectPatientTo(routeName, item.patient_id, addQuery);
    };
  }

  /** 遷移先利用者画面 */
  private redirectPatientTo(
    routeName: string,
    patientId: number,
    addQuery: { [key: string]: string } = {}
  ): Location {
    if (
      routeName === "PatientRecieptDetail" ||
      routeName === "PatientRecieptDetailFocus"
    ) {
      // 利用者レセプト画面は?id=形式
      return { name: routeName, query: { id: patientId + "", ...addQuery } };
    } else {
      return {
        name: routeName,
        params: { id: patientId + "" },
        query: addQuery
      };
    }
  }

  /** 利用者画面へ遷移する */
  private redirectPatient(
    routeName: string,
    patientId: number,
    addQuery: { [key: string]: string } = {}
  ): void {
    if (!routeName || !patientId) {
      return; // ルーティング名と利用者IDは必須
    }
    routeName = this.pageForChild(routeName);
    const newRoute = this.redirectPatientTo(routeName, patientId, addQuery);
    const resolved = this.$router.resolve(newRoute);
    this.open(resolved.href);
  }

  /** URLを開く */
  private open(url: string) {
    if (this.IsNeedChildOpen) {
      this.openChildWindow(url);
    } else {
      this.$router.push(url);
    }
  }

  /** 別タブでURLを開く */
  private openChildWindow(url: string) {
    if (this.childEditWindow && !this.childEditWindow.closed) {
      // 既に同じURLを別タブで開いていれば、そのタブをアクティブにする
      // 既に別のURLを別タブで開いていれば、そのURLを変える(その時編集中であれば、先に確認アラートを表示する)
      const msgData: WindowPostMessage = { movePath: url };
      this.childEditWindow.postMessage(msgData, location.origin);
      this.childEditWindow.focus();
    } else {
      this.childEditWindow = window.open(url, "reportfocus");
    }
  }

  /** 帳票PDF表示ボタンで選べる並び順のオプション */
  private get outputOptions(): Choice[] {
    switch (this.selectedRadioItem.value) {
      case this.ReportType.IMPLEMENTREPORT:
        return this.implReportOrderList;
      case this.ReportType.MONTHSUMMARY:
        return this.monthSummaryOrderList;
    }

    return [];
  }

  /** 帳票一覧取得の振り分け */
  private async fetchReportList(): Promise<void> {
    if (this.selectedRadioItem.fetchFunc != null) {
      this.selectedRadioItem.fetchFunc();
    } else {
      await this.$openAlert("種類を選択してください");
    }
  }

  /** 帳票PDF表示の振り分け */
  private async previewReportsPdf(select: number | null): Promise<void> {
    // 並び順が設定されていれば、対応する帳票データに格納
    if (this.implementReportData && select) {
      this.implementReportData.order = select;
    }
    if (this.monthSummaryData && select) {
      this.monthSummaryData.sort_type = select;
    }

    if (this.selectedRadioItem.previewFunc != null) {
      this.selectedRadioItem.previewFunc();
    } else {
      await this.$openAlert("種類を選択してください");
    }
  }

  /** 画像のみ表示 */
  private async previewImageFile(): Promise<void> {
    const visitRecordIds = this.selectedTableItem.map(
      (selectTableRow: Types.VisitRecordUnsubmit) => selectTableRow.id
    );

    if (!visitRecordIds.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(
      window.base_heavy_url + "/api/reportlist/highlight/image/pdf",
      {
        visit_record_ids: visitRecordIds
      }
    );
  }

  /** サービス提供票を連結してPDF出力 */
  private previewReportsWithServiceOffer(offerType: number): void {
    this.joinServiceOfferType = offerType; // 0なら添付されない
    this.previewReportsPdf(null);
    this.joinServiceOfferType = 0; // リセット
  }

  /** 帳票差戻しコメントを記入するポップアップの表示 */
  private showRemandDialog(
    reportId: number,
    remandTo: string,
    optionalReportType?: number
  ): void {
    // VNPLAMMONTHのように複数の帳票を1つに結びつけているものは、引数で帳票種類を指定する
    this.remandReportType = optionalReportType || +this.selectedRadioItem.value;
    this.remandReportId = reportId;
    this.remandTo = remandTo;
    this.isRemandDialogOpen = true;
  }

  /** 編集して差戻し画面へリダイレクトする */
  private async redirectRemandEdit(report: Types.VisitRecord): Promise<void> {
    if (
      (report.insurance_div == 1 && report.is_history_medical) ||
      (report.insurance_div == 2 && report.is_history_care)
    ) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください。"
      );
      return;
    } else if (
      ((report.insurance_div == 1 && report.is_decision_medical) ||
        (report.insurance_div == 2 && report.is_decision_care)) &&
      !(await this.$openConfirm(
        "実績確定されています。差戻しを行うと実績確定が解除されますが記録書を差戻しますか？"
      ))
    ) {
      return;
    }

    const routeRemand = this.$router.resolve({
      name: this.pageForChild("PatientRecord2Edit"),
      params: { id: String(report.patient_id), record2_id: String(report.id) },
      query: { mode: EditMode.Remand }
    });
    this.open(routeRemand.href);
  }

  /** 帳票差戻し処理の振り分け */
  private async remandReportSwitch(): Promise<void> {
    // 一般職員は差戻し不可
    if (!this.IsRemandable) {
      await this.$openAlert("差戻し権限がありません。");
      return;
    }

    let apiUrl = "";
    switch (this.remandReportType) {
      case this.ReportType.VNRECORD:
        apiUrl = window.base_url + "/api/patient/vnrecord/remand";
        break;
      case this.ReportType.VNPLAN:
        apiUrl = window.base_url + "/api/patient/vnplan/remand";
        break;
      case this.ReportType.SERVICEREPORT:
        apiUrl = window.base_url + "/api/patient/servicereport/remand";
        break;
      case this.ReportType.VISITRECORD:
        apiUrl = window.base_url + "/api/patient/visitrecord/remand";
        break;
      case this.ReportType.PROVISIONREPORT:
      case this.ReportType.PROVISIONEACHREPORT:
        apiUrl = window.base_url + "/api/patient/provisionreport/remand";
        break;
      case this.ReportType.MONTHREPORT:
        apiUrl = window.base_url + "/api/patient/report/remand";
        break;
      case this.ReportType.BEDSOREPLAN:
        apiUrl = window.base_url + "/api/patient/bedsoreplan/remand";
        break;
      case this.ReportType.OCCURREPORT:
        apiUrl = window.base_url + "/api/patient/occurreport/remand";
        break;
      case this.ReportType.CARESUMMARY:
        apiUrl = window.base_url + "/api/patient/caresummary/remand";
        break;
      case this.ReportType.CONFERENCEREPORT:
        apiUrl = window.base_url + "/api/patient/conferencereport/remand";
        break;
      default:
        await this.$openAlert("差戻し可能な帳票種類を選んでください。");
        return;
    }
    this.remandReport(apiUrl, false);
  }

  /** 帳票出力用のデータがあるか */
  private isExistStat = false;
  private get isExistData(): boolean {
    switch (this.selectedRadioItem.value) {
      case this.ReportType.CAREENHANCED:
      case this.ReportType.SERVICEPROVISION:
      case this.ReportType.IMPLEMENTSUMMARY:
      case this.ReportType.IMPLEMENTREPORT:
      case this.ReportType.MONTHSUMMARY:
      case this.ReportType.DAILYREPORT:
      case this.ReportType.BUSINESS_WORKER_REPORT:
      case this.ReportType.RECEIPTRESULT:
        return this.isExistStat;
      default:
        return this.selectedTableItem.length !== 0;
    }
  }

  /** 看護記録書Ⅰ一覧取得 */
  private fetchVnrecords(): void {
    this.vnrecordItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/vnrecord/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateJustLastYear,
        end_date: this.searchCond.endDateMonthLast
      },
      res => {
        if (res.data.vnrecords == null) return;
        this.vnrecordItems.push(...res.data.vnrecords);
        this.tableHeader = Headers.vnrecord;
      }
    );
  }

  /** 看護計画書一覧取得 */
  private async fetchVnPlans(): Promise<void> {
    this.vnplanItems.splice(0);
    this.searchCondSave.selectedAddressVnPlan = this.searchCond.selectedAddressVnPlan;
    let startDate = "";
    let endDate = "";
    switch (this.searchCond.selectedDateBy) {
      case 0:
        startDate = this.searchCond.startMonthCurrent;
        endDate = this.searchCond.endMonthCurrent;
        break;
      case 1:
      case 2:
        startDate = this.searchCond.startDateMonthFirst;
        endDate = this.searchCond.endDateMonthLast;
        break;
      default:
        await this.$openAlert(
          "検索する日付の基準を計画月／記載日／提出日で指定してください。"
        );
        return;
    }

    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/vnplan/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        address: this.searchCondSave.selectedAddressVnPlan,
        start_date: startDate,
        end_date: endDate,
        date_by: this.searchCond.selectedDateBy,
        include_all: this.searchCond.isIncludeAll
      },
      res => {
        if (res.data.vnplans == null) return;
        this.vnplanItems.push(...res.data.vnplans);
        this.tableHeader = Headers.vnplan;
      }
    );
  }

  /** 看護計画書+月次報告書一覧取得 */
  private fetchVnPlanMonths(): void {
    this.vnplanMonthItems.splice(0);
    this.searchCondSave.selectedAddressVnPlanMonth = this.searchCond.selectedAddressVnPlanMonth;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/vnplanmonth/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        address: this.searchCondSave.selectedAddressVnPlanMonth,
        yearmonth_vnplan: this.searchCond.viewMonthVnPlan,
        yearmonth_monthreport: this.searchCond.viewMonthMonthReport
      },
      res => {
        if (res.data.vnplan_months == null) return;
        this.vnplanMonthItems.push(...res.data.vnplan_months);
        this.tableHeader = Headers.vnplanMonth;
      }
    );
  }

  /** サービス内容確認書一覧取得 */
  private fetchServiceReports(): void {
    this.serviceReportItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/servicereport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateLastMonthFirst,
        end_date: this.searchCond.endDatelastMonthLast
      },
      res => {
        if (res.data.service_reports == null) return;
        this.serviceReportItems.push(...res.data.service_reports);
        this.tableHeader = Headers.serviceReport;
      }
    );
  }

  /** 看護記録書II一覧取得 */
  private fetchVisitRecords(): void {
    this.visitRecordItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/visitrecord/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        only_submit: true,
        has_insurance: this.searchCond.hasInsurance
      },
      res => {
        if (res.data.visit_records == null) return;
        this.visitRecordItems.push(...res.data.visit_records);

        // ほのぼの連携かどうかで表示するヘッダーを変える
        // ほのぼの連携時は実績連携情報を表示する
        const isHonobono = res.data.is_honobono
          ? (res.data.is_honobono as boolean)
          : false;

        this.tableHeader = Headers.visitRecord;

        this.selectedRadioItem.headers = isHonobono
          ? Headers.visitRecordHonobono
          : Headers.visitRecord;
      }
    );
  }

  /** 看護記録書II(未提出)一覧取得 */
  private fetchVisitRecordUnsubmits(): void {
    this.visitRecordUnsubmitItems.splice(
      0,
      this.visitRecordUnsubmitItems.length
    );
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/visitrecord/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        only_submit: false
      },
      res => {
        if (res.data.visit_records == null) return;
        this.visitRecordUnsubmitItems.push(...res.data.visit_records);
        this.tableHeader = Headers.visitRecordUnsubmit;
      }
    );
  }

  /** 情報提供書一覧取得 */
  private fetchProvisionReports(): void {
    this.provisionReportItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/provisionreport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        view_month: this.searchCond.viewMonthLast
      },
      res => {
        if (res.data.provision_report == null) return;
        this.provisionReportItems.push(...res.data.provision_report);
        this.tableHeader = Headers.provisionReport;
      }
    );
  }

  /** 情報提供書＋送付状一覧取得 */
  private fetchProvisionEachReports(): void {
    this.provisionEachReportItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url +
        "/api/reportlist/provisioneachreport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        view_month: this.searchCond.viewMonthLast,
        pdf_formats: this.searchCond.selectedProvisionPdfFormats
      },
      res => {
        if (res.data.provision_report == null) return;
        this.provisionEachReportItems.push(...res.data.provision_report);
        this.tableHeader = Headers.provisionEachReport;
      }
    );
  }

  /** 月次報告書一覧取得 */
  private fetchMonthReports(): void {
    this.monthReportItems.splice(0);
    this.searchCondSave.selectedAddressMonthReport = this.searchCond.selectedAddressMonthReport;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/monthreport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        address: this.searchCond.selectedAddressMonthReport,
        start_yearmonth: this.searchCond.viewMonthLast,
        end_yearmonth: this.searchCond.viewMonthLast,
        include_all: this.searchCond.isIncludeAll
      },
      res => {
        if (res.data.month_reports == null) return;
        this.monthReportItems.push(...res.data.month_reports);
        this.tableHeader = Headers.monthReport;
      }
    );
  }

  /** 有効期限切れ指示書一覧取得 */
  private fetchIndicateExpireds(): void {
    this.indicateExpiredItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/indicateexpired/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        patient_status: this.searchCond.selectedPatientStatus,
        target_date: this.searchCond.viewDateCurrent,
        indicate_types: this.searchCond.selectedIndicateTypes,
        is_exclude_have_future: this.searchCond.isExcludeIndicateHaveFuture,
        is_include_not_arrive: this.searchCond.isIncludeIndicateNotArrive
      },
      res => {
        if (res.data.indicate_expireds == null) return;
        this.indicateExpiredItems.push(...res.data.indicate_expireds);
        this.tableHeader = Headers.indicateExpired;
      }
    );
  }

  /** 公費有効期限管理取得 */
  private fetchPublicMoneyExpireds(): void {
    this.publicMoneyExpiredItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/publicmoneyexpired/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        patient_status: this.searchCond.selectedPatientStatus,
        target_date: this.searchCond.viewDateCurrent,
        is_exclude_have_future: this.searchCond.isExcludePubMoneyHaveFuture
      },
      res => {
        if (res.data.public_money_expireds == null) return;
        this.publicMoneyExpiredItems.push(...res.data.public_money_expireds);
        this.tableHeader = Headers.publicMoneyExpired;
      }
    );
  }

  /** 褥瘡対策看護計画書一覧取得 */
  private fetchBedsorePlans(): void {
    this.bedsorePlanItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/bedsoreplan/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast
      },
      res => {
        if (res.data.bedsore_plans == null) return;
        this.bedsorePlanItems.push(...res.data.bedsore_plans);
        this.tableHeader = Headers.bedsorePlan;
      }
    );
  }

  /** 事故/ヒヤリハット報告書一覧取得 */
  private fetchOccurReports(): void {
    this.occurReportItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/occurreport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateBefore90,
        end_date: this.searchCond.endDateCurrent
      },
      res => {
        if (res.data.occur_reports == null) return;
        this.occurReportItems.push(...res.data.occur_reports);
        this.tableHeader = Headers.occurReport;
      }
    );
  }

  /** 24時間体制を記録している利用者一覧を取得 */
  private fetchFulltimePatients(): void {
    this.tableItems.splice(0);
    this.searchCondSave.startDateBefore90 = this.searchCond.startDateBefore90;
    this.searchCondSave.endDateCurrent = this.searchCond.endDateCurrent;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/fulltime/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCondSave.startDateBefore90,
        end_date: this.searchCondSave.endDateCurrent
      },
      res => {
        if (res.data.patient_data == null) return;
        this.tableItems.push(...res.data.patient_data);
        this.tableHeader = Headers.patient;
      }
    );
  }

  /** フェイスシート利用者一覧を取得 */
  private fetchFacesheetPatients(): void {
    this.tableItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/facesheet/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        }
      },
      res => {
        if (res.data.patient_data == null) return;
        this.tableItems.push(...res.data.patient_data);
        this.tableHeader = Headers.patient;
      }
    );
  }

  /** 看護サマリ一覧取得 */
  private fetchCareSummaries(): void {
    this.careSummaryItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/caresummary/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast
      },
      res => {
        if (res.data.care_summaries == null) return;
        this.careSummaryItems.push(...res.data.care_summaries);
        this.tableHeader = Headers.careSummary;
      }
    );
  }

  /** 服薬管理表利用者一覧を取得 */
  private fetchMedicineTakingPatients(): void {
    this.tableItems.splice(0);
    this.searchCondSave.startMonthCurrent = this.searchCond.startMonthCurrent;
    this.searchCondSave.endMonthCurrent = this.searchCond.endMonthCurrent;
    this.searchCondSave.isMedicineIncludeStop = this.searchCond.isMedicineIncludeStop;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/medicinetaking/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_month: this.searchCondSave.startMonthCurrent,
        end_month: this.searchCondSave.endMonthCurrent,
        need_stop: this.searchCondSave.isMedicineIncludeStop
      },
      res => {
        if (res.data.patient_data == null) return;
        this.tableItems.push(...res.data.patient_data);
        this.tableHeader = Headers.patient;
      }
    );
  }

  /** 会議録一覧取得 */
  private fetchConferenceReports(): void {
    this.conferenceReportItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/conferencereport/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast
      },
      res => {
        if (res.data.conference_reports == null) return;
        this.conferenceReportItems.push(...res.data.conference_reports);
        this.tableHeader = Headers.conferenceReport;
      }
    );
  }

  /** サービス提供票一覧取得 */
  private fetchServiceOffers(): void {
    this.serviceOfferItems.splice(0);
    this.searchCondSave.selectedServiceOfferType = this.searchCond.selectedServiceOfferType;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/serviceoffer/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        yearmonth: this.searchCond.viewMonthCurrent,
        offer_type: this.searchCondSave.selectedServiceOfferType
      },
      res => {
        if (res.data.service_offers == null) return;
        this.serviceOfferItems.push(...res.data.service_offers);
        this.tableHeader = Headers.serviceOffer;
      }
    );
  }

  /** 月次書類確認リスト情報取得 */
  private fetchMonthlyExist(): void {
    this.monthlyExistItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/monthlyexist/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        patient_status: this.searchCond.selectedPatientStatus,
        target_month: this.searchCond.viewMonthCurrent
      },
      res => {
        if (res.data.monthly_exist == null) return;
        this.monthlyExistItems.push(...res.data.monthly_exist);
        this.tableHeader = Headers.monthlyExist;
      }
    );
  }

  /** 月次一括リスト情報取得 */
  private fetchMonthlyBatch(): void {
    this.monthlyBatchItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/monthlybatch/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_month: this.searchCond.viewMonthLastYear,
        end_month: this.searchCond.endMonthCurrent
      },
      res => {
        if (res.data.monthly_batches == null) return;
        this.monthlyBatchItems.push(...res.data.monthly_batches);
        this.tableHeader = Headers.monthlyBatch;
      }
    );
  }

  /** 緊急連絡先リスト情報取得 */
  private fetchEmergencyContactList(): void {
    // 初期化
    this.emergencyContactItems.splice(0);

    // 取得
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/emergency/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        patient_status: this.searchCond.selectedPatientStatus
      },
      res => {
        if (res.data.emergency_contact_list) {
          this.emergencyContactItems.push(...res.data.emergency_contact_list);
          this.tableHeader = Headers.emergencyContactList;
        }
      }
    );
  }

  /** 新規受付チェックリスト情報取得 */
  private fetchNewReception(): void {
    this.newReceptionItems.splice(0);
    let targetMonth = this.searchCond.viewMonthCurrent;
    if (this.patientId !== 0) {
      // 利用者帳票ではその利用者だけを調べるので、いつの登録でも一発検索できるように
      targetMonth = "";
    }

    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/newreception/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        target_month: targetMonth
      },
      res => {
        if (res.data.new_receptions == null) return;
        this.newReceptionItems.push(...res.data.new_receptions);
        this.tableHeader = Headers.newReception;
      }
    );
  }

  /** 看護体制強化届出資料取得 */
  private fetchCareEnhanced(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/careenhanced/get",
      {
        care_type: this.searchCond.selectedCareEnhancedType,
        office_ids: this.searchCond.officeIds,
        target_month: this.searchCond.viewMonthNext,
        will_count_urgency: +this.searchCond.willCountUrgency,
        will_count_special: +this.searchCond.willCountSpecial,
        will_count_new_user: +this.searchCond.willCountNewUser
      },
      res => {
        if (res.data.care_enhanced == null) return;
        this.careEnhancedData = res.data.care_enhanced;
        this.isExistStat = this.careEnhancedData != null;
      }
    );
  }

  /** サービス提供状況取得 */
  private fetchServiceProvision(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/serviceprovision/get",
      {
        office_ids: this.TargetOffices.map(office => office.value),
        target_month: this.searchCond.viewMonthServiceProvision
      },
      res => {
        if (res.data.service_provision == null) return;
        this.serviceProvisionData = res.data.service_provision;
        this.isExistStat = this.serviceProvisionData != null;
      }
    );
  }

  /** 実施内容集計表取得 */
  private fetchImplementSummary(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/implsummary/get",
      {
        target_month: this.searchCond.viewMonthLast,
        office_ids: this.searchCond.officeIds,
        group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
        group_operator: this.searchCond.selectedGroupOperator,
        keyword: this.searchCond.keyword
      },
      res => {
        if (res.data.impl_summary == null) return;
        this.implementSummaryData = res.data.impl_summary;
        this.isExistStat = this.implementSummaryData != null;
      }
    );
  }

  /** 実施報告書取得 */
  private fetchImplementReport(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/implreport/get",
      {
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        office_ids: this.searchCond.officeIds,
        staff_id: this.searchCond.staffId,
        group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
        group_operator: this.searchCond.selectedGroupOperator,
        keyword: this.searchCond.keyword
      },
      res => {
        if (res.data.impl_report == null) return;
        this.implementReportData = res.data.impl_report;
        this.isExistStat = this.implementReportData != null;
      }
    );
  }

  /** 月間訪問実績集計表 */
  private fetchMonthSummary(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/monthsummary/get",
      {
        target_yearmonth: this.searchCond.viewMonthLast,
        office_ids: this.searchCond.officeIds,
        group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
        group_operator: this.searchCond.selectedGroupOperator,
        keyword: this.searchCond.keyword
      },
      res => {
        if (res.data.month_summary_report == null) return;
        this.monthSummaryData = res.data.month_summary_report;
        this.isExistStat =
          this.monthSummaryData?.month_summary != null &&
          this.monthSummaryData?.month_summary.length > 0;

        if (this.monthSummaryData) {
          this.monthSummaryData.target_yearmonth = this.searchCond.viewMonthLast;
        }
      }
    );
  }

  /** 業務日報情報取得 */
  private fetchDailyReport(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/dailyreport/get",
      {
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        office_ids: this.searchCond.officeIds,
        date_specification: this.searchCond.checkTargetDate,
        sort_type: this.searchCond.dailyReportSortType
      },
      res => {
        if (res.data.reports == null) return;
        this.dailyReportData = res.data;
        this.isExistStat = this.dailyReportData != null;
      }
    );
  }

  /** 看護記録ハイライト取得 */
  private fetchHighlightRecords(): void {
    this.highlightRecordItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/visitrecord/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        only_submit: true,
        is_change_condition: true
      },
      res => {
        if (res.data.visit_records == null) return;
        this.highlightRecordItems.push(...res.data.visit_records);
        this.tableHeader = Headers.highlightRecord;
      }
    );
  }

  /** 緊急対応ハイライト取得 */
  private fetchHighlightUrgencies(): void {
    this.highlightUrgencyItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/fulltime/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateBefore90,
        end_date: this.searchCond.endDateCurrent,
        only_urgency: true
      },
      res => {
        if (res.data.fulltime_data == null) return;
        this.highlightUrgencyItems.push(...res.data.fulltime_data);
        this.tableHeader = Headers.highlightUrgency;
      }
    );
  }

  /** 記録写真ハイライト取得 */
  private fetchHighlightPhotos(): void {
    this.visitRecordItems.splice(0);
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/visitrecord/patients/get",
      {
        part: {
          patient_id: this.patientId,
          keyword: this.searchCond.keyword,
          office_ids: this.searchCond.officeIds,
          group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
          group_operator: this.searchCond.selectedGroupOperator
        },
        start_date: this.searchCond.startDateMonthFirst,
        end_date: this.searchCond.endDateMonthLast,
        only_submit: true,
        has_photo: true
      },
      res => {
        if (res.data.visit_records == null) return;
        this.visitRecordItems.push(...res.data.visit_records);
        this.tableHeader = Headers.visitRecord;
      }
    );
  }

  /** 保健師・助産師・看護師・准看護師業務従事者届情報取得 */
  private fetchBusinessWorkReport(): void {
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/businessworkreports/get",
      {
        office_ids: this.searchCond.officeIds
      },
      res => {
        if (res.data.business_work_reports == null) return;
        this.businessWorkReportData = res.data.business_work_reports;
      }
    );
  }

  /** 帳票PDF連結表示 */
  private async previewJoinedPdf(
    pdfPaths: string[],
    isMakeZip = 0,
    isMaskedPdf = false,
    successFunc: (res: AxiosResponse) => void = () => {
      return;
    },
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    },
    isPreview = true
  ): Promise<void> {
    if (!pdfPaths.length) {
      await this.$openAlert("選択されていません");
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/pdf/join",
      {
        str_pdfs: pdfPaths,
        is_make_zip: isMakeZip,
        is_masked_pdf: isMaskedPdf
      },
      successFunc,
      catchFunc,
      isPreview
    );
  }

  /** 看護記録書IIPDF連結生成と表示 */
  private async previewJoinedVisitRecordUnsubmitPdf(visitRecordIds: number[]) {
    if (!visitRecordIds.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(window.base_heavy_url + "/api/patient/visitrecords/generate", {
      visit_record_ids: visitRecordIds
    });
  }

  /** 24時間体制記録書PDF連結表示 */
  private async previewJoinedFulltimePdf(patientIds: number[]) {
    if (!patientIds.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(window.base_heavy_url + "/api/fulltime/preview", {
      patient_ids: patientIds,
      start_date: this.searchCondSave.startDateBefore90,
      end_date: this.searchCondSave.endDateCurrent
    });
  }

  /** フェイスシートPDF連結表示 */
  private async previewJoinedFacesheetPdf(patientIds: number[]) {
    if (!patientIds.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(window.base_heavy_url + "/api/patient/facesheet/preview", {
      patient_ids: patientIds
    });
  }

  /** 服薬管理表PDF連結表示 */
  private async previewJoinedMedicineTakingPdf(patientIds: number[]) {
    if (!patientIds.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(
      window.base_heavy_url + "/api/patient/medicinetaking/preview",
      {
        patient_ids: patientIds,
        start_month: this.searchCondSave.startMonthCurrent,
        end_month: this.searchCondSave.endMonthCurrent,
        include_stop: this.searchCondSave.isMedicineIncludeStop
      }
    );
  }

  /** 看護記録書ⅠPDF表示 */
  private previewVnrecordsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.Vnrecord) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 看護計画書PDF表示 */
  private previewVnPlansPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.VnPlan) => selectTableRow.pdf_paths
    );
    this.previewJoinedPdf(pdfPaths.flat());
  }

  /** 看護計画書+月次報告書PDF表示 */
  private previewVnPlanMonthsPdf(): void {
    const pdfPaths = this.selectedTableItem.flatMap(
      (selectTableRow: Types.VnPlanMonth) => {
        const eachPaths = [
          selectTableRow.pdf_paths_monthreport,
          selectTableRow.pdf_paths_vnplan
        ].flat();
        // ケアマネ宛に限り、サービス提供票もあれば連結
        switch (this.joinServiceOfferType) {
          case 1:
            if (selectTableRow.pdf_path_offer_beppyo) {
              eachPaths.push(selectTableRow.pdf_path_offer_beppyo);
            }
            break;
          case 2:
            if (selectTableRow.pdf_path_offer_care) {
              eachPaths.push(selectTableRow.pdf_path_offer_care);
            }
            break;
        }
        return eachPaths;
      }
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** サービス内容確認書PDF表示 */
  private previewServiceReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.ServiceReport) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 看護記録書IIPDF表示 */
  private previewVisitRecordsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.VisitRecord) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths, 1);
  }

  /** 看護記録書II(未提出)PDF表示 */
  private previewVisitRecordUnsubmitsPdf(): void {
    const visitRecordIds = this.selectedTableItem.map(
      (selectTableRow: Types.VisitRecordUnsubmit) => selectTableRow.id
    );
    this.previewJoinedVisitRecordUnsubmitPdf(visitRecordIds);
  }

  /** 情報提供書PDF表示 */
  private previewProvisionReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.ProvisionReport) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 情報提供書＋送付状PDF表示 */
  private previewProvisionEachReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.ProvisionEachReport) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 月次報告書PDF表示 */
  private previewMonthReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.MonthReport) => selectTableRow.pdf_paths
    );
    this.previewJoinedPdf(pdfPaths.flat());
  }

  /** 褥瘡対策看護計画書PDF表示 */
  private previewBedsorePlansPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.BedsorePlan) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 事故/ヒヤリハット報告書PDF表示 */
  private previewOccurReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.OccurReport) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 24時間体制記録書PDF表示 */
  private previewFulltimesPdf(): void {
    const patientIds = this.selectedTableItem.map(
      (selectTableRow: Types.Table) => selectTableRow.patient_id
    );
    this.previewJoinedFulltimePdf(patientIds);
  }

  /** フェイスシートPDF表示 */
  private previewFaceSheetsPdf(): void {
    const patientIds = this.selectedTableItem.map(
      (selectTableRow: Types.Table) => selectTableRow.patient_id
    );
    this.previewJoinedFacesheetPdf(patientIds);
  }

  /** 看護サマリPDF表示 */
  private previewCareSummarysPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.CareSummary) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 服薬管理表PDF表示 */
  private previewMedicineTakingsPdf(): void {
    const patientIds = this.selectedTableItem.map(
      (selectTableRow: Types.Table) => selectTableRow.patient_id
    );
    this.previewJoinedMedicineTakingPdf(patientIds);
  }

  /** 会議録PDF表示 */
  private previewConferenceReportsPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.ConferenceReport) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** サービス提供票PDF表示 */
  private async previewServiceOffersPdf(): Promise<void> {
    this.isServiceOffersDialogOpen = true;
    this.needMaskedPdfServiceOffers = false;
  }

  private hideServiceOffersDialog() {
    this.isServiceOffersDialogOpen = false;
  }

  private async execServiceOffersPdf(): Promise<void> {
    let pdfPaths: string[] = [];
    switch (this.searchCondSave.selectedServiceOfferType) {
      case 1: // 別表
        pdfPaths = this.selectedTableItem.map(
          (selectTableRow: Types.ServiceOffer) => selectTableRow.pdf_path_beppyo
        );
        break;
      case 2: // 給付費
        pdfPaths = this.selectedTableItem.map(
          (selectTableRow: Types.ServiceOffer) => selectTableRow.pdf_path_care
        );
        break;
      case 3: // 医療
        pdfPaths = this.selectedTableItem.map(
          (selectTableRow: Types.ServiceOffer) =>
            selectTableRow.pdf_path_medical
        );
        break;
      default:
        await this.$openAlert("サービス提供票の種類が無効です。");
        return;
    }
    this.previewJoinedPdf(
      pdfPaths,
      0,
      this.needMaskedPdfServiceOffers,
      res => {
        if (!this.needMaskedPdfServiceOffers) {
          return;
        }
        // 伏せ字ファイルが未作成の利用者名をダイアログ表示する
        const paths = res.data.not_found_paths;
        if (!paths || paths.length == 0) {
          return;
        }
        const items = this.serviceOfferItems.filter(item => {
          let path = "";
          switch (this.searchCondSave.selectedServiceOfferType) {
            case 1:
              path = item.pdf_path_beppyo; //別表
              break;
            case 2:
              path = item.pdf_path_care; //給付費
              break;
            case 3:
              path = item.pdf_path_medical; //医療
              break;
          }
          for (let i = 0; i < paths.length; i++) {
            if (paths[i] == path) {
              return true;
            }
          }
          return false;
        });
        if (items.length > 0) {
          const msg = items.map(o => o.patient_name).join("\r\n");
          this.$openAlert(
            "伏字の帳票PDF表示ができませんでした。\r\n" +
              "伏字の帳票は「実績確定」時に作成します。そのためレセプト処理の「実績確定日」が以前の場合は再度「実績確定」を行う必要があります。\r\n\r\n" +
              "PDF表示ができなかった利用者名：\r\n\r\n" +
              msg,
            "閉じる"
          );
        }
      },
      () => {
        if (this.needMaskedPdfServiceOffers) {
          this.$openAlert(
            "伏字の帳票PDF表示ができませんでした。\r\n\r\n" +
              "伏字の帳票は「実績確定」時に作成します。そのためレセプト処理の「実績確定日」が以前の場合は再度「実績確定」を行う必要があります。",
            "閉じる"
          );
        }
      }
    );

    this.hideServiceOffersDialog();
  }

  /** 看護体制強化届出資料PDF生成表示 */
  private async previewCareEnhancedPdf(): Promise<void> {
    if (!this.careEnhancedData) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/careenhanced/pdf/preview",
      this.careEnhancedData
    );
  }

  /** サービス提供状況PDF生成表示 */
  private async previewServiceProvisionPdf(): Promise<void> {
    if (!this.serviceProvisionData) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/serviceprovision/pdf/preview",
      this.serviceProvisionData
    );
  }

  /** 実施内容集計表PDF生成表示 */
  private async previewImplementSummaryPdf(): Promise<void> {
    if (!this.implementSummaryData?.care_contents.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/implsummary/pdf/preview",
      this.implementSummaryData
    );
  }

  /** 実施報告書PDF生成表示 */
  private async previewImplementReportPdf(): Promise<void> {
    if (!this.implementReportData?.reports.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/implreport/pdf/preview",
      this.implementReportData
    );
  }

  /** 月間訪問実績集計表PDF生成表示 */
  private async previewMonthSummaryPdf(): Promise<void> {
    if (!this.monthSummaryData?.month_summary.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/monthsummary/preview",
      {
        month_summary_report: this.monthSummaryData
      }
    );
  }

  /** 業務日報PDF生成表示 */
  private async previewDailyReportPdf(): Promise<void> {
    if (!this.dailyReportData?.reports.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/dailyreport/pdf/preview",
      {
        office_names: this.dailyReportData.office_names,
        reports: this.dailyReportData.reports
      }
    );
  }

  /** 月次一括PDF生成表示 */
  private async previewMonthlyBatch(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択してください");
      return;
    }
    this.makePdf(window.base_heavy_url + "/api/reportlist/monthlybatch/pdf", {
      monthly_batches: this.selectedTableItem
    });
  }

  /** 看護記録ハイライトPDF表示 */
  private previewHighlightRecordPdf(): void {
    const pdfPaths = this.selectedTableItem.map(
      (selectTableRow: Types.HighlightRecord) => selectTableRow.pdf_path
    );
    this.previewJoinedPdf(pdfPaths);
  }

  /** 緊急対応ハイライトPDF表示 */
  private async previewHighlightUrgencyPdf(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択してください");
      return;
    }
    this.makePdf(
      window.base_heavy_url + "/api/reportlist/fulltime/patients/pdf",
      {
        fulltimes: this.selectedTableItem
      }
    );
  }

  /** 帳票差戻し */
  private async remandReport(apiUrl: string, force: boolean): Promise<void> {
    const reportName =
      this.selectedRadioItem.shortName || this.selectedRadioItem.text;

    let confirmMessage = `${reportName}を記入者へ差戻しますか？`;
    switch (this.selectedRadioItem.value) {
      case this.ReportType.VISITRECORD:
        confirmMessage = `${reportName}を ${this.remandTo}さん へ差戻しますか？`;
        break;
      case this.ReportType.VNPLANMONTH:
        confirmMessage = "選択した帳票を差戻します。よろしいですか？";
        break;
    }

    // 実績確定が解除されることの確認、強制実行オプション付きならスキップ
    if (!force && !(await this.$openConfirm(confirmMessage))) {
      return;
    }

    const id = this.remandReportId;
    const comment = this.remandComment;
    this.postJsonCheck(apiUrl, { id, comment, force }, async res => {
      this.isRemandDialogOpen = false;
      if (res.data.code > 0) {
        await this.$openAlert(res.data.message);
        return;
      } else if (!force && res.data.is_receipt_decision) {
        // 強制でない場合は確認を挟む
        if (await this.$openConfirm(res.data.message)) {
          // 実績確定が解除されることに同意したので、強制実行オプション付きで再実行
          this.remandReport(apiUrl, true);
        }
      } else {
        this.reportUnselectAll();
        this.fetchReportList();
      }
    });
  }

  /** 送付状PDF生成表示 */
  private async previewLettersPdf(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    let apiUrl = "";
    let address = 0;
    switch (this.selectedRadioItem.value) {
      case this.ReportType.VNPLAN:
        apiUrl =
          window.base_heavy_url + "/api/reportletters/vnplan/pdf/preview";
        address = this.searchCondSave.selectedAddressVnPlan;
        break;
      case this.ReportType.VNPLANMONTH:
        apiUrl =
          window.base_heavy_url + "/api/reportletters/vnplanmonth/pdf/preview";
        address = this.searchCondSave.selectedAddressVnPlanMonth;
        break;
      case this.ReportType.MONTHREPORT:
        apiUrl =
          window.base_heavy_url + "/api/reportletters/monthreport/pdf/preview";
        address = this.searchCondSave.selectedAddressMonthReport;
        break;
      default:
        await this.$openAlert(
          "看護計画書／月次報告書／看護計画書+月次報告書 のいずれかを選択してください。"
        );
        return;
    }

    this.makePdf(apiUrl, {
      reports: this.selectedTableItem,
      address
    });
  }

  /** 指示書送付状PDF生成表示 */
  private async previewIndicateLettersPdf(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(
      window.base_heavy_url + "/api/reportletters/indicateexpired/pdf/preview",
      { indicates: this.selectedTableItem }
    );
  }

  /** 情報提供書+送付状PDF生成表示 */
  private async previewProvisionLettersPdf(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.makePdf(
      window.base_heavy_url + "/api/reportletters/provisionreport/pdf/preview",
      { provision_reports: this.selectedTableItem }
    );
  }

  /** 送付状Excel生成ダウンロード */
  private async downloadLettersExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    let apiUrl = "";
    let address = 0;
    let fileName = "";
    switch (this.selectedRadioItem.value) {
      case this.ReportType.VNPLAN:
        apiUrl =
          window.base_heavy_url + "/api/reportletters/vnplan/excel/download";
        address = this.searchCondSave.selectedAddressVnPlan;
        fileName = "keikakusyo.xlsx";
        break;
      case this.ReportType.VNPLANMONTH:
        apiUrl =
          window.base_heavy_url +
          "/api/reportletters/vnplanmonth/excel/download";
        address = this.searchCondSave.selectedAddressVnPlanMonth;
        fileName = "keikakuhoukokusyo.xlsx";
        break;
      case this.ReportType.MONTHREPORT:
        apiUrl =
          window.base_heavy_url +
          "/api/reportletters/monthreport/excel/download";
        address = this.searchCondSave.selectedAddressMonthReport;
        fileName = "houkokusyo.xlsx";
        break;
      default:
        await this.$openAlert(
          "看護計画書／月次報告書／看護計画書+月次報告書 のいずれかを選択してください。"
        );
        return;
    }

    this.postJsonBlobResCheck(
      apiUrl,
      {
        reports: this.selectedTableItem,
        address
      },
      res => {
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** 表内容Excel生成ダウンロード */
  private async downloadExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    switch (this.selectedRadioItem.value) {
      case this.ReportType.VISITRECORDUNSUBMIT:
        this.downloadVisitRecordExcel();
        break;
      case this.ReportType.INDICATEEXPIRED:
        this.downloadListExcel("IndicateExpired.xlsx");
        break;
      case this.ReportType.PUBLICMONEYXPIRED:
        this.downloadListExcel("PublicMoneyExpired.xlsx");
        break;
      case this.ReportType.MONTHLYEXIST:
        this.downloadMonthlyExistExcel();
        break;
      case this.ReportType.EMERGENCY_CONTACT_LIST:
        this.downloadEmergencyContactListExcel();
        break;
    }
  }

  /** 看護記録書IIExcel生成ダウンロード */
  private async downloadVisitRecordExcel(): Promise<void> {
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/visitrecord/excel/download",
      { visit_records: this.selectedTableItem },
      res => {
        this.downloadFile(res.data, "RecordNoSubmit.xlsx");
      }
    );
  }

  /** 指示書有効期限切れリストExcel生成ダウンロード */
  private async downloadListExcel(fileName: string): Promise<void> {
    const excelHeaders = this.tableHeader.map(header => header.text);
    const reqItems = [excelHeaders];
    for (const item of this.selectedTableItem) {
      const excelItem = this.tableHeader.map(header => item[header.value]);
      reqItems.push(excelItem);
    }

    this.postJsonBlobResCheck(
      window.base_url + "/api/dashboard/excel/download",
      { items: reqItems },
      res => {
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** 指示書送付状Excel生成ダウンロード */
  private async downloadIndicateLettersExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url +
        "/api/reportletters/indicateexpired/excel/download",
      { indicates: this.selectedTableItem },
      res => {
        this.downloadFile(res.data, "shijisyo.xlsx");
      }
    );
  }

  /** 情報提供書+送付状Excel生成ダウンロード */
  private async downloadProvisionLettersExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url +
        "/api/reportletters/provisionreport/excel/download",
      { provision_reports: this.selectedTableItem },
      res => {
        this.downloadFile(res.data, "ProvisionLetter.xlsx");
      }
    );
  }

  /** 月次書類確認リストExcel生成ダウンロード */
  private async downloadMonthlyExistExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/monthlyexist/excel/download",
      { monthly_exists: this.selectedTableItem },
      res => {
        this.downloadFile(res.data, "MonthlyReports.xlsx");
      }
    );
  }

  /** 緊急連絡先リストExcel生成ダウンロード */
  private async downloadEmergencyContactListExcel(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/emergency/excel/download",
      { emergency_contact_list: this.selectedTableItem },
      res => {
        const fileName = `緊急連絡先リスト_${appDate.dateToStr(
          new Date(),
          "yyyyMMddHHmm"
        )}.xlsx`;

        this.downloadFile(res.data, fileName);

        // Firestoreへ出力日時を保存
        if (this.loginUser.office_id > 0) {
          this.emergencyContactListLastOutput.output_datetime = appDate.dateToStr(
            new Date(),
            "yyyy/MM/dd HH:mm"
          );
          this.emergencyContactListLastOutput.is_fetch_error = false;

          this.collection = COLLECTION_EMERGENCY_CONTACT_LIST_LAST_OUTPUT_DATETIME;
          this.documentId = String(this.loginUser.office_id);
          this.documentSave(this.emergencyContactListLastOutput);
        }
      }
    );
  }

  /** 新規受付チェックリストCSV生成ダウンロード */
  private async downloadNewReceptionCsv(): Promise<void> {
    if (!this.selectedTableItem.length) {
      await this.$openAlert("選択されていません");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/newreception/csv/download",
      { new_receptions: this.selectedTableItem },
      res => {
        const timestamp = appDate.dateToStr(new Date(), "yyyyMMddHHmm");
        this.downloadFile(res.data, `新規受付チェックリスト_${timestamp}.csv`);
      }
    );
  }

  /** 実施報告書Excel生成ダウンロード */
  private async downloadImplementReportExcel(
    select: number | null
  ): Promise<void> {
    if (!this.implementReportData?.reports.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }

    if (this.implementReportData && select) {
      this.implementReportData.order = select;
    }
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/implreport/excel/download",
      this.implementReportData,
      res => {
        this.downloadFile(res.data, "Implementation.xlsx");
      }
    );
  }

  /** 実施報告書CSV生成ダウンロード */
  private async downloadImplementReportCsv(
    select: number | null
  ): Promise<void> {
    if (!this.implementReportData?.reports.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }

    if (this.implementReportData && select) {
      this.implementReportData.order = select;
    }
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/implreport/csv/download",
      this.implementReportData,
      res => {
        this.downloadFile(res.data, "Implementation.csv");
      }
    );
  }

  /** 月間訪問実績集計表CSVダウンロード */
  private async downloadMonthSummaryCsv(select: number | null): Promise<void> {
    if (!this.monthSummaryData?.month_summary.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }

    if (this.monthSummaryData && select) {
      this.monthSummaryData.sort_type = select;
    }
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/monthsummary/csv/download",
      {
        month_summary_report: this.monthSummaryData
      },
      res => {
        this.downloadFile(res.data, "Implementation.csv");
      }
    );
  }

  /** 保健師・助産師・看護師・准看護師業務従事者届Excel生成ダウンロード */
  private async downloadBusinessWorkReport(): Promise<void> {
    if (!this.businessWorkReportData.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/dashboard/excel/download",
      {
        items: this.businessWorkReportData,
        template_file_name: "businessWorkReport.xlsx",
        row_offset: 5
      },
      res => {
        this.downloadFile(res.data, "BusinessWokerReportExcel.xlsx");
      }
    );
  }

  /** レセプト実績(売上) データ取得 */
  private fetchReceiptResult(): void {
    this.isExistStat = false;
    this.postJsonCheck(
      window.base_heavy_url + "/api/reportlist/receiptresult/get",
      {
        target_yearmonth: this.searchCond.viewMonthLast,
        office_ids: this.searchCond.officeIds,
        group_ids: this.searchCond.selectedGroupItems.filter(Boolean),
        group_operator: this.searchCond.selectedGroupOperator,
        keyword: this.searchCond.keyword
      },
      res => {
        this.receiptResultData = res.data;
        this.isExistStat = res.data.receipt_results.length > 0;
      }
    );
  }

  /** レセプト実績(売上) CSV出力 */
  private async downloadReceiptResult(): Promise<void> {
    if (!this.receiptResultData?.receipt_results?.length) {
      await this.$openAlert(
        "出力するデータがありません。検索条件を見直し、もう一度検索してください。"
      );
      return;
    }
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/reportlist/receiptresult/download",
      {
        receipt_results: this.receiptResultData.receipt_results,
        yearmonth: this.receiptResultData.yearmonth,
        office_name: this.receiptResultData.office_name
      },
      res => {
        this.downloadFile(res.data, "SalesList.csv");
      }
    );
  }

  /** ファイルのダウンロード */
  private downloadFile(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  /** 前回出力日時：緊急連絡先リスト */
  private get EmergencyContactListLastOutputDatetime(): string {
    if (this.emergencyContactListLastOutput.is_fetch_error) {
      return "取得に失敗しました";
    }

    return this.emergencyContactListLastOutput.output_datetime;
  }
}
