export interface SearchItem {
  corp_name: string;
  tel_area: string;
  name: string;
}

export const DefaultSearchItem = (): SearchItem => ({
  corp_name: "",
  tel_area: "",
  name: ""
});

export interface SearchResList {
  id: number;
  corp_name: string;
}

export interface AddItem {
  corpId: number;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
}

export const DefaultAddItem = (): AddItem => ({
  corpId: 0,
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: ""
});
