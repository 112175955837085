













































































































































































































































































































import { Component, Mixins, Prop, Watch, Emit } from "vue-property-decorator";
import {
  DefaultVisitModel,
  Master,
  VisitModel
} from "@/components/patient/record2/types";
import { TimeCares } from "@/components/patient/record2/const";
import {
  VisitPatient,
  ResetVisitTimeType,
  VisitOffice
} from "@/components/visit/types";
import * as constant from "#/const";
import { Choice } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class AddItem extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  RulesSaveMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: DefaultVisitModel() })
  private readonly model!: VisitModel;
  @Prop() private readonly master!: Master;
  @Prop() private readonly patient!: VisitPatient;
  @Prop() private readonly office!: VisitOffice;
  @Prop() private readonly isLoaded!: boolean;

  @Watch("model.time_type")
  private watchNursingType() {
    this.update();
  }
  @Watch("model.is_long_care")
  private watchSenmonCareDiv() {
    this.update();
  }
  @Watch("model.is_emergency_care")
  private watchOnlineVisitFlg() {
    this.update();
  }

  @Emit() update() {
    return;
  }

  @Emit()
  private changeInsDiv() {
    // 保険区分を切り替える
    return;
  }

  /*
   * watch
   */

  // 訪問種別監視
  @Watch("model.nursing_type", { deep: true })
  private changeNursingType() {
    if (!this.isLoaded) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/get",
      {
        patient_id: this.patient.id,
        record2_id: this.model.id,
        visit_date: this.model.visit_start_datetime,
        nursing_type: this.model.nursing_type
      },
      async res => {
        // 保険区分の切り替えチェック
        if (res.data) {
          const resInsDiv = res.data.patient.ins_div;
          if (this.patient.ins_div !== resInsDiv) {
            let message =
              resInsDiv === constant.PATIENT.INSURANCE_DIV_CARE
                ? "介護訪問"
                : "医療訪問";
            message += "に切り替えます";
            // 保険区分が切り替わる場合はアラートを出す
            await this.$openAlert(message);
            // 保険区分を切り替える
            this.changeInsDiv();
          }
        }

        // 訪問種別changeイベント
        this.changeVisitType();

        if (this.model.nursing_type !== 4 && this.model.nursing_type !== 5) {
          // 「専門看護師として同行を行う 、 専門管理加算」が外れたら専門看護師の訪問種類も未選択にする
          this.model.senmon_care_div = 0;
        }
        if (
          this.model.nursing_type !== 5 &&
          this.model.senmon_care_div ==
            constant.VISIT_RECORD.SENMON_NURSSE_PRACTITIONER
        ) {
          // 「専門管理加算で特定行為」が外れたら専門看護師の訪問種類も未選択にする
          this.model.senmon_care_div = 0;
        }
      }
    );
  }

  // 訪問時刻監視
  @Watch("model.visit_start_datetime")
  private changeVisitStartDatetime() {
    if (!this.isLoaded) {
      // 画面読み込み時の反映で訪問開始時間が変わった場合は、訪問種別を変えない
      return;
    }
    // 時間
    if (
      this.patient.regular_visit_div ===
      constant.PATIENT.REGULAR_VISIT_DIV_NOTREGULAR
    ) {
      this.model.time_type = ResetVisitTimeType(
        this.model.visit_start_datetime
      );
    } else {
      this.model.time_type = 0;
    }

    // 初回加算
    if (!this.IsAfterHoukaisei202406) {
      // 法改正前の日付に変更したとき、初回加算に改正後のvalueが入っていたら初期化する
      if (this.model.first_visit_flg >= 2) {
        this.model.first_visit_flg = 0;
      }
    }
  }

  // 専門ケア
  @Watch("model.senmon_care_div")
  private changeSenmonCareDiv() {
    if (this.model.senmon_care_div != 4) {
      this.model.trach_tube_replace_flg = 0;
      this.model.gast_jej_catheter_button_replace_flg = 0;
      this.model.bladder_catheter_replace_flg = 0;
      this.model.necrotic_tissue_remove_flg = 0;
      this.model.negative_pressure_wound_therapy_flg = 0;
      this.model.high_cal_infusion_adjust_flg = 0;
      this.model.fluid_dehydration_correction_flg = 0;
    }
  }

  /*
   * computed
   */

  // 訪問種別
  private get visitTypes(): Choice[] {
    const choices: Choice[] = [];
    choices.push({ value: 0, text: "未選択" });
    choices.push({ value: 1, text: "サービス訪問（自費）" });
    choices.push({ value: 2, text: "入院中の外泊訪問（医療）" });
    choices.push({ value: 3, text: "加算のみ（医療／介護）" });
    // 事業所の設定に専門看護師あり
    if (
      this.office.office_info.medical_is_senmon_kangoshi == 1 &&
      this.patient.ins_div != constant.PATIENT.INSURANCE_DIV_CARE
    ) {
      choices.push({
        value: 4,
        text:
          "専門看護師として同行を行う※他の訪問看護ステーションまたは在宅医療の保険医療期間の看護師等と同行を行う"
      });
    }
    // 事業所の設定に専門看護師あり
    if (
      // 事業所の設定に専門看護師あり（2022/4以降）
      (this.office.office_info.medical_is_senmon_kangoshi == 1 &&
        this.patient.ins_div != constant.PATIENT.INSURANCE_DIV_CARE &&
        this.model.visit_start_datetime.substr(0, 7) >= "2022-04") ||
      // 介護に専門管理加算を追加
      (this.office.office_info.care_is_senmon_kangoshi == 1 &&
        this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_CARE &&
        this.model.visit_start_datetime.substr(0, 7) >= "2024-06")
    ) {
      choices.push({
        value: 5,
        text: "専門看護師による計画的な管理（専門管理加算）"
      });
    }

    // 訪問種別の選択値が選択可能なリストから外れた場合は未選択に変更する
    const type = choices.find(type => type.value == this.model.nursing_type);
    if (!type) {
      this.model.nursing_type = 0;
    }
    return choices;
  }

  // 緊急時訪問看護加算表示フラグ
  private get showEmergencyCare(): boolean {
    // 医療、かつ、事業所の設定で医療緊急訪問看護加算ありの場合表示
    if (
      this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_MEDICAL &&
      this.office.office_info.medical_kinkyu_houmon_kango_add
    ) {
      return true;
    }
    return false;
  }

  /** 令和4年(2022年)4月法改正後フラグ */
  private get IsAfterHoukaisei202204(): boolean {
    return this.model.visit_start_datetime.substr(0, 7) >= "2022-04";
  }

  /** 令和4年(2024年)6月法改正後フラグ */
  private get IsAfterHoukaisei202406(): boolean {
    return this.model.visit_start_datetime.substr(0, 7) >= "2024-06";
  }

  /** 緊急訪問看護加算算定理由の記載欄を表示するかどうか */
  private get IsShownEmergencyReason(): boolean {
    // 医療のみ
    if (this.model.insurance_div === constant.PATIENT.INSURANCE_DIV_CARE) {
      return false;
    }

    // 2024年6月の訪問から有効
    if (
      this.model.visit_start_datetime.length < 7 ||
      this.model.visit_start_datetime.substring(0, 7) < "2024-06"
    ) {
      return false;
    }

    // 緊急訪問看護加算ONの時のみ
    return this.model.is_emergency_care === 1;
  }

  /*
   * data
   */

  // 定数
  private constant = constant;

  // 時間帯における訪問看護加算（医療／介護）items
  private TimeCares = TimeCares;

  /*
   * method
   */

  // 訪問種別changeイベント
  private async changeVisitType(): Promise<void> {
    if (
      this.model.nursing_type === constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE
    ) {
      // サービス訪問（自費）選択時アラート
      await this.$openAlert(
        "サービス訪問（自費）はレセプトに実績が反映されません"
      );
    }
    if (
      this.model.nursing_type === constant.VISIT_RECORD.NURSING_TYPE_ADDONLY
    ) {
      // 加算のみ選択時アラート
      await this.$openAlert("加算のみ選択時は基本療養費は算定されません");
    }
    /*
     サービス訪問/入院中の外泊訪問/加算のみ/専門看護師として同行を行う 選択時以下が選択されていた場合チェックを外す
      - 時間帯における訪問看護加算（医療／介護）
      - 同一日同一建物
      - 長時間訪問看護 （医療）※90分を超えた場合
      - 複数名訪問看護加算※専門看護師の場合はそのまま
     */
    if (
      [
        constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE,
        constant.VISIT_RECORD.NURSING_TYPE_STAYOUTSIDE,
        constant.VISIT_RECORD.NURSING_TYPE_ADDONLY,
        constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL
      ].includes(this.model.nursing_type)
    ) {
      this.model.time_type = 0; // 時間帯における訪問看護加算（医療／介護）
      this.model.is_long_care = 0; // 長時間訪問看護 （医療）※90分を超えた場合
      this.model.same_p2_flg = 0; // 同一日同一建物
      this.model.same_p3_flg = 0; // 同一日同一建物
    }
    /*
     サービス訪問/入院中の外泊訪問/専門看護師として同行を行う 選択時（加算以外）以下が選択されていた場合チェックを外す
      - 緊急時訪問看護 （医療）
     */
    if (
      [
        constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE,
        constant.VISIT_RECORD.NURSING_TYPE_STAYOUTSIDE,
        constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL
      ].includes(this.model.nursing_type)
    ) {
      this.model.is_emergency_care = 0; // 緊急時訪問看護 （医療）
    }
    /*
     サービス訪問/入院中の外泊訪問/加算のみ 選択時(専門看護師以外)以下が選択されていた場合チェックを外す
      - 複数名訪問看護加算
     */
    if (
      [
        constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE,
        constant.VISIT_RECORD.NURSING_TYPE_STAYOUTSIDE,
        constant.VISIT_RECORD.NURSING_TYPE_ADDONLY
      ].includes(this.model.nursing_type)
    ) {
      this.model.is_multi_care = 0; // 複数名訪問看護加算
    }

    // 2022/4法改正後
    // 「専門看護師として同行を行う」を選択時、主訪問者の保有資格に・専門看護師（緩和ケア）・専門看護師（褥瘡ケア）・専門看護師（人工肛門/膀胱ケア）のいずれかがある場合
    // 「専門管理加算」を選択時、主訪問者の保有資格に・専門看護師（緩和ケア）・専門看護師（褥瘡ケア）・専門看護師（人工肛門/膀胱ケア）・特定行為のいずれかがある場合
    // 専門看護師の訪問種類を保有資格にする（複数保有の場合は上から順番に選択する）
    if (
      this.model.visit_start_datetime.substr(0, 7) >= "2022-04" &&
      (this.model.nursing_type ==
        constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL ||
        this.model.nursing_type ==
          constant.VISIT_RECORD.NURSING_TYPE_SPECIAL_MANAGEMENT_ADD)
    ) {
      const staff = this.master.staffs.find(
        staff => staff.value === this.model.staff_id
      );
      if (staff?.certifications != undefined) {
        for (const certification of staff?.certifications) {
          // 専門看護師（緩和ケア）
          if (
            certification.certification_id ===
            constant.VISIT_RECORD.CERTIFICATION_SPECIALITY_NURSE_MITIGATION
          ) {
            this.model.senmon_care_div =
              constant.VISIT_RECORD.SENMON_CARE_DIV_MITIGATION;
            break;
          }
          // 専門看護師（褥瘡ケア）
          if (
            certification.certification_id ===
            constant.VISIT_RECORD.CERTIFICATION_SPECIALITY_NURSE_BEDSORE
          ) {
            this.model.senmon_care_div =
              constant.VISIT_RECORD.SENMON_CARE_DIV_BEDSORE;
            break;
          }
          // 専門看護師（人工肛門/膀胱ケア）
          if (
            certification.certification_id ===
            constant.VISIT_RECORD.CERTIFICATION_SPECIALITY_NURSE_URINARY_BLADDER
          ) {
            this.model.senmon_care_div =
              constant.VISIT_RECORD.SENMON_CARE_DIV_STOMA;
            break;
          }
          // 専門管理加算の場合に限る
          // 特定行為
          if (
            this.model.nursing_type ==
            constant.VISIT_RECORD.NURSING_TYPE_SPECIAL_MANAGEMENT_ADD
          ) {
            if (
              certification.certification_id ===
              constant.VISIT_RECORD.CERTIFICATION_NURSSE_PRACTITIONER
            ) {
              this.model.senmon_care_div =
                constant.VISIT_RECORD.SENMON_NURSSE_PRACTITIONER;
              break;
            }
          }
        }
      }
    }
  }

  // 時間帯における訪問看護加算changeイベント
  private changeTimeType() {
    // 時間帯における訪問看護加算選択時、訪問種別を未選択にする
    if (this.model.time_type > 0) {
      this.model.nursing_type = 0;
    }
  }

  // 長時間訪問看護加算changeイベント
  private changeIsLongCare() {
    // 長時間訪問看護加算選択時、訪問種別に"専門管理加算"以外を選択時、訪問種別を未選択にする
    if (
      this.model.is_long_care > 0 &&
      this.model.nursing_type != constant.VISIT_RECORD.NURSING_TYPE_SPECIAL_MANAGEMENT_ADD
    ) {
      this.model.nursing_type = 0;
    }
  }

  // 緊急訪問加算changeイベント
  private changeIsEmergencyCare() {
    // 緊急訪問選択、かつ、訪問種別に"加算のみ"以外選択時、訪問種別を未選択にする
    if (
      this.model.is_emergency_care > 0 &&
      this.model.nursing_type != constant.VISIT_RECORD.NURSING_TYPE_ADDONLY
    ) {
      this.model.nursing_type = 0;
    }
  }

  // 同一日同一建物changeイベント
  private changeSameP2Flg() {
    if (this.model.same_p2_flg) {
      this.model.same_p3_flg = 0;
      // 訪問種別を未選択にする
      this.model.nursing_type = 0;
    }
  }
  // 同一日同一建物changeイベント
  private changeSameP3Flg() {
    if (this.model.same_p3_flg) {
      this.model.same_p2_flg = 0;
      // 訪問種別を未選択にする
      this.model.nursing_type = 0;
    }
  }

  // 複数名訪問看護加算changeイベント
  private changeIsMultiCare() {
    // 複数名訪問看護加算選択、かつ、訪問種別に"専門看護師として同行を行う"以外選択時、訪問種別を未選択にする
    if (
      this.model.is_multi_care > 0 &&
      this.model.nursing_type !=
        constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL
    ) {
      this.model.nursing_type = 0;
    }
  }

  // オンライン訪問の場合は訪看I1以外選択できない
  private checkOnlineVisit(): boolean | string {
    if (
      this.model.nursing_type === constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE
    ) {
      return true; //自費の場合はチェック不要
    }
    if (
      this.model.online_visit_flg &&
      this.model.nursing_type !== constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE
    ) {
      const msg =
        "コロナ5類対応によりオンライン訪問（電話訪問）の臨時対応が以下の期限で対応終了になります。\r\n" +
        "　医療：〜2023年7月31日\r\n" +
        "　介護：〜2023年5月7日\r\n" +
        "オンライン訪問（電話対応）をオンにする場合は、訪問種別に「サービス訪問（自費）」を選択してください。";
      if (
        this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_MEDICAL &&
        10 <= this.model.visit_start_datetime.length &&
        "2023-07-31" < this.model.visit_start_datetime.substring(0, 10)
      ) {
        return msg; //医療：〜2023年7月31日
      }
      if (
        this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_CARE &&
        10 <= this.model.visit_start_datetime.length &&
        "2023-05-07" < this.model.visit_start_datetime.substring(0, 10)
      ) {
        return msg; //介護：〜2023年5月7日
      }
    }
    if (
      this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_CARE && // 介護
      this.model.online_visit_flg && // オンライン訪問チェックあり
      this.model.service_time_div != constant.VISIT_RECORD.SERVICE_TIME_DIV_I1 // 提供時間の選択が訪看Ⅰ1以外
    ) {
      return "オンライン訪問の場合は訪看I1以外選択できません";
    }
    return true;
  }

  // 専門看護師を選択できます。
  private checkAccompanySenmon(): boolean | string {
    //2022/4法改正前
    if (this.model.visit_start_datetime.substr(0, 7) < "2022-04") {
      // 「専門看護師として同行」選択
      if (
        this.model.nursing_type ===
        constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL
      ) {
        const staff = this.master.staffs.find(
          staff => staff.value === this.model.staff_id
        );
        // 訪問者に専門看護師以外を選択
        if (staff?.job_type !== 3) {
          return "専門看護師のみ「専門看護師として同行を行う」を選択できます";
        }
      }
    }

    //2022/4法改正後
    //判定に「専門看護師による計画的な管理（専門管理加算）」も追加
    //専門看護師のみの判定に資格の条件も追加
    if (this.model.visit_start_datetime.substr(0, 7) >= "2022-04") {
      // 「専門看護師として同行」もしくは「専門看護師による計画的な管理（専門管理加算）」選択
      if (
        this.model.nursing_type ===
          constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL ||
        this.model.nursing_type ===
          constant.VISIT_RECORD.NURSING_TYPE_SPECIAL_MANAGEMENT_ADD
      ) {
        const staff = this.master.staffs.find(
          staff => staff.value === this.model.staff_id
        );
        // 訪問者に専門看護師以外を選択
        if (staff?.job_type !== 3) {
          return "専門看護師のみ選択できます";
        }
      }
    }
    return true;
  }

  // 専門看護師の訪問種類を選択してください。
  private checkSenmonCareDiv(): boolean | string {
    // 「専門看護師として同行」選択
    if (
      this.model.nursing_type ===
      constant.VISIT_RECORD.NURSING_TYPE_ACCOMPANY_PROFESSIONAL
    ) {
      // 専門看護師の訪問種類未選択
      if (this.model.senmon_care_div === 0) {
        return "専門看護師の訪問種類を選択してください";
      }
    }
    return true;
  }
  // 専門看護師の訪問種類を選択してください。
  private checkSpecialManagementAddSenmonCareDiv(): boolean | string {
    // 「専門看護師による計画的な管理（専門管理加算）」選択
    if (
      this.model.nursing_type ===
      constant.VISIT_RECORD.NURSING_TYPE_SPECIAL_MANAGEMENT_ADD
    ) {
      // 専門看護師の訪問種類未選択
      if (this.model.senmon_care_div === 0) {
        return "専門看護師の訪問種類を選択してください";
      }
    }
    return true;
  }
  // 特定行為を選択してください。
  private checkPractitionerDiv(): boolean | string {
    if (this.is_save) {
      return true;
    }
    // 専門看護師の訪問種類で「特定行為」を選択
    if (this.model.senmon_care_div === 4) {
      // 特定行為未選択
      if (
        this.model.trach_tube_replace_flg === 0 &&
        this.model.gast_jej_catheter_button_replace_flg === 0 &&
        this.model.bladder_catheter_replace_flg === 0 &&
        this.model.necrotic_tissue_remove_flg === 0 &&
        this.model.negative_pressure_wound_therapy_flg === 0 &&
        this.model.high_cal_infusion_adjust_flg === 0 &&
        this.model.fluid_dehydration_correction_flg === 0
      ) {
        return "特定行為を選択してください";
      }
    }
    return true;
  }

  /** 職員の職種を取得 */
  private getStaffJobType(staffId: number): number {
    const staff = this.master.staffs.find(staff => staff.value === staffId);
    return staff ? staff.job_type : 0;
  }

  // 複数名訪問での同行者チェックあり
  private checkIsMultiCare(): boolean | string {
    // チェックがない場合はtrue
    if (this.model.is_multi_care == 0) {
      return true;
    }

    // 令和4年法改正後、同行者1は選択が必要
    if (this.model.accompany1_id == 0 && this.IsAfterHoukaisei202204) {
      return "同行者①を選択して下さい";
    }

    // 法改正によらず、全同行者が未選択はNG
    if (
      this.model.accompany1_id == 0 &&
      this.model.accompany2_id == 0 &&
      this.model.accompany3_id == 0
    ) {
      return "同行者を選択して下さい";
    }

    // 看護学生に関するチェック
    const accompany1JobType = this.getStaffJobType(this.model.accompany1_id);
    const accompany2JobType = this.getStaffJobType(this.model.accompany2_id);
    const accompany3JobType = this.getStaffJobType(this.model.accompany3_id);

    // 法改正後、同行者1は看護学生以外のみ
    if (
      accompany1JobType == constant.OFFICE_STAFF.STAFF_DIV_STUDENT &&
      this.IsAfterHoukaisei202204
    ) {
      return "同行者①には看護学生以外を選択してください";
    }

    // 同行者が全て学生はNG
    let isOnlyStudent = true;

    if (
      accompany1JobType != 0 &&
      accompany1JobType != constant.OFFICE_STAFF.STAFF_DIV_STUDENT
    ) {
      isOnlyStudent = false;
    }

    if (
      accompany2JobType != 0 &&
      accompany2JobType != constant.OFFICE_STAFF.STAFF_DIV_STUDENT
    ) {
      isOnlyStudent = false;
    }

    if (
      accompany3JobType != 0 &&
      accompany3JobType != constant.OFFICE_STAFF.STAFF_DIV_STUDENT
    ) {
      isOnlyStudent = false;
    }

    if (isOnlyStudent) {
      return "同行者に、看護学生以外を最低1名選択してください";
    }

    // 医療訪問で主訪問者がPT・OT・STはNG
    const staffJob = this.getStaffJobType(this.model.staff_id);
    if (
      (staffJob == constant.OFFICE_STAFF.STAFF_DIV_PT ||
        staffJob == constant.OFFICE_STAFF.STAFF_DIV_OT ||
        staffJob == constant.OFFICE_STAFF.STAFF_DIV_ST) &&
      this.model.mental_div == constant.PATIENT.MENTAL_DIV_NORMAL &&
      this.model.insurance_div == constant.PATIENT.INSURANCE_DIV_MEDICAL
    ) {
      return "主訪問者の職種が複数名訪問看護加算の算定要件に含まれていません";
    }

    return true;
  }

  // 訪問者と同行者重複チェック
  private checkDuplicateStaff(): boolean | string {
    // 訪問者と同行者重複
    let ids: number[] = [];
    ids = this.pushStaffId(this.model.accompany1_id, ids);
    ids = this.pushStaffId(this.model.accompany2_id, ids);
    ids = this.pushStaffId(this.model.accompany3_id, ids);
    const notDupIds: number[] = Array.from(new Set(ids));
    if (
      this.model.staff_id &&
      (this.model.staff_id === this.model.accompany1_id ||
        this.model.staff_id === this.model.accompany2_id ||
        this.model.staff_id === this.model.accompany3_id)
    ) {
      return "訪問者と同行者が重複しています";
    }
    if (ids.length > notDupIds.length) {
      return "同行者が重複しています";
    }
    return true;
  }

  private pushStaffId(id: number, ids: number[]): number[] {
    if (id > 0) {
      ids.push(id);
    }
    return ids;
  }
}
