import { Choice } from "@/types";
import {
  NursingDetailMaster,
  ObservationCategoryMaster,
  BedsoreMaster,
  RiskFactorMaster
} from "#/model/master";
import {
  Bedsore,
  CareContent,
  Observation,
  Pdf,
  DefaultPdf,
  File,
  DefaultFile,
  RiskFactor,
  DefaultRiskFacotr,
  Vital,
  DefaultVital
} from "#/model/report";
import { OwnExpense } from "#/model/patient";
import { ApplyStaff, DefaultApplyStaff } from "#/model/common";
import { OwnExpenseItemChoice } from "#/model/patient/ownExpense";
import { VisitRecordRemand, DefaultVisitRecordRemand } from "#/model/visit";

export interface OfficeStaffChoice extends Choice {
  job_type: number; // 職種ID
  certifications: Certification[]; //資格
}

// 資格
export interface Certification {
  certification_id: number;
  is_check: number;
  certification_name: string;
  sort_no: number;
}

// マスタ
export interface Master {
  staffs: OfficeStaffChoice[]; // 職員マスタ（同行者）
  visit_staffs: OfficeStaffChoice[]; // 職員マスタ（訪問者）
  visit_types: Choice[]; // 訪問種別マスタ
  night_cares: Choice[]; // 夜間訪問マスタ
  conditions: Choice[]; // 症状報告マスタ
  indicate_info: string; // 指示書情報
  visit_days_later: string; // 前回訪問からの経過日数
  is_covid19: boolean; // 臨時対応（コロナ）フラグ
  is_sam_building: boolean; // 同一建物フラグ
  show_first_visit: boolean; // 初回訪問加算表示フラグ

  nursing_details: NursingDetailMaster; // 看護内容
  observations: ObservationCategoryMaster; // 観察項目
  bedsores: BedsoreMaster; // 褥瘡マスタ
  risk_factor: RiskFactorMaster; // 危険因子マスタ

  own_expense_categories: Choice[]; // 自費カテゴリマスタ
  own_expense_items: OwnExpenseItemChoice[]; //自費アイテムマスタ

  before_bedsores: BeforeBedsore[]; // 褥瘡（前回）
  before_bedsore_date: string; // 褥瘡（前回）評価日
  before_risk_factor: RiskFactor | null; // 危険因子（前回）
  before_risk_factor_date: string; // 危険因子（前回）評価日
  before_observations: Observation[]; // 観察項目（前回）
  before_observation_date: string; // 観察項目（前回）評価日

  visit_contents: Choice[]; // 訪問内容（介護）

  care_offer_time: Choice[]; // 提供時間（介護）- 看護師・准看護師・専門・保健師
  care_offer_time2: Choice[]; // 提供時間（介護）- OT・PT・ST
  care_offer_time3: Choice[]; // 提供時間（介護）- OT・PT・ST(他STの訪問があり選択肢にない場合はこちら)
  medical_offer_time: Choice[]; // 提供時間（医療）
}

/** 前回評価褥瘡 */
export interface BeforeBedsore extends Bedsore {
  /** 評価日 */
  date: string;
}

export const DefaultMaster = (): Master => ({
  indicate_info: "",
  visit_days_later: "",
  night_cares: [],
  staffs: [],
  visit_staffs: [],
  visit_types: [],
  conditions: [],
  is_covid19: false,
  is_sam_building: false,
  show_first_visit: false,

  nursing_details: {
    care_large_contents: []
  },
  observations: {
    is_display_category: false,
    observation_categories: []
  },
  bedsores: {
    parts: [],
    depths: [],
    exudates: [],
    granulations: [],
    infections: [],
    necrotics: [],
    pockets: [],
    sizes: []
  },
  risk_factor: {
    cans: [],
    existences: []
  },
  own_expense_categories: [],
  own_expense_items: [],

  before_bedsores: [],
  before_bedsore_date: "",
  before_risk_factor: null,
  before_risk_factor_date: "",
  before_observations: [],
  before_observation_date: "",

  visit_contents: [],

  care_offer_time: [],
  care_offer_time2: [],
  care_offer_time3: [],
  medical_offer_time: []
});

export interface VisitModel {
  id: number; //ID
  agreement_id: number; //'契約ID',
  office_id: number; //'事業所ID',
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'記事編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  first_submit_datetime: string; //'初回提出日時',
  recent_submit_datetime: string; //'最新提出日時',
  departure_datetime: string; //'出発日時',
  invoice_yearmonth: string; //'請求対象年月',
  invoice_datetime: string; //'請求チェック日時',
  insurance_div: number; // 保険区分 1:医療保険訪問 2:介護保険訪問 3:自費訪問
  regular_visit_div: number; //'定期巡回訪問タイプ（定期巡回は介護保険のみ）\n0:定期巡回以外\n1:定期巡回\n2:随時巡回',
  mental_div: number; //'一般・精神区分\n1:訪問看護\n2:精神訪問看護（医療保険のみ）',
  care_div: number; //'介護・予防区分\n1:介護\n2:予防',
  visit_content_div: number; //'訪問内容\n1:計画\n2:緊急',
  visit_start_datetime: string; //'訪問開始日時',
  visit_end_datetime: string; //'訪問終了日時',
  service_start_datetime: string; //'サービス開始日時',
  service_end_datetime: string; //'サービス終了日時',
  staff_id: number; //'訪問者ID',
  service_time_div: number; //'提供時間区分\n介護保険/看護師・准看護師の場合\n1:訪看I1(20分未満)\n2:訪看I2(40分未満)\n3:訪看I3(30分以上60分未満)\n4:訪看I4(60分以上90分未満)\n5:訪看I4・長(90分以上)\n介護保険/OT・PT・ST（他ステーション訪問なし）の場合\n6:訪看I5(20分)\n7:訪看I5・2超(40分)\n8:訪看I5・2超(60分)\n9:訪看I5・2超(80分)\n10:訪看I5・2超(100分)\n11:訪看I5・2超(120分)\n介護保険/OT・PT・ST（他ステーション訪問あり）の場合\n12:訪看I5・2超(20分)\n13:訪看I5・2超(40分)\n医療保険の場合\n14:医療保険訪問',
  nursing_type: number; //訪問タイプ 1:サービス訪問（自費） 2:入院中の外泊訪問 3:加算のみ 4:専門看護師として同行を行う
  senmon_care_div: number; //'専門看護師訪問区分\n1:緩和ケア\n2:褥瘡ケア\n3:人工肛門／膀胱ケア\n4:特定行為\n5:特定行為（01創傷管理関連）',
  online_visit_flg: number; //'オンライン訪問フラグ',
  first_visit_flg: number; //'初回訪問看護加算'
  is_leave_joint: number; //'退院時共同指導加算',
  leave_joint_date: string; //'退院時共同指導日',
  time_type: number; //訪問時間区分 1:夜間訪問看護 2:早朝訪問看護 3:深夜訪問看護
  is_long_care: number; //'長時間訪問看護（医療のみ）',
  is_emergency_care: number; //'緊急訪問看護（医療のみ）',
  emergency_reason: string; //緊急訪問看護加算算定理由
  same_p2_flg: number; //'加算項目：同一日同一建物 2人',
  same_p3_flg: number; //'加算項目：同一日同一建物 3人以上',
  is_multi_care: number; //'複数名訪問看護加算',
  accompany1_id: number; //'同行者1ID',
  accompany1_other: string; //'同行者1その他テキスト',
  accompany2_id: number; //'同行者2ID',
  accompany2_other: string; //'同行者2その他テキスト',
  accompany3_id: number; //'同行者3ID',
  accompany3_other: string; //'同行者3その他テキスト',
  condition_div: number; //'症状\n1:著しい変化はなく安定\n2:特記すべき症状変化がある',
  condition: string; //'症状報告内容',
  care_content: string; //'看護内容記載',
  contact: string; //'連絡・特記事項',
  insurance_confirmed: number; //'有効保険証確認フラグ',
  next_visit_start_datetime: string; //'次回訪問日時',
  copy_recent_evaluation_adl: number; //観察項目 前回参照フラグ\n1:参照する\n0:参照しない
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  first_submit_datetime_wareki: string; //初回提出日時（和暦）
  recent_submit_datetime_wareki: string; //最新提出日時（和暦）
  departure_datetime_wareki: string; //出発日時（和暦）
  invoice_datetime_wareki: string; //請求チェック日時（和暦）
  visit_date_wareki: string; //訪問日（和暦）
  visit_start_datetime_wareki: string; //訪問開始日時（和暦）
  visit_end_datetime_wareki: string; //訪問終了日時（和暦）
  leave_joint_date_wareki: string; //退院時共同指導日（和暦）
  next_visit_start_datetime_wareki: string; //次回訪問日時（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  service_start_datetime_wareki: string; //サービス開始日時（和暦）
  service_end_datetime_wareki: string; //サービス終了日時（和暦）
  trach_tube_replace_flg: number; //特定行為 気管カニューレの交換の有無
  gast_jej_catheter_button_replace_flg: number; //特定行為 胃ろうカテーテル若しくは腸ろうカテーテル又は胃ろうボタンの交換の有無
  bladder_catheter_replace_flg: number; //特定行為 膀胱ろうカテーテルの交換の有無
  necrotic_tissue_remove_flg: number; //特定行為 褥瘡又は慢性創傷の治療における血流のない壊死組織の除去の有無
  negative_pressure_wound_therapy_flg: number; //特定行為 創傷に対する陰圧閉鎖療法の有無
  high_cal_infusion_adjust_flg: number; //特定行為 持続点滴中の高カロリー輸液の投与量の調整の有無
  fluid_dehydration_correction_flg: number; //特定行為 脱水症状に対する輸液による補正の有無
  care_contents: CareContent[]; //看護記録
  files: File[]; //ファイル
  gaf: VisitRecordGaf; //GAF
  jokusos: Bedsore[]; //褥瘡
  observations: Observation[]; //観察項目
  pdf: Pdf; //PDF
  remand: VisitRecordRemand; //差し戻し
  risk_factor: RiskFactor; //危険因子
  vitals: Vital[]; //バイタル
  own_expenses: OwnExpense[]; //自費
  editor_staff: ApplyStaff; //編集者
  staff: ApplyStaff; //訪問者
  accompany1: ApplyStaff; //同行者1
  accompany2: ApplyStaff; //同行者2
  accompany3: ApplyStaff; //同行者3
  create_staff_id: number; //作成者ID
  create_staff: ApplyStaff; //作成者
  is_editor_staff_write: number; //編集者に履歴を残すフラグ
}

// 訪問記録GAF
export interface VisitRecordGaf {
  id: number; //ID
  gaf_score: number; //'GAF評価点',
  is_family_visit: number; //'家族への訪問フラグ\n0:なし\n1:あり',
}
export const DefaultVisitRecordGaf = (): VisitRecordGaf => ({
  id: 0,
  gaf_score: -1,
  is_family_visit: 0
});

// 訪問記録サービス提供時間
export interface VisitRecordServiceTime {
  id: number; //ID
  sort_no: number; //'並び順',
  service_date: string; //'訪問日',
  service_start_datetime: string; //'訪問開始日時',
  service_end_datetime: string; //'訪問終了日時',
}

export const DefaultVisitModel = (): VisitModel => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  first_submit_datetime: "",
  recent_submit_datetime: "",
  departure_datetime: "",
  invoice_yearmonth: "",
  invoice_datetime: "",
  insurance_div: 0,
  regular_visit_div: 0,
  care_div: 0,
  mental_div: 0,
  visit_content_div: 0,
  visit_start_datetime: "",
  visit_end_datetime: "",
  service_start_datetime: "",
  service_end_datetime: "",
  staff_id: 0,
  service_time_div: 0,
  nursing_type: 0,
  senmon_care_div: 0,
  online_visit_flg: 0,
  first_visit_flg: 0,
  is_leave_joint: 0,
  leave_joint_date: "",
  time_type: 0,
  is_long_care: 0,
  is_emergency_care: 0,
  emergency_reason: "",
  same_p2_flg: 0,
  same_p3_flg: 0,
  is_multi_care: 0,
  accompany1_id: 0,
  accompany1_other: "",
  accompany2_id: 0,
  accompany2_other: "",
  accompany3_id: 0,
  accompany3_other: "",
  condition_div: 0,
  condition: "",
  care_content: "",
  contact: "",
  insurance_confirmed: 0,
  next_visit_start_datetime: "",
  copy_recent_evaluation_adl: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",

  first_submit_datetime_wareki: "",
  recent_submit_datetime_wareki: "",
  departure_datetime_wareki: "",
  invoice_datetime_wareki: "",
  visit_date_wareki: "",
  visit_start_datetime_wareki: "",
  visit_end_datetime_wareki: "",
  leave_joint_date_wareki: "",
  next_visit_start_datetime_wareki: "",
  updated_at_wareki: "",
  service_start_datetime_wareki: "",
  service_end_datetime_wareki: "",
  trach_tube_replace_flg: 0,
  gast_jej_catheter_button_replace_flg: 0,
  bladder_catheter_replace_flg: 0,
  necrotic_tissue_remove_flg: 0,
  negative_pressure_wound_therapy_flg: 0,
  high_cal_infusion_adjust_flg: 0,
  fluid_dehydration_correction_flg: 0,

  care_contents: [],
  files: [
    DefaultFile(1),
    DefaultFile(1),
    DefaultFile(1),
    DefaultFile(1),
    DefaultFile(2),
    DefaultFile(2),
    DefaultFile(2),
    DefaultFile(2)
  ],
  gaf: DefaultVisitRecordGaf(),
  jokusos: [],
  observations: [],
  pdf: DefaultPdf(),
  remand: DefaultVisitRecordRemand(),

  risk_factor: DefaultRiskFacotr(),
  vitals: [DefaultVital()],
  own_expenses: [],
  editor_staff: DefaultApplyStaff(),
  staff: DefaultApplyStaff(),
  accompany1: DefaultApplyStaff(),
  accompany2: DefaultApplyStaff(),
  accompany3: DefaultApplyStaff(),
  create_staff_id: 0,
  create_staff: DefaultApplyStaff(),
  is_editor_staff_write: 0
});
